import React from 'react'

import styles from './ColorLabelCell.module.scss'

type ColorLabelCellProps = { color: string }

export const ColorLabelCell: React.FC<ColorLabelCellProps> = ({ color, children }) => (
  <div className={styles.label}>
    <span className={styles.dot} style={{ backgroundColor: color }} />
    {children}
  </div>
)
