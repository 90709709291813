import React from 'react'

import classnames from 'classnames'

import styles from './HamburgerIcon.module.scss'

type HamburgerIconProps = { isOpen: boolean; className?: string }

export const HamburgerIcon = ({ isOpen, className }: HamburgerIconProps) => (
  <div className={classnames(styles.hamburgerIcon, className, { [styles.open]: isOpen })}>
    <span />
    <span />
    <span />
  </div>
)
