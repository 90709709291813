import { useCallback } from 'react'

import { useLoginState, extractRawIdToken } from '../../hooks/useLoginState'
import { getAuthenticatedApi } from '../../utils/api'
import { trackError } from '../../utils/trackError'

export const postSubscribeToMovement = async (movementId: string, options: { idToken: string }) => {
  const api = await getAuthenticatedApi()
  const result = await api.post(`subscriptions?movementId=${movementId}`, {
    headers: { 'X-Id-Token': options.idToken },
  })

  return result.ok
}

export const usePostSubscribeToMovement = () => {
  const { getIdTokenClaims } = useLoginState()

  return useCallback(
    async (movementId: string) => {
      try {
        const idToken = await getIdTokenClaims()
        return await postSubscribeToMovement(movementId, { idToken: extractRawIdToken(idToken) })
      } catch (err) {
        if (err instanceof Error) {
          trackError(err)
        }
        return false
      }
    },
    [getIdTokenClaims]
  )
}
