import { useMemo, useRef, useState } from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import classnames from 'classnames'
import { debounce } from 'lodash'

import { NO_DATA_AVAILABLE } from '../../constants/constants'
import { getImoFromMovement, getRowLabel, jobTypeToColor, Movement, OrderType } from '../../Domain/Movement'
import { TableType } from '../../Domain/Tracking'
import { useVesselDetailsLink } from '../../hooks/useVesselDetailsLink'
import { MovementOrPortStatus } from '../../pages/Dashboard/MovementOrPortStatus'
import { transparent } from '../../styles/variables'
import { HelpIcon } from '../../ui/icons/HelpIcon/HelpIcon'
import { SearchIcon } from '../../ui/SearchIcon/SearchIcon'
import { Direction } from '../../ui/Tooltip/Tooltip'
import { UpdatePill } from '../../ui/UpdatePill/UpdatePill'
import { renderTimeInfo } from '../../utils/datetime'
import { useActivePortContext } from '../Port/ActivePort'
import { PortStateIndicator } from '../PortStateIndicator/PortStateIndicator'

import { BackToTopButton } from './BackToTopButton/BackToTopButton'
import { ColorLabelCell } from './cells/ColorLabelCell/ColorLabelCell'
import { NotificationCell } from './cells/OverflowCell/OverflowCell'
import { TimeCell } from './cells/TimeCell/TimeCell'
import { TooltipCell } from './cells/TooltipCell/TooltipCell'
import { VesselStatusCell } from './cells/VesselStatusCell/VesselStatusCell'
import { HelpDropperFullScreen } from './HelpDropper/HelpDropper'
import styles from './TinyTable.module.scss'
import { NoResults } from './VirtualListWithHeaders'

export type MovementFieldGetter = (m: Movement) => string | undefined

const createMovementFilter = (getters: MovementFieldGetter[]) => (query: string, movements: Movement[]) =>
  movements.filter(movement => {
    const fields = getters.map(getter => getter(movement))
    return fields.some(field => field?.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
  })

export const TinyTable = ({
  movements,
  fieldGetters,
}: {
  movements: Movement[]
  fieldGetters: MovementFieldGetter[]
}) => {
  const tableRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { track } = useMixpanel()
  const ROW_SIZE = 200
  const DEBOUNCE_TIME = 300

  const [query, setQuery] = useState('')

  const debounceSearch = useMemo(() => debounce(setQuery, DEBOUNCE_TIME), [])
  const movementFilter = useMemo(() => createMovementFilter(fieldGetters), [fieldGetters])
  const filteredMovements = useMemo(() => movementFilter(query, movements), [movementFilter, movements, query])
  const hasMovements = filteredMovements.length > 0

  const handleChange = (value: string) => debounceSearch(value)

  return (
    <div ref={tableRef} className={styles.tinyTable}>
      <div className={styles.tinyTableHead}>
        <HelpIcon
          onClick={() => {
            if (isOpen) {
              setIsOpen(false)
            } else {
              track('View legend')
              setIsOpen(true)
            }
          }}
          className={isOpen ? styles.helpIconSelected : undefined}
        />
        {isOpen && <HelpDropperFullScreen handleClickClose={() => setIsOpen(false)} />}
        <div className={styles.search}>
          <input
            className={styles.searchInput}
            type="search"
            onChange={e => handleChange(e.target.value)}
            placeholder="Search for vessel name, IMO or from/to destination"
          />
          <SearchIcon className={styles.searchIcon} />
        </div>
      </div>
      {!hasMovements ? (
        <NoResults />
      ) : (
        filteredMovements.map(movement => {
          return <CollapsedMovement key={movement.movementId} movement={movement} />
        })
      )}
      <BackToTopButton listRef={tableRef} rowSize={ROW_SIZE} />
    </div>
  )
}

const CollapsedMovement = ({ movement }: { movement: Movement }) => {
  const {
    activePort: { unlocode },
  } = useActivePortContext()
  const { track } = useMixpanel()
  const { goToVesselDetails } = useVesselDetailsLink()
  const [isMovementCollapsed, setIsMovementCollapsed] = useState(true)
  const {
    movementId: id,
    from,
    to,
    underWayTime,
    offTime,
    draft,
    vessel: { vesselType },
    movementToDockHeading,
    tugMat,
    agent,
    subscribed,
  } = movement
  const imo = getImoFromMovement(movement)
  const hasIndicators = useMemo(() => !['USCRP'].includes(unlocode), [unlocode])

  return (
    <div className={styles.row}>
      <div
        className={classnames(styles.rowTitle, {
          [styles.labelAutomatic]: getRowLabel(movement) === OrderType.Automatic,
          [styles.labelCancelled]: getRowLabel(movement) === OrderType.Cancelled,
        })}>
        <MovementOrPortStatus
          type={movement.type}
          Movement={
            <>
              <ColorLabelCell color={jobTypeToColor[movement.jobType]} />
              <VesselStatusCell movementId={id} vesselName={movement.vessel.name} status={movement.status} />
            </>
          }
          PortStatus={
            <>
              <ColorLabelCell color={transparent} />
              <VesselStatusCell movementId={id} vesselName={movement.vessel.name} status="" />
            </>
          }
        />
      </div>
      <div className={classnames([styles.subRow, styles.portStatusCell])}>
        <MovementOrPortStatus
          type={movement.type}
          Movement={
            <>
              {hasIndicators && <PortStateIndicator portState={movement.houstonPortState} />}
              {movement.jobType}
            </>
          }
          PortStatus={movement.jobType}
        />
      </div>
      <div className={styles.subRow}>
        <dl className={styles.detailsList}>
          <div className={styles.group}>
            <dt className={styles.label}>Scheduled time</dt>
            <dd className={styles.value}>
              <MovementOrPortStatus
                type={movement.type}
                Movement={
                  <TimeCell
                    text="ET Pilot On"
                    time={movement.scheduledTime}
                    previousTime={
                      movement.recentlyUpdated.scheduledTime
                        ? {
                            value: movement.recentlyUpdated.scheduledTime,
                            tooltipKey: `${id}-${movement.recentlyUpdated.scheduledTime}`,
                          }
                        : undefined
                    }
                    onShow={() => track('Tap on update', { tableType: TableType.MOBILE, columnName: 'scheduledTime' })}
                  />
                }
                PortStatus={renderTimeInfo(movement.scheduledTime)}
              />
            </dd>
          </div>
        </dl>
        <MovementOrPortStatus
          type={movement.type}
          Movement={
            <button
              className={classnames(styles.button, { [styles.isActive]: !isMovementCollapsed })}
              onClick={() => setIsMovementCollapsed(!isMovementCollapsed)}>
              {isMovementCollapsed ? 'Show more' : 'Show less'}
            </button>
          }
          PortStatus={null}
        />
      </div>

      {!isMovementCollapsed && (
        <div className={styles.expandedView}>
          <dl className={classnames([styles.subRow, styles.detailsList])}>
            <div className={styles.group}>
              <dt className={styles.label}>From</dt>
              <dd className={styles.value}>
                {from === undefined && NO_DATA_AVAILABLE}
                {from !== undefined &&
                  (from.shortName === from.longName ? (
                    from.shortName
                  ) : (
                    <TooltipCell
                      tooltipDirection={Direction.RIGHT}
                      cellText={from.shortName}
                      tooltipValues={[from.longName]}
                      tooltipKey={`${id}-${from.abbreviation}`}
                      onShow={() => track('Hover on From', { tableType: TableType.MOBILE })}
                    />
                  ))}
              </dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>To</dt>
              <dd className={styles.value}>
                {to === undefined && NO_DATA_AVAILABLE}
                {to !== undefined &&
                  (to.shortName === to.longName ? (
                    to.shortName
                  ) : (
                    <TooltipCell
                      tooltipDirection={Direction.RIGHT}
                      cellText={to.shortName}
                      tooltipValues={[to.longName]}
                      tooltipKey={`${id}-${to.abbreviation}`}
                      onShow={() => track('Hover on To', { tableType: TableType.MOBILE })}
                    />
                  ))}
              </dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>Underway</dt>
              <dd className={styles.value}>
                <TimeCell text="AT Pilot On" time={underWayTime} />
              </dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>Off time</dt>
              <dd className={styles.value}>
                <TimeCell text="AT Pilot On" time={offTime} />
              </dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>Draft</dt>
              <dd className={styles.value}>
                <UpdatePill
                  value={draft}
                  previous={
                    movement.recentlyUpdated.draft
                      ? {
                          value: movement.recentlyUpdated.draft,
                          tooltipKey: `${id}-${movement.recentlyUpdated.draft}`,
                        }
                      : undefined
                  }
                  onShow={() => track('Hover on update', { tableType: TableType.MOBILE, columnName: 'draft' })}
                />
              </dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>Type</dt>
              <dd className={styles.value}>{vesselType}</dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>IMO</dt>
              <dd className={styles.value}>{imo}</dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>Heading</dt>
              <dd className={styles.value}>{movementToDockHeading}</dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>Tugmat</dt>
              <dd className={styles.value}>{tugMat}</dd>
            </div>
            <div className={styles.group}>
              <dt className={styles.label}>Agent</dt>
              <dd className={styles.value}>
                {agent === undefined ? (
                  NO_DATA_AVAILABLE
                ) : (
                  <TooltipCell
                    tooltipDirection={Direction.RIGHT}
                    tooltipKey={`${id}-${agent.shortName}`}
                    cellText={agent.shortName}
                    tooltipValues={[agent.longName, agent.contactPerson, agent.phone]}
                    onShow={() => track('Hover on agent', { tableType: TableType.MOBILE })}
                  />
                )}
              </dd>
            </div>
          </dl>
          <div className={styles.subRow}>
            <NotificationCell
              tableType={TableType.MOBILE}
              subscribed={subscribed}
              movementId={id}
              tooltipKey={`${id}-${subscribed}`}
              disabled={!!(movement.underWayTime || movement.offTime)}
            />
          </div>

          <div className={styles.subRow}>
            {imo !== null && (
              <button
                type="button"
                className={styles.sectionAction}
                onClick={() => {
                  track('View vessel information', { tableType: TableType.MOBILE })
                  goToVesselDetails(imo)
                }}>
                Vessel Information
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
