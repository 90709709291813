export enum TestId {
  DashboardViewToggle = 'dashboard-view-toggle',
  PilotOnBoard = 'status-pill-pilot-on-board',
  TableHeaderCell = 'table-header-cell',
  TableBodyRow = 'table-body-row',
  NotificationConfirmationModal = 'notification-confirmation-modal',
  ConfirmationModalCancelButton = 'confirmation-modal-cancel-button',
  ConfirmationModalConfirmButton = 'confirmation-modal-confirm-button',
  NotificationDisabledIconTooltipText = 'notification-disabled-icon-tooltip-text',
  CollapsableTitle = 'collapsable-title',
  CollapsableButton = 'collapsable-button',
  CollapsableContent = 'collapsable-content',
  DisabledRowIconContainer = 'disabled-row-icon-container',
  CheckBoxCheckMark = 'checkbox-check-mark',
  CheckBoxInput = 'checkbox-input',
  CheckBoxLabel = 'checkbox-label',
}

export const getTestIdSelector = (testId: TestId | string) => `[data-testid="${testId}"]`
