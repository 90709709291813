import { SVGAttributes } from 'react'

import { mediumblue, mostlyPureYellow } from '../../../styles/variables'

export type SortDirection = 'asc' | 'desc' | null

export const getSortDirection = (isSorted: boolean, isSortedDesc?: boolean): SortDirection => {
  if (isSortedDesc) {
    return 'desc'
  }

  if (isSorted) {
    return 'asc'
  }

  return null
}

type SortingIconProps = Readonly<{
  sortDirection: SortDirection
  svgProps?: SVGAttributes<SVGElement>
  testId?: string
}>

export const SortingIcon = ({ sortDirection, svgProps, testId }: SortingIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 9 11"
    width="9"
    height="11"
    {...svgProps}
  >
    <g fill="none" fillRule="evenodd" transform="translate(.411 .143)">
      {sortDirection !== 'asc' && (
        <path
          id="a"
          data-testid={`${testId}-desc-svg-icon`}
          d="M7.392 6.208c.137.01.259.124.304.294a.51.51 0 0 1-.103.47l-3.534 3.65a.267.267 0 0 1-.402 0L.127 6.99a.51.51 0 0 1-.104-.492c.044-.172.166-.287.305-.289h7.064Z"
          fill={sortDirection === 'desc' ? mostlyPureYellow : mediumblue}
        />
      )}
      {sortDirection !== 'desc' && (
        <path
          id="b"
          data-testid={`${testId}-asc-svg-icon`}
          d="M3.657.091a.267.267 0 0 1 .402 0l3.53 3.634a.51.51 0 0 1 .104.475c-.045.17-.167.284-.305.285H.325c-.139 0-.262-.116-.307-.288a.508.508 0 0 1 .109-.472Z"
          fill={sortDirection === 'asc' ? mostlyPureYellow : mediumblue}
        />
      )}
    </g>
  </svg>
)
