import classnames from 'classnames'

import { NoPhotographyIcon } from '../icons/NoPhotographyIcon/NoPhotographyIcon'

import styles from './ImagePlaceHolder.module.scss'

export const ImagePlaceHolder: React.FC<{ className?: string }> = ({ className }) => (
  <div data-testid="image-placeholder" className={classnames(styles.imagePlaceholder, className)}>
    <NoPhotographyIcon />
    <span>Not available</span>
  </div>
)
