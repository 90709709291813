import { useEffect, useState } from 'react'

import { IdToken, useAuth0 } from '@auth0/auth0-react'

import { trackError } from '../utils/trackError'

export enum LoginStateType {
  LoggedIn,
  LoggedOut,
  Loading,
  Error,
}
type LoginStateLoggedIn = { type: LoginStateType.LoggedIn }
type LoginStateLoggedOut = { type: LoginStateType.LoggedOut }
type LoginStateLoading = { type: LoginStateType.Loading }
type LoginStateError = { type: LoginStateType.Error; error: string }
type LoginState = LoginStateLoggedIn | LoginStateLoggedOut | LoginStateLoading | LoginStateError

const determineLoginState = (isAuthenticated: boolean, isLoading: boolean, error: Error | undefined): LoginState => {
  if (error !== undefined) {
    return {
      type: LoginStateType.Error,
      error: error?.message ?? 'Unknown error occurred during logging in. Please try again.',
    }
  }

  if (isAuthenticated && !isLoading) {
    return { type: LoginStateType.LoggedIn }
  }

  if (!isAuthenticated && isLoading) {
    return { type: LoginStateType.Loading }
  }

  return { type: LoginStateType.LoggedOut }
}

// eslint-disable-next-line no-underscore-dangle
export const extractRawIdToken = (idToken: IdToken) => idToken.__raw

export const useLoginState = () => {
  const auth0Client = useAuth0()

  const loginState = determineLoginState(auth0Client.isAuthenticated, auth0Client.isLoading, auth0Client.error)

  return {
    ...auth0Client,
    loginState,
  }
}

export const useGetRawIdToken = () => {
  const { getIdTokenClaims } = useLoginState()
  const [rawIdToken, setRawIdToken] = useState<string>()

  useEffect(() => {
    getIdTokenClaims()
      .then(idToken => {
        // eslint-disable-next-line no-underscore-dangle
        setRawIdToken(idToken.__raw)
      })
      .catch(err => {
        trackError(err)
        setRawIdToken(undefined)
      })
  }, [getIdTokenClaims])

  return rawIdToken
}
