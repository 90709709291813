import classNames from 'classnames'

import { HoustonPortState } from '../../Domain/Movement'
import { ArrowLeftIcon } from '../../ui/icons/ArrowIcons/ArrowLeftIcon'
import { ArrowRightIcon } from '../../ui/icons/ArrowIcons/ArrowRightIcon'

import styles from './PortStateIndicator.module.scss'

type PortStateIndicatorProps = { portState: HoustonPortState }

export const PortStateIndicator = ({ portState }: PortStateIndicatorProps) => {
  switch (portState) {
    case 'BothWaysClosed':
      return (
        <div className={styles.arrowContainer}>
          <ArrowLeftIcon className={classNames(styles.arrow, styles.arrowClosed)} />
          <ArrowRightIcon className={classNames(styles.arrow, styles.arrowClosed)} />
        </div>
      )
    case 'BothWaysOpen':
      return (
        <div className={styles.arrowContainer}>
          <ArrowLeftIcon className={classNames(styles.arrow, styles.arrowOpen)} />
          <ArrowRightIcon className={classNames(styles.arrow, styles.arrowOpen)} />
        </div>
      )
    case 'InboundClosed':
      return (
        <div className={styles.arrowContainer}>
          <ArrowLeftIcon className={classNames(styles.arrow, styles.arrowClosed)} />
          <ArrowRightIcon className={classNames(styles.arrow, styles.arrowOpen)} />
        </div>
      )
    case 'OutboundClosed':
      return (
        <div className={styles.arrowContainer}>
          <ArrowLeftIcon className={classNames(styles.arrow, styles.arrowOpen)} />
          <ArrowRightIcon className={classNames(styles.arrow, styles.arrowClosed)} />
        </div>
      )
    default:
      // eslint-disable-next-line no-case-declarations
      const exhaustive: never = portState
      throw new Error(exhaustive)
  }
}
