import React, { Children, createContext, forwardRef, PropsWithChildren, RefObject, useContext } from 'react'

import { useAtom } from 'jotai'
import { HeaderGroup, TableBodyProps, UseSortByColumnProps, UseTableColumnProps } from 'react-table'
import { FixedSizeList, VariableSizeList, VariableSizeListProps } from 'react-window'

import { rowSizes } from '../../constants/table'
import { FIRST_COLUMN_WIDTH } from '../../pages/Dashboard/columns'
import { tableRowSizeAtom } from '../../state/tableRowSize'
import { getSortDirection, SortingIcon } from '../../ui/icons/SortingIcon/SortingIcon'
import { TestId } from '../../utils/testid'

import styles from './Table.module.scss'

const Sorting = <Data extends {}>({ column }: { column: UseTableColumnProps<Data> & UseSortByColumnProps<Data> }) => (
  <div className={styles.headCellInnerSortable}>
    {column.render('Header')}{' '}
    <SortingIcon sortDirection={getSortDirection(column.isSorted, column.isSortedDesc)} testId={column.id} />
  </div>
)

export const NoResults = () => (
  <p className={styles.noResults}>
    We couldn't find any results. Please check your filters and search bar entries on the table
  </p>
)

const Filtering = <Data extends {}>({ column }: { column: UseTableColumnProps<Data> }) => <>{column.render('Filter')}</>

type TableHeaderCellProps<Data extends {}> = { column: HeaderGroup<Data> }
const TableHeaderCell = <Data extends {}>({ column }: TableHeaderCellProps<Data>) => {
  if (column.canFilter) {
    return <Filtering column={column} />
  }

  if (column.canSort) {
    return <Sorting column={column} />
  }

  // Render default text if there's no sorting or filtering
  return <>{column.render('Header')}</>
}

type HeaderRowContextType = { headerGroups: HeaderGroup[]; tableBodyProps: TableBodyProps }
const HeaderRowContext = createContext<HeaderRowContextType>({ headerGroups: [], tableBodyProps: {} })

const HeadersRow = forwardRef<HTMLDivElement, PropsWithChildren<{ [key: string]: unknown }>>(
  ({ children, ...rest }, ref) => {
    const { headerGroups, tableBodyProps } = useContext(HeaderRowContext)
    const isDashboardView = headerGroups[0].headers.length > 5
    const isSecondColumn = (i: number) => i === 1
    const isSticky = (i: number) => i < 2
    const hasMovements = Children.count(children) > 0

    const getClassName = (i: number) => {
      if (!isDashboardView) {
        return styles.headerCell
      }

      return `${styles.headerCell} ${isSticky(i) ? styles.sticky : ''} ${isSecondColumn(i) ? styles.divider : ''}`
    }

    return (
      <div ref={ref} {...rest}>
        <div role="rowgroup" className={styles.head}>
          {headerGroups.map(headerGroup => (
            <div role="row" {...headerGroup.getHeaderGroupProps({ className: styles.row })}>
              {headerGroup.headers.map((column, i) => (
                <div
                  role="columnheader"
                  {...column.getHeaderProps({
                    style: {
                      maxWidth: column.maxWidth,
                      left: isSecondColumn(i) && isDashboardView ? FIRST_COLUMN_WIDTH.width : undefined,
                    },
                    className: getClassName(i),
                  })}
                >
                  <div
                    {...column.getSortByToggleProps()}
                    data-testid={TestId.TableHeaderCell}
                    className={styles.headCellInner}
                    title="" // Override sort by title tooltip
                  >
                    <TableHeaderCell column={column} />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div role="rowgroup" {...tableBodyProps}>
          {hasMovements ? children : <NoResults />}
        </div>
      </div>
    )
  }
)

type VirtualListWithHeadersProps = Omit<
  VariableSizeListProps,
  'className' | 'innerElementType' | 'itemSize' | 'width'
> &
  HeaderRowContextType & { listRef?: RefObject<VariableSizeList>; tableBodyProps: TableBodyProps }
export const VirtualListWithHeaders = ({
  children,
  headerGroups,
  tableBodyProps,
  ...rest
}: VirtualListWithHeadersProps) => {
  const [tableRowSize] = useAtom(tableRowSizeAtom)

  return (
    <HeaderRowContext.Provider value={{ headerGroups: headerGroups as HeaderGroup[], tableBodyProps }}>
      <FixedSizeList
        itemData={{ ItemRenderer: children }}
        innerElementType={HeadersRow}
        className={styles.scrollableArea}
        itemSize={rowSizes[tableRowSize]}
        overscanCount={10}
        width="100%"
        {...rest}
      >
        {children}
      </FixedSizeList>
    </HeaderRowContext.Provider>
  )
}
