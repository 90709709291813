import { useHistory, useLocation } from 'react-router-dom'

import { PAGES } from '../constants/routing'

export const useVesselDetailsLink = () => {
  const history = useHistory()
  const location = useLocation()

  const createPath = (imo: string) => `/${PAGES.VESSEL_DETAILS}/${imo}?returnTo=${location.pathname}`

  return {
    goToVesselDetails: (imo: string) => history.push(createPath(imo)),
    getVesselDetailsLink: createPath,
  }
}
