import { useEffect, useState } from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import { Filters, SortingRule } from 'react-table'

import { TableType } from '../../Domain/Tracking'

export const useTrackSortTable = <Data extends {}>({
  initialSorting,
  sortBy,
  tableType,
}: {
  initialSorting?: SortingRule<Data>
  sortBy: SortingRule<Data>[]
  tableType: TableType
}) => {
  const { track } = useMixpanel()
  const [currentSortBy, setCurrentSortBy] = useState(initialSorting)

  useEffect(() => {
    const singleSortBy: SortingRule<Data> | undefined = sortBy[0]
    if (singleSortBy && (singleSortBy?.id !== currentSortBy?.id || singleSortBy?.desc !== currentSortBy?.desc)) {
      track('Sort column', {
        tableType,
        columnName: singleSortBy?.id,
        ...(singleSortBy ? { sortType: singleSortBy?.desc ? 'desc' : 'asc' } : {}),
      })
      setCurrentSortBy(singleSortBy)
    } else if (!singleSortBy) {
      setCurrentSortBy(singleSortBy)
    }
  }, [sortBy, currentSortBy, track, tableType])
}

export const useTrackFilterTable = <Data extends {}>({
  filters,
  tableType,
}: {
  filters: Filters<Data>
  tableType: TableType
}) => {
  const { track } = useMixpanel()
  const [currentFilterIds, setCurrentFilterIds] = useState<string[]>([])

  useEffect(() => {
    const filterIds = filters.map(({ id }) => id)
    const newIds = filterIds.filter(id => !currentFilterIds.includes(id))

    if (newIds.length) {
      newIds.forEach(id => {
        track('Filter column', {
          tableType,
          columnName: id,
        })
      })
      setCurrentFilterIds(filterIds)
    } else if (filterIds.length < currentFilterIds.length) {
      setCurrentFilterIds(filterIds)
    }
  }, [filters, currentFilterIds, track, tableType])
}
