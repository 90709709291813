import { useCallback, useEffect } from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import { ActivePortProvider } from './components/Port/ActivePort'
import { PortNotFound } from './components/PortNotFound/PortNotFound'
import { SystemStatus } from './components/SystemStatus/SystemStatus'
import { ColumnSettingsProvider } from './components/TableSettingsProvider/TableSettingsProvider'
import { UserProvider, useUserContext } from './components/UserProvider/UserProvider'
import { VesselDetailsOverlay } from './components/VesselDetailsOverlay/VesselDetailsOverlay'
import { AUTH0_REDIRECT_URI } from './constants/auth'
import { PAGES } from './constants/routing'
import { LoginStateType, useLoginState } from './hooks/useLoginState'
import { usePrevious } from './hooks/usePrevious'
import { useTrackUser } from './hooks/useTrackUser'
import { DashboardContainer } from './pages/Dashboard/Dashboard'
import { Faq } from './pages/Faq/Faq'
import { Login } from './pages/Login/Login'
import { InlineMessage } from './ui/InlineMessage/InlineMessage'
import { LoadingScreen } from './ui/LoadingScreen/LoadingScreen'
import { initializeClient } from './utils/api'

const AuthenticatedRouter = () => {
  const { user } = useUserContext()

  const { defaultPort } = user

  useEffect(() => {}, [defaultPort])

  useTrackUser()

  return (
    <Switch>
      <Route
        path={`/${PAGES.DASHBOARD}`}
        render={() => (
          <ActivePortProvider fallback={<Redirect to={PAGES.PORT_NOT_FOUND} />}>
            <ColumnSettingsProvider>
              <DashboardContainer />
            </ColumnSettingsProvider>
          </ActivePortProvider>
        )}
      />
      <Route path={`/${PAGES.VESSEL_DETAILS}/:imo`} component={VesselDetailsOverlay} />
      <Route path={PAGES.FAQ} component={Faq} />
      <Route exact path={PAGES.PORT_NOT_FOUND} component={PortNotFound} />
      {/* Redirect root path to dashboard */}
      <Redirect exact from={PAGES.ROOT} to={`/${PAGES.DASHBOARD}`} />
      <Route>404: Page not found</Route>
    </Switch>
  )
}

const useTrackLocationChange = () => {
  const { track } = useMixpanel()
  const location = useLocation()
  const prevLocation = usePrevious(location.pathname)

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      track('View page', { pageName: location.pathname })
    }
  }, [location, prevLocation, track])
}

export const Router = () => {
  const { loginState, loginWithRedirect, getAccessTokenSilently, isAuthenticated } = useLoginState()

  useEffect(() => {
    initializeClient({ isAuthenticated, getTokenSilently: getAccessTokenSilently })
  }, [isAuthenticated, getAccessTokenSilently])

  const onLogin = useCallback(
    () => loginWithRedirect({ max_age: 0, redirectUri: AUTH0_REDIRECT_URI }),
    [loginWithRedirect]
  )

  useTrackLocationChange()

  switch (loginState.type) {
    case LoginStateType.LoggedIn:
      return (
        <UserProvider>
          <AuthenticatedRouter />
          <SystemStatus />
        </UserProvider>
      )
    case LoginStateType.Loading:
      return <LoadingScreen />
    case LoginStateType.LoggedOut:
      onLogin()
      return null
    case LoginStateType.Error:
      return (
        <Login onLogin={() => onLogin()}>
          <p>An error occurred when logging in:</p>
          <InlineMessage>{loginState.error}</InlineMessage>
          <p>Please try again.</p>
        </Login>
      )
    default:
      return null
  }
}
