import ReactTooltip from 'react-tooltip'

import styles from './Tooltip.module.scss'
import { backgroundColor } from './variables'

export enum Direction {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}
interface IProps {
  text?: React.ReactNode
  tooltipKey: string
  place?: Direction
  onShow?: () => void
  testId?: string
}

export const Tooltip = ({ text, tooltipKey, onShow, testId, place = Direction.TOP }: IProps) => {
  if (!text || !tooltipKey) return null
  return (
    <ReactTooltip
      className={styles.tooltip}
      id={tooltipKey}
      backgroundColor={backgroundColor}
      effect="solid"
      place={place}
      afterShow={onShow}
    >
      <span data-testid={testId}>{text}</span>
    </ReactTooltip>
  )
}
