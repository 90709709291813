import { useEffect, useState } from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import md5 from 'md5'
import { noop } from 'rxjs'

import { usePostStartSession } from '../Api/Analytics/postStartSession'
import { useUserContext } from '../components/UserProvider/UserProvider'

import { useLoginState } from './useLoginState'

export const useTrackUser = () => {
  const { setupUser, setShouldTrack } = useMixpanel()
  const { user: auth0User } = useLoginState()
  const [isSessionStarted, setIsSessionStarted] = useState(false)
  const postStartSession = usePostStartSession()
  const { user: portxchangeUser } = useUserContext()

  useEffect(() => {
    if (auth0User?.email) {
      const id = md5(auth0User.email)
      setShouldTrack(!portxchangeUser.trackingOptOut)
      setupUser(id, { companyName: portxchangeUser.company }, noop)
    }
  }, [auth0User, setupUser, setShouldTrack, portxchangeUser])

  useEffect(() => {
    if (!isSessionStarted) {
      postStartSession().then(setIsSessionStarted)
    }
  }, [isSessionStarted, postStartSession])
}
