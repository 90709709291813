import { UseQueryResult } from 'react-query'

type RenderQueryResultProps<Result> = Readonly<{
  query: UseQueryResult<Result>
  ErrorComponent: JSX.Element
  LoadingComponent: JSX.Element
  children: (result: Result) => JSX.Element
}>
export const RenderQueryResult = <T extends {}>({
  query: { isLoading, isError, data, isIdle },
  ErrorComponent,
  LoadingComponent,
  children,
}: RenderQueryResultProps<T>) => {
  if ((isLoading && !data) || isIdle) {
    return LoadingComponent
  }
  if (!isError && data) {
    return children(data)
  }

  return ErrorComponent
}
