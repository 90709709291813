import { useMemo } from 'react'

import classNames from 'classnames'

import { useResponsiveness } from '../../../hooks/useResponsiveness'
import { TopLeftDropoutMenuDropper } from '../../../ui/DropoutMenu/DropoutMenuDropper'
import { CloseIcon } from '../../../ui/icons/CloseIcon/CloseIcon'
import { useActivePortContext } from '../../Port/ActivePort'
import { PortStateIndicator } from '../../PortStateIndicator/PortStateIndicator'

import styles from './HelpDropper.module.scss'

export const HelpDropper = ({ handleClickClose }: { handleClickClose: () => void }) => (
  <TopLeftDropoutMenuDropper
    hasTriangleSpace
    triangleOffsetLeft={6}
    theme={{ triangleTop: styles.helpDropoutArrow }}
    className={styles.helpDropout}>
    <HelpDropperContent handleClickClose={handleClickClose} />
  </TopLeftDropoutMenuDropper>
)

export const HelpDropperFullScreen = ({ handleClickClose }: { handleClickClose: () => void }) => (
  <div className={classNames(styles.helpDropout, styles.fullScreen)}>
    <HelpDropperContent handleClickClose={handleClickClose} />
  </div>
)

const HelpDropperContent = ({ handleClickClose }: { handleClickClose: () => void }) => {
  const { isMobile } = useResponsiveness()
  const {
    activePort: { unlocode },
  } = useActivePortContext()

  const hasHiddenSections = useMemo(() => ['USCRP'].includes(unlocode), [unlocode])

  return (
    <>
      <button aria-label="close" type="button" className={styles.close} onClick={handleClickClose}>
        <CloseIcon size={20} />
      </button>
      <h3 className={styles.title}>Why do I see colored labels in front of a row?</h3>
      {!hasHiddenSections && (
        <section className={classNames(styles.section, styles.automatic)}>
          <h4 className={styles.name}>Automatic order</h4>
          <p className={styles.description}>
            An agent will place an order for their vessel to follow another vessel that is currently occupying the
            berth. A date/time will be added once the ship on the berth places an order to sail or shift. Once the ship
            on the berth places an order, the automatic order will then activate and match the date/time of the vessel
            sailing/shifting.
          </p>
        </section>
      )}
      <section className={classNames(styles.section, styles.cancelled)}>
        <h4 className={styles.name}>Cancelled order</h4>
        <p className={styles.description}>When a pilot is ordered and the agent cancels the vessel's movement.</p>
      </section>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>Movement type</h3>
        <p className={styles.description}>
          The dots shown at the beginning of the movement rows indicate what type of movement it is:
        </p>
        <ul className={styles.movementTypeList}>
          <li className={classNames(styles.movementTypeListItem, styles.inbound)}>Inbound</li>
          <li className={classNames(styles.movementTypeListItem, styles.shifting)}>Shifting</li>
          <li className={classNames(styles.movementTypeListItem, styles.outbound)}>Outbound</li>
        </ul>
      </div>
      {!hasHiddenSections && (
        <div className={styles.textContainer}>
          <h3 className={styles.title}>Traffic status</h3>
          <p className={styles.description}>
            The arrows below indicate the current traffic status in the channel, and if there is suspended or restricted
            movement due to weather conditions such as fog, storms, wind gusts and currents during the booked movement.
          </p>
          <ul className={styles.portStatusList}>
            <li className={styles.portStatusListItem}>
              <PortStateIndicator portState="BothWaysOpen" />
              <span className={styles.portStatusText}>
                inbound <strong>open</strong>/outbound <strong>open</strong>
              </span>
            </li>
            <li className={styles.portStatusListItem}>
              <PortStateIndicator portState="InboundClosed" />
              <span className={styles.portStatusText}>
                inbound <strong>closed</strong>/outbound <strong>open</strong>
              </span>
            </li>
            <li className={styles.portStatusListItem}>
              <PortStateIndicator portState="OutboundClosed" />
              <span className={styles.portStatusText}>
                inbound <strong>open</strong>/outbound <strong>closed</strong>
              </span>
            </li>
            <li className={styles.portStatusListItem}>
              <PortStateIndicator portState="BothWaysClosed" />
              <span className={styles.portStatusText}>
                inbound <strong>closed</strong>/outbound <strong>closed</strong>
              </span>
            </li>
          </ul>
        </div>
      )}
      {isMobile && (
        <section className={styles.section}>
          <h4 className={styles.name}>Searching movements</h4>
          <p className={styles.description}>
            You can filter movements matching a certain vessel name, IMO, or from/to destination by typing a query into
            the search field at the top of the page.
          </p>
        </section>
      )}
    </>
  )
}
