import { ReactNode, useState } from 'react'

import { TestId } from '../../utils/testid'
import { DropdownArrow } from '../icons/DropdownArrow/DropdownArrow'

import styles from './Collapsable.module.scss'

interface ICollapsableProps {
  titleText: ReactNode
  children: ReactNode
}

export const Collapsable = (props: ICollapsableProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <div className={styles.collapsable}>
      <button
        data-testid={TestId.CollapsableButton}
        className={styles.title}
        onClick={() => setIsCollapsed(prev => !prev)}
      >
        <div data-testid={TestId.CollapsableTitle} className={styles.titleText}>
          {props.titleText}
        </div>
        <div>
          <DropdownArrow isCollapsed={isCollapsed} />
        </div>
      </button>
      {isCollapsed ? null : (
        <div data-testid={TestId.CollapsableContent} className={styles.content}>
          {props.children}
        </div>
      )}
    </div>
  )
}
