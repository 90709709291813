import { createContext, useContext } from 'react'

import { useQuery } from 'react-query'

import { fetchPorts } from '../../Api/Me/fetchPorts'
import { REACT_QUERY_KEYS } from '../../constants/react-query'
import { Port } from '../../Domain/Port'
import { RenderQueryResult } from '../../hoc/RenderQueryResult'
import { LoadingScreen } from '../../ui/LoadingScreen/LoadingScreen'
import { ErrorScreen } from '../ErrorScreen/ErrorScreen'
import { useUserContext } from '../UserProvider/UserProvider'

type ActivePortState = {
  activePort: Port
}

const ActivePortContext = createContext<ActivePortState>({} as ActivePortState)

export const useActivePortContext = () => useContext(ActivePortContext)

type ActivePortProviderProps = {
  fallback: JSX.Element
}

export const ActivePortProvider: React.FC<ActivePortProviderProps> = ({ children, fallback }) => {
  const portsQuery = useQuery(REACT_QUERY_KEYS.ports, fetchPorts)
  const {
    user: { defaultPort },
  } = useUserContext()

  return (
    <RenderQueryResult query={portsQuery} ErrorComponent={<ErrorScreen />} LoadingComponent={<LoadingScreen />}>
      {ports => {
        const activePort = ports.find(port => port.unlocode === defaultPort)

        return activePort ? (
          <ActivePortContext.Provider value={{ activePort }}>{children}</ActivePortContext.Provider>
        ) : (
          fallback
        )
      }}
    </RenderQueryResult>
  )
}
