import React, { PropsWithChildren } from 'react'

import classnames from 'classnames'

import { TestId } from '../../utils/testid'

import styles from './Toggle.module.scss'

export const TOGGLE_THEME_DEFAULT: ToggleTheme = {
  toggle: styles.toggle,
  checked: styles.checked,
  disabled: styles.disabled,
  toggleText: styles.toggleText,
  toggleContainer: styles.toggleContainer,
}

export const TOGGLE_THEME_WITH_OUTER_LABEL: ToggleThemeWithOuterLabel = {
  ...TOGGLE_THEME_DEFAULT,
  outerLabel: styles.outerLabel,
}

export type ToggleTheme = Readonly<{
  toggle: string
  checked: string
  disabled: string
  toggleText: string
  toggleContainer: string
}>

export type ToggleThemeWithOuterLabel = ToggleTheme & { outerLabel: string }

interface IToggleProps {
  isChecked: boolean
  labelOn?: string
  labelOff?: string
  disabled?: boolean
  handleToggle?: (checked: boolean) => void
  theme?: ToggleTheme
  testId?: TestId
}

export const Toggle = ({
  theme = TOGGLE_THEME_DEFAULT,
  isChecked,
  disabled,
  handleToggle,
  labelOn,
  labelOff,
  children,
  testId,
}: PropsWithChildren<IToggleProps>) => {
  const labelState = isChecked ? labelOn : labelOff

  return (
    <label className={theme.toggleContainer}>
      <input
        checked={isChecked}
        onChange={handleToggle && handleToggle.bind(handleToggle, !isChecked)}
        disabled={disabled || !handleToggle}
        className={styles.hiddenInput}
        type="checkbox"
      />
      {children}
      <div
        className={classnames(theme.toggle, { [theme.checked]: isChecked, [theme.disabled]: disabled })}
        data-testid={testId}
      >
        {labelState && <span className={theme.toggleText}>{labelState}</span>}
      </div>
    </label>
  )
}

export const ToggleWithOuterLabel = ({
  outerLabel,
  theme = TOGGLE_THEME_WITH_OUTER_LABEL,
  ...toggleProps
}: Omit<IToggleProps, 'theme'> & { outerLabel: string; theme: ToggleThemeWithOuterLabel }) => (
  <Toggle theme={theme} {...toggleProps}>
    <span className={theme.outerLabel}>{outerLabel}</span>
  </Toggle>
)
