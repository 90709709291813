import React from 'react'

import { InlineLoader } from '../InlineLoader/InlineLoader'

import styles from './LoadingScreen.module.scss'

export const LoadingScreen = () => {
  return (
    <div className={styles.loaderContainer}>
      <InlineLoader />
    </div>
  )
}
