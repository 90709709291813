import {
  USHOU_SUPPORT_EMAIL_ADDRESS,
  USHOU_SUPPORT_PHONE_NUMBER,
  USCRP_SUPPORT_EMAIL_ADDRESS,
  USCRP_SUPPORT_PHONE_NUMBER,
} from '../../constants/constants'
import { SubMenuForm } from '../Header/SubMenu/SubMenuForm/SubMenuForm'
import { SubMenuHeader } from '../Header/SubMenu/SubMenuHeader/SubMenuHeader'
import { useUserContext } from '../UserProvider/UserProvider'

import styles from './BugReporter.module.scss'

interface IBugReporterProps {
  onBack: () => void
  isActive: boolean
}

export const BugReporter = (props: IBugReporterProps) => {
  const { user } = useUserContext()

  const SUPPORT_PARTY =
    user.defaultPort === 'USCRP' ? 'Port of Corpus Christi Harbor Master' : 'the Greater Houston Port Bureau'
  const SUPPORT_EMAIL_ADDRESS = user.defaultPort === 'USCRP' ? USCRP_SUPPORT_EMAIL_ADDRESS : USHOU_SUPPORT_EMAIL_ADDRESS
  const SUPPORT_PHONE_NUMBER = user.defaultPort === 'USCRP' ? USCRP_SUPPORT_PHONE_NUMBER : USHOU_SUPPORT_PHONE_NUMBER

  return (
    <div className={styles.container}>
      <SubMenuHeader onBack={props.onBack} />
      <SubMenuForm>
        <h3 className={styles.heading}>Request support</h3>
        <p>Please note that 1st level support is handled by {SUPPORT_PARTY}.</p>
        <p>
          Please email{' '}
          <a className={styles.link} href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
            {SUPPORT_EMAIL_ADDRESS}
          </a>{' '}
          or call {SUPPORT_PHONE_NUMBER} if you have any questions.
        </p>
      </SubMenuForm>
    </div>
  )
}
