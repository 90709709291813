import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const darkModeAtom = atomWithStorage('darkMode', true)

export const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useAtom(darkModeAtom)
  const toggleDarkMode = () => setIsDarkMode(!isDarkMode)

  return { isDarkMode, toggleDarkMode }
}
