import { useCallback, useEffect, useState } from 'react'

import styles from './BackToTopButton.module.scss'

export const BackToTopButton = ({
  listRef,
  rowSize,
}: {
  listRef: React.RefObject<HTMLDivElement>
  rowSize: number
}) => {
  const hasScrolledList = useCallback(
    () => !!listRef.current && listRef.current.scrollTop > rowSize,
    [listRef, rowSize]
  )

  const [shouldDisplay, setShouldDisplay] = useState(hasScrolledList())

  useEffect(() => {
    if (listRef.current) {
      listRef.current.onscroll = () => setShouldDisplay(hasScrolledList())
    }
  }, [hasScrolledList, listRef])

  const handleScrollTop = () => listRef.current?.scrollTo({ top: 0, behavior: 'smooth' })

  return shouldDisplay ? (
    <button className={styles.backToTopButton} onClick={handleScrollTop}>
      Back to top
    </button>
  ) : null
}
