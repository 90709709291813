import React from 'react'

import { CloseIcon } from '../../ui/icons/CloseIcon/CloseIcon'

import { Modal } from './Modal'
import styles from './Modal.module.scss'

type InformationModalProps = {
  onClose: () => void
  title: string
  body: React.ReactNode
  isVisible: boolean
  withCloseButton?: boolean
}

export const InformationModal = ({ isVisible, onClose, title, body, withCloseButton }: InformationModalProps) => (
  <Modal isVisible={isVisible} onClose={onClose}>
    {withCloseButton && (
      <button aria-label="close" onClick={onClose} className={styles.closeButton}>
        <CloseIcon size={16} />
      </button>
    )}
    <div className={styles.content}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.text}>{body}</div>
    </div>
  </Modal>
)
