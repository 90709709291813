import { createContext, useContext } from 'react'

import { useQuery } from 'react-query'

import { fetchMe } from '../../Api/Me/fetchMe'
import { REACT_QUERY_KEYS } from '../../constants/react-query'
import { User } from '../../Domain/Me'
import { RenderQueryResult } from '../../hoc/RenderQueryResult'
import { LoadingScreen } from '../../ui/LoadingScreen/LoadingScreen'
import { ErrorScreen } from '../ErrorScreen/ErrorScreen'

type UserState = {
  user: User
}

const UserContext = createContext<UserState>({} as UserState)

export const useUserContext = () => useContext(UserContext)

export const UserProvider: React.FC = ({ children }) => {
  const meQuery = useQuery(REACT_QUERY_KEYS.me, fetchMe)

  return (
    <RenderQueryResult query={meQuery} ErrorComponent={<ErrorScreen />} LoadingComponent={<LoadingScreen />}>
      {user => (
        <UserContext.Provider
          value={{
            user,
          }}>
          {children}
        </UserContext.Provider>
      )}
    </RenderQueryResult>
  )
}
