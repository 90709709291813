import { Tooltip } from '../Tooltip/Tooltip'

import styles from './UpdatePill.module.scss'

interface IProps {
  value: React.ReactNode
  previous?: {
    value: React.ReactNode
    tooltipKey: string
  }
  onShow?: () => void
}

export const UpdatePill = ({ value, previous, onShow }: IProps) =>
  previous ? (
    <>
      <span className={styles.updatedPill} data-tip data-for={previous.tooltipKey}>
        {value}
      </span>
      <Tooltip text={<>Prev. {previous.value}</>} tooltipKey={previous.tooltipKey} onShow={onShow} />
    </>
  ) : (
    <>{value}</>
  )
