import { addHours, isAfter, isEqual, isBefore } from 'date-fns'
import { getTimezoneOffset } from 'date-fns-tz'
import { Row, SortByFn } from 'react-table'

import { DateTimeRange } from '../../components/Table/Filters/DateTimeRangeFilter'
import { HOUSTON_TIME_ZONE } from '../../constants/time'
import { Agent, Dock, Movement } from '../../Domain/Movement'
import { getCurrentTimeZone } from '../../utils/timezone'

export enum CustomSortTypes {
  OptionalDateTime = 'optionalDatetime',
}

export enum CustomFilterTypes {
  AgentFilter = 'agentFilter',
  DockFilter = 'dockFilter',
  DateTimeRangeFilter = 'dateTimeRangeFilter',
}

const getColValue = <Data extends {}>(row: Row<Data>, colId: keyof Row<Data>['values']) => row.values[colId]

const isAgent = (agent?: any): agent is Agent => agent?.shortName !== undefined && agent?.longName !== undefined
const isDock = (dock?: any): dock is Dock =>
  dock?.abbreviation !== undefined && dock?.shortName !== undefined && dock?.longName !== undefined
const isDate = (date?: any): date is Date => date instanceof Date

export const customSortTypes: Record<CustomSortTypes, SortByFn<Movement>> = {
  optionalDatetime: (rowA, rowB, colId) => {
    const valA = getColValue(rowA, colId)
    const valB = getColValue(rowB, colId)

    if (isDate(valA) && isDate(valB)) {
      return valA <= valB ? -1 : 1
    }

    if (isDate(valA) && !isDate(valB)) {
      return -1 // Move A before B
    }

    if (!isDate(valA) && isDate(valB)) {
      return 1 // Move A after B
    }

    return 0 // Do nothing
  },
}

type FilterFn<Data extends {}, InputQuery = unknown> = (
  rows: Row<Data>[],
  colId: keyof Row<Data>['values'],
  origQuery: InputQuery
) => Row<Data>[]

const createColumnFilter =
  <Data extends {}, ExpectedData, InputQuery = string>(
    typeAssert: (value: any) => value is ExpectedData,
    filterFn: (value: ExpectedData, query: InputQuery) => boolean
  ): FilterFn<Data, InputQuery> =>
  (rows: Row<Data>[], colId: keyof Row<Data>['values'], origQuery: InputQuery): Row<Data>[] => {
    return rows.filter(row => {
      const data = getColValue(row, colId)

      if (typeAssert(data)) {
        return filterFn(data, origQuery)
      }

      return false
    })
  }

export const customFilterTypes: Record<CustomFilterTypes, FilterFn<Movement, any>> = {
  agentFilter: createColumnFilter<Movement, Agent, string>(
    isAgent,
    (agent, query) =>
      agent?.longName?.toLowerCase().includes(query.toLowerCase()) ||
      agent?.shortName.toLowerCase().includes(query.toLowerCase())
  ),

  dockFilter: createColumnFilter<Movement, Dock, string>(
    isDock,
    (dock, query) =>
      (dock?.shortName.toLowerCase().includes(query.toLowerCase()) ||
        dock?.longName?.toLowerCase().includes(query.toLowerCase())) ??
      false
  ),
  dateTimeRangeFilter: createColumnFilter<Movement, Date | null, DateTimeRange | null>(
    isDate,
    (date, dateTimeRange) => {
      // Get local timezone
      const currentTimeZone = getCurrentTimeZone()
      const localOffset = getTimezoneOffset(currentTimeZone, new Date(Date.now())) / 1000 / 3600

      // Calculate time zone offset for movement date
      const houstonOffset = getTimezoneOffset(HOUSTON_TIME_ZONE, date || undefined) / 1000 / 3600

      // Calculate offset in hours between time zones to offset the filtering below
      const offsetDiff = (houstonOffset - localOffset) * -1

      if (date && dateTimeRange) {
        /* Offset the input start date by timezone offset, so filtered output matches the local time zone

          Otherwise this happens:
          Houston movement time: 12:00 US/Houston UTC-6 -> 18:00 UTC
          Filter range: 12:00 until 13:00 Europe/Amsterdam UTC+1 -> 11:00 until 12:00 UTC
          Result: The filter range corresponds to 04:00 until 05:00 Houston local time, so the movements shown will be off by 7 hours.

          To fix this, we have to offset the filter range by the difference in timezone hours, so the result is as follows
          Houston movement time: 12:00 UTC-6 -> 18:00 UTC
          Filter range: 12:00 until 13:00 UTC+1 -> Offset by 7 hours: 19:00 until 20:00 UTC+1 -> 18:00 until 19:00 UTC
          Result: The filter range corresponds to 12:00 until 13:00 Houston local time, so now the movement from 12:00 UTC-6 will be shown
        */
        const offsetStartDateInput = addHours(dateTimeRange.start, offsetDiff)
        const offsetEndDateInput = addHours(dateTimeRange.end, offsetDiff)

        return [
          // Is selected start before or equal to date of movement
          isAfter(date, offsetStartDateInput) || isEqual(offsetStartDateInput, date),
          // Is date of movement before or equal to selected end
          isBefore(date, offsetEndDateInput) || isEqual(offsetEndDateInput, date),
        ].every(item => item === true)
      }
      // Always show rows if they can't be filtered (yet)
      return true
    }
  ),
}
