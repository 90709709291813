import { TestId } from '../../../utils/testid'

interface IProps {
  testId: TestId
}

export const CheckMarkIcon = ({ testId }: IProps) => (
  <svg data-testid={testId} viewBox="0 0 18 18" height="18px" width="18px" fill="#fff">
    <polygon points="7,11.307692 14.6,4 16,5.3461539 7,14 2,9.1923077 3.4,7.8461539 " />
  </svg>
)
