import React, { useEffect, useMemo, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useMixpanel } from '@portxchange/mixpanel-utils'
import classnames from 'classnames'
import { getYear } from 'date-fns'
import { jwtDecode } from 'jwt-decode'

import { AUTH0_REDIRECT_URI } from '../../../constants/auth'
import { PXP_HOMEPAGE_URL } from '../../../constants/constants'
import { PXP_ADMIN_URL, SHIP_TRACKER_URL, SYNCHRONIZER_URL } from '../../../constants/env-vars'
import { InternalPages } from '../../../constants/routing'
import { useDarkMode } from '../../../hooks/useDarkMode'
import { Toggle } from '../../../ui/Toggle/Toggle'
import { getToken } from '../../../utils/api'
import { trackError } from '../../../utils/trackError'
import { BugReporter } from '../../BugReporter/BugReporter'
import { useUserContext } from '../../UserProvider/UserProvider'
import { DrillDownLevel, DRILL_DOWN_LEFT, DRILL_DOWN_RIGHT } from '../DrillDownLevel/DrillDownLevel'
import { ExternalNavigationItem, InternalNavigationItem, NavigationItem } from '../NavigationItem/NavigationItem'

import styles from './NavigationDrawer.module.scss'

function ShipTrackerNavigationItem() {
  const AUTH_KEY = 'https://pronto.portcalloptimization.nl/authorization'
  const [roles, setRoles] = useState<string[]>([])
  const { track } = useMixpanel()

  useEffect(() => {
    const sub = getToken.subscribe(token => {
      try {
        const jwt: Partial<{ [AUTH_KEY]: { roles: string[] } }> = jwtDecode(token)

        setRoles(jwt[AUTH_KEY]?.roles ?? [])
      } catch (e) {
        if (e instanceof Error) {
          trackError(e)
        }
      }
    })

    return sub.unsubscribe.bind(sub)
  }, [])

  return (
    <>
      {roles.includes('ShipTracker') && (
        <li className={styles.entry}>
          <ExternalNavigationItem
            text="ShipTracker"
            url={SHIP_TRACKER_URL}
            onClick={() => track('Navigate to shipTracker')}
          />
        </li>
      )}
      {['Administrator', 'User Super Admin', 'User Admin'].some(role => roles.includes(role)) && (
        <li className={styles.entry}>
          <ExternalNavigationItem
            text="User Admin"
            url={PXP_ADMIN_URL}
            onClick={() => track('Navigate to pxp admin')}
          />
        </li>
      )}
    </>
  )
}

type NavigationDrawerProps = { isOpen: boolean; onClose: () => void }
export const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ isOpen, onClose }) => {
  const { logout } = useAuth0()
  const {
    user: { defaultPort },
  } = useUserContext()
  const { reset, track } = useMixpanel()
  const currentYear = useMemo(() => getYear(new Date()), [])
  const { isDarkMode, toggleDarkMode } = useDarkMode()
  const [isBugReportActive, setIsBugReportActive] = useState(false)

  useEffect(() => {
    if (!isOpen && !!isBugReportActive) {
      setIsBugReportActive(false)
    }
  }, [isOpen, isBugReportActive])

  const handleLogout = () => {
    reset()
    logout({ returnTo: AUTH0_REDIRECT_URI })
  }

  return (
    <>
      {isOpen && (
        <div
          className={styles.navigationDrawerOverlay}
          onClick={onClose}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        />
      )}
      <nav className={classnames(styles.navigationDrawer, { [styles.navigationDrawerOpen]: isOpen })}>
        <DrillDownLevel active={!isBugReportActive} position={DRILL_DOWN_LEFT}>
          <ul className={classnames(styles.section, styles.sectionTop)}>
            {defaultPort !== 'USCRP' && (
              <li className={styles.entry}>
                <ExternalNavigationItem
                  text="Synchronizer"
                  url={SYNCHRONIZER_URL}
                  onClick={() => track('Navigate to synchronizer')}
                />
              </li>
            )}
            <ShipTrackerNavigationItem />
          </ul>
          <ul className={classnames(styles.section, styles.sectionBottom)}>
            <li className={classnames(styles.entry)}>
              <label className={styles.text}>Dark mode</label>{' '}
              <Toggle isChecked={isDarkMode} handleToggle={toggleDarkMode} />
            </li>
            <li className={styles.entry}>
              <InternalNavigationItem text="FAQ" page={InternalPages.Faq} />
            </li>
            <li className={styles.entry}>
              <NavigationItem onClick={() => setIsBugReportActive(true)} text="Help" />
            </li>
            <li className={styles.entry}>
              <NavigationItem onClick={handleLogout} text="Log out" />
            </li>
            <li className={classnames(styles.entry, styles.entrySmall, styles.entryInactive, styles.inner)}>
              <label className={styles.text}>
                &copy; {currentYear} Pilottracker by{' '}
                <a href={PXP_HOMEPAGE_URL} target="_blank" rel="noopener noreferrer">
                  PortXchange
                </a>
              </label>
            </li>
          </ul>
        </DrillDownLevel>
        <DrillDownLevel active={isBugReportActive} position={DRILL_DOWN_RIGHT}>
          <BugReporter onBack={() => setIsBugReportActive(false)} isActive={isBugReportActive} />
        </DrillDownLevel>
      </nav>
    </>
  )
}
