import { Link, useLocation } from 'react-router-dom'

import { CustomLocationState } from '../../components/Header/NavigationItem/NavigationItem'
import { PAGES } from '../../constants/routing'
import { CloseIcon } from '../icons/CloseIcon/CloseIcon'

import styles from './PageCloseLink.module.scss'

export const PageCloseLink = () => {
  const location = useLocation<CustomLocationState<{ from: string | undefined }>>()
  return (
    <div className={styles.container}>
      <Link to={location.state?.from || PAGES.DASHBOARD}>
        <CloseIcon size={32} className={styles.icon} />
      </Link>
    </div>
  )
}
