import { useMixpanel } from '@portxchange/mixpanel-utils'
import classNames from 'classnames'
import { isEqual } from 'lodash'

import { FilterDefinition, FilterType, useActiveFilters } from '../../state/filters'
import { DropoutMenu } from '../DropoutMenu/DropoutMenu'

import { StaticSection } from './Section'
import styles from './SettingsDropdown.module.scss'

const mixpanelFilterNames: { [type in FilterType]: string } = {
  [FilterType.VESSEL_TYPE]: 'filterVesselType',
  [FilterType.VESSEL_NAME]: 'filterVesselName',
  [FilterType.VESSEL_IMO]: 'filterVesselImo',
  [FilterType.TABLE_FILTERS]: 'filterTable',
  [FilterType.TERMINAL]: 'filterTerminal',
  [FilterType.AGENT]: 'filterAgent',
  [FilterType.FLAG]: 'filterFlag',
}

const FilterSettingsContent = ({
  filterDefinitions,
  onClose,
}: {
  filterDefinitions: FilterDefinition[]
  onClose: () => void
}) => {
  const { track, setUserProperties, register } = useMixpanel()
  const handleReset = () => {
    filterDefinitions.forEach(definition => definition.onReset())
  }

  const handleApply = () => {
    const trackProperties: { [type: string]: string[] } = {}
    filterDefinitions.forEach(definition => {
      trackProperties[mixpanelFilterNames[definition.key]] = definition.selectedOptions.map(
        value => definition.options[value]?.label ?? value
      )
      definition.onSubmit()
    })

    setUserProperties(trackProperties)
    register(trackProperties)
    track('Customize filter settings', trackProperties)

    onClose()
  }

  const hasChanged = filterDefinitions.some(definition => definition.hasChanged)

  return (
    <div className={styles.dropdownContainer}>
      {filterDefinitions.map(definition => {
        const { title, FilterComponent } = definition
        return (
          <StaticSection key={title} title={title}>
            <FilterComponent definition={definition} />
          </StaticSection>
        )
      })}
      <button className={styles.resetButton} onClick={handleReset}>
        Reset filters
      </button>
      <div className={styles.applyButtonContainer}>
        <button
          onClick={handleApply}
          className={classNames(styles.applyButton, {
            [styles.disabled]: !hasChanged,
          })}
          disabled={!hasChanged}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export const FilterSettingsDropdown = ({ filterDefinitions }: { filterDefinitions: FilterDefinition[] }) => {
  const [activeFilters] = useActiveFilters()

  // Compare option arrays for equality.
  // If active options in `activeFilters` matches `getInitialSelected()`, it's pristine
  // and thus nothing is filtered.
  const isPristine = filterDefinitions.every(({ key, getInitialSelected }) =>
    isEqual(activeFilters[key], getInitialSelected())
  )

  return (
    <div className={styles.buttonContainer}>
      <DropoutMenu>
        {({ closeMenu, isOpen, openMenu }) => (
          <>
            <button
              data-testid="filter-dropdown-handle"
              className={classNames(styles.dropdownButton, styles.textButton, { [styles.clicked]: isOpen })}
              onClick={() => (isOpen ? closeMenu() : openMenu())}
            >
              Filters
              <span className={classNames(styles.filterIndicator, { [styles.filterActive]: !isPristine })} />
            </button>
            {isOpen && <FilterSettingsContent onClose={closeMenu} filterDefinitions={filterDefinitions} />}
          </>
        )}
      </DropoutMenu>
    </div>
  )
}
