import { useMixpanel } from '@portxchange/mixpanel-utils'
import classNames from 'classnames'
import { useAtom } from 'jotai'

import { TableType } from '../../Domain/Tracking'
import { TableRowSize, tableRowSizeAtom } from '../../state/tableRowSize'
import { DropoutMenu } from '../DropoutMenu/DropoutMenu'
import { ArrowDownIcon } from '../icons/ArrowIcons/ArrowDownIcon'

import styles from './RowHeightSelector.module.scss'

export const RowHeightSelector = ({ tableType }: { tableType: TableType }) => {
  const [tableRowSize, setTableRowSize] = useAtom(tableRowSizeAtom)
  const { track, setUserProperties } = useMixpanel()

  return (
    <div className={styles.dropoutContainer}>
      <DropoutMenu>
        {({ closeMenu, isOpen, openMenu }) => {
          return (
            <>
              <button
                type="button"
                onClick={() => (isOpen ? closeMenu() : openMenu())}
                className={classNames(styles.dropoutButton, { [styles.open]: isOpen })}
              >
                Row height: <span className={styles.activeValue}>{tableRowSize[0]}</span> <ArrowDownIcon />
              </button>
              {isOpen && (
                <ul className={styles.dropoutMenu}>
                  {Object.values(TableRowSize).map(size => (
                    <li key={size}>
                      <button
                        className={classNames(styles.dropOutMenuButton, {
                          [styles.active]: tableRowSize === size,
                        })}
                        onClick={() => {
                          setTableRowSize(size)
                          setUserProperties('Row Height', size)
                          track('Select Row Height', {
                            tableType,
                            size,
                          })
                          closeMenu()
                        }}
                      >
                        {size}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )
        }}
      </DropoutMenu>
    </div>
  )
}
