import { format, utcToZonedTime } from 'date-fns-tz'

import { NO_DATA_AVAILABLE } from '../constants/constants'
import { HOUSTON_TIME_ZONE, TimeZone } from '../constants/time'

export const timeFormat = 'HH:mm'
export const longDateFormat = 'MM/dd/yyyy'
export const longDateTimeFormat = `${longDateFormat} ${timeFormat}`
export const shortDateFormat = 'MM/dd'
export const shortDateTimeFormat = `${shortDateFormat} ${timeFormat}`

export function formatAsTimeZone(date: Date, formatting: string, timeZone: TimeZone): string {
  const zonedTime = utcToZonedTime(date, timeZone)
  return format(zonedTime, formatting, { timeZone })
}

export const renderTimeInfo = (time?: Date, placeholder: string = NO_DATA_AVAILABLE) =>
  time ? formatAsTimeZone(time, longDateTimeFormat, HOUSTON_TIME_ZONE) : placeholder
