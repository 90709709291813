const DASHBOARD = 'DASHBOARD'
const DASHBOARD_MAP = 'DASHBOARD_MAP'
const VESSEL_DETAILS = 'VESSEL_DETAILS'
const ROOT = 'ROOT'
const FAQ = 'FAQ'
const PORT_NOT_FOUND = 'PORT_NOT_FOUND'

export const PAGES = {
  [DASHBOARD]: 'dashboard',
  [DASHBOARD_MAP]: 'dashboard/map',
  [VESSEL_DETAILS]: 'vessel-details',
  [ROOT]: '',
  [FAQ]: '/faq',
  [PORT_NOT_FOUND]: '/port-not-found',
}

export const DASHBOARD_PAGES = [PAGES.DASHBOARD, PAGES.DASHBOARD_MAP]

export enum InternalPages {
  Dashboard = 'dashboard',
  DashboardMap = 'dashboard/map',
  VesselDetails = 'vessel-details',
  Faq = '/faq',
  PortNotFound = '/port-not-found',
}

export type Page =
  | typeof ROOT
  | typeof DASHBOARD_MAP
  | typeof DASHBOARD
  | typeof FAQ
  | typeof PORT_NOT_FOUND
  | typeof VESSEL_DETAILS
