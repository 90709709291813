/* eslint-disable no-case-declarations */
import React from 'react'

import configureMeasurements, {
  volume,
  mass,
  length,
  VolumeSystems,
  MassSystems,
  LengthSystems,
  VolumeUnits,
  MassUnits,
  LengthUnits,
} from 'convert-units'

import { SystemOfMeasurement } from '../Domain/Me'

import { numberFormatter } from './number-formatter'

const OIL_BARRELS_PER_M3 = 6.28981077

const convert = configureMeasurements<
  'volume' | 'mass' | 'length',
  VolumeSystems | MassSystems | LengthSystems,
  VolumeUnits | MassUnits | LengthUnits
>({ volume, mass, length })

export const formatDecimals = (value: number) => (value % 1 > 0 ? Number(value.toFixed(2)) : value)

export const metersToFeet = (meters: number) => formatDecimals(convert(meters).from('m').to('ft'))

export const feetToMeters = (feet: number) => formatDecimals(convert(feet).from('ft').to('m'))

export const inchesToMeters = (inches: number) => formatDecimals(convert(inches).from('in').to('m'))

export const cbmToCuft = (cbm: number) => formatDecimals(convert(cbm).from('m3').to('ft3'))

export const cbmToBbls = (cbm: number) => formatDecimals(cbm * OIL_BARRELS_PER_M3)

export const draftToMeasurements = (draft: string, measurementSystem: SystemOfMeasurement) => {
  try {
    const match = draft.match(/^(\d+)\/(\d+)$/)

    if (match === null) {
      throw new Error('Improper draft string, continue without formatting.')
    }

    // Split draft string into proper numbers
    const feet = Number(match[1])
    const inches = Number(match[2])

    switch (measurementSystem) {
      case SystemOfMeasurement.Metric:
        const feetMeters = feetToMeters(feet)
        const inchMeters = inchesToMeters(inches)

        return <>{addUnit('m')(feetMeters + inchMeters)}</>
      case SystemOfMeasurement.Imperial:
        return (
          <>
            {addUnit('ft')(feet)} {inches ? addUnit('in')(inches) : null}
          </>
        )
      default:
        const exhaustive: never = measurementSystem
        throw new Error(exhaustive)
    }
  } catch (error) {
    console.error('Cannot convert draft into proper formatted units')
    console.error(error instanceof Error ? error.message : error)

    return draft
  }
}

export const convertToUnit =
  (converter: (n: number) => number, targetUnit: React.ReactNode) =>
  (value?: number): React.ReactNode =>
    value ? addUnit(targetUnit)(converter(value)) : 'N/A'

export const addUnit = (unit: React.ReactNode) => (value: number) =>
  (
    <>
      {numberFormatter(formatDecimals(value))} {unit}
    </>
  )

export const createMeasurementSystemConverter =
  <T extends unknown>(
    systemFormatters: Record<SystemOfMeasurement, (value: T, system: SystemOfMeasurement) => React.ReactNode>
  ) =>
  (value: T, measurementSystem: SystemOfMeasurement) =>
    systemFormatters[measurementSystem](value, measurementSystem)
