import React from 'react'

import styles from './Pill.module.scss'

type PillProps = { children: string; ['data-testid']?: string }
export const Pill = ({ children, ...props }: PillProps) => {
  return (
    <span className={styles.pill} {...props}>
      <span className={styles.pillInner}>{children}</span>
    </span>
  )
}
