import variables from './variables.module.scss'

export const {
  catalinaBlue,
  mediumblue,
  white,
  mostlyPureYellow,
  green,
  cerulean,
  alto,
  darkgrey,
  darkblue,
  transparent,
} = variables
