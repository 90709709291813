import { useState } from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import classnames from 'classnames'
import { useAtom } from 'jotai'
import { Column, Row } from 'react-table'

import { useDeleteSubscribeToMovement } from '../../../../Api/Movement/deleteSubscribeToMovement'
import { usePostSubscribeToMovement } from '../../../../Api/Movement/postSubscribeToMovement'
import { getImoFromMovement, Movement } from '../../../../Domain/Movement'
import { TableType } from '../../../../Domain/Tracking'
import { useVesselDetailsLink } from '../../../../hooks/useVesselDetailsLink'
import { notificationsRefetchCounterAtom } from '../../../../state/notificationsRefetchCounter'
import { Checkbox } from '../../../../ui/Checkbox/Checkbox'
import { DropoutMenu } from '../../../../ui/DropoutMenu/DropoutMenu'
import { TopRightDropoutMenuDropper } from '../../../../ui/DropoutMenu/DropoutMenuDropper'
import { DotsIcon } from '../../../../ui/icons/DotsIcon/DotsIcon'
import { TestId } from '../../../../utils/testid'
import { ConfirmationModal } from '../../../Modal/ConfirmationModal'

import styles from './OverflowCell.module.scss'

interface IProps {
  tableType: TableType
  subscribed: boolean
  movementId: string
  tooltipKey: string
  disabled?: boolean
}

export enum NotificationCellTitle {
  Subscribed = 'Would you like to unsubscribe?',
  Unsubscribed = 'Would you like to be notified when pilot is on board for this movement?',
}

export enum NotificationCellConfirmText {
  Subscribed = 'Yes, unsubscribe me!',
  Unsubscribed = 'Yes, notify me!',
}

export enum NotificationCellTooltipText {
  PilotIsAlreadyOnBoard = 'Pilot has boarded',
}

export const OverflowDropdown = <ColumnData extends {}>({
  subscribed,
  row,
  column,
  tableType,
}: {
  subscribed: boolean
  row: Row<Movement>
  column: Column<ColumnData>
  tableType: TableType
}) => {
  const { goToVesselDetails } = useVesselDetailsLink()
  const movement = row.original
  const imo = getImoFromMovement(movement)
  const { track } = useMixpanel()

  return (
    <div
      className={styles.overflowMenu}
      onClick={evt => {
        evt.stopPropagation()
      }}>
      <DropoutMenu>
        {({ isOpen, openMenu, closeMenu }) => (
          <>
            <span
              className={styles.dotsButton}
              onClick={() => {
                if (isOpen) {
                  closeMenu()
                } else {
                  openMenu()
                }
              }}>
              <DotsIcon />
            </span>
            {isOpen && (
              <TopRightDropoutMenuDropper hasTriangle={false} className={styles.overflowDropout}>
                <div className={styles.section}>
                  <NotificationCell
                    tableType={tableType}
                    subscribed={subscribed}
                    movementId={movement.movementId}
                    tooltipKey={`${row.id + column.id}`}
                    disabled={!!(movement.underWayTime || movement.offTime)}
                  />
                </div>
                {imo !== null && (
                  <div className={styles.section}>
                    <button
                      type="button"
                      className={styles.sectionAction}
                      onClick={() => {
                        track('View vessel information', { tableType })
                        goToVesselDetails(imo)
                      }}>
                      Vessel Information
                    </button>
                  </div>
                )}
              </TopRightDropoutMenuDropper>
            )}
          </>
        )}
      </DropoutMenu>
    </div>
  )
}

export const NotificationCell = ({ tableType, subscribed, movementId, disabled, tooltipKey }: IProps) => {
  const { track } = useMixpanel()
  const [, setNotificationsRefetchCounter] = useAtom(notificationsRefetchCounterAtom)
  const postSubscribeToMovement = usePostSubscribeToMovement()
  const deleteSubscribeToMovement = useDeleteSubscribeToMovement()
  const [isVisible, setIsVisible] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isError, setIsError] = useState(false)

  const title = subscribed ? NotificationCellTitle.Subscribed : NotificationCellTitle.Unsubscribed
  const confirmText = subscribed ? NotificationCellConfirmText.Subscribed : NotificationCellConfirmText.Unsubscribed

  const onConfirm = async () => {
    setIsProcessing(true)

    const isSubscribed = subscribed
      ? await deleteSubscribeToMovement(movementId)
      : await postSubscribeToMovement(movementId)

    if (isSubscribed) {
      setNotificationsRefetchCounter(prev => prev + 1)
      setIsVisible(false)
      setIsProcessing(false)
      track('Set alerts', {
        tableType,
      })
    } else {
      setIsProcessing(false)
      setIsError(true)
    }
  }

  const onCancel = () => {
    setIsVisible(false)
    setIsError(false)
  }

  return (
    <>
      <Checkbox
        isChecked={subscribed}
        label="Pilot on board notification"
        className={classnames(styles.sectionAction, {
          [styles.checkboxDisabled]: disabled,
        })}
        onChange={() => {
          if (!disabled) setIsVisible(true)
        }}
        disabled={disabled}
        data-tip
        data-for={tooltipKey}
      />
      <ConfirmationModal
        isVisible={isVisible}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title={title}
        text={!subscribed && 'You will be notified by email.'}
        cancelText="Cancel"
        confirmText={confirmText}
        isProcessing={isProcessing}
        isError={isError}
        testId={TestId.NotificationConfirmationModal}
      />
    </>
  )
}
