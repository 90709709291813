import classNames from 'classnames'

import { UpdatePill } from '../../../../ui/UpdatePill/UpdatePill'
import { renderTimeInfo } from '../../../../utils/datetime'

import styles from './TimeCell.module.scss'

interface Props {
  text: string
  time?: Date
  previousTime?: {
    value: Date
    tooltipKey: string
  }
  onShow?: () => void
}

export const TimeCell = ({ text, time, previousTime, onShow }: Props) => {
  return (
    <div className={classNames(styles.container, { [styles.smallGap]: previousTime })}>
      <span>{text}</span>
      <UpdatePill
        value={renderTimeInfo(time)}
        previous={
          previousTime ? { value: renderTimeInfo(previousTime.value), tooltipKey: previousTime.tooltipKey } : undefined
        }
        onShow={onShow}
      />
    </div>
  )
}
