import { useCallback, useEffect, useState } from 'react'

import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { Movement, StatusPill } from '../Domain/Movement'
import { recordFromArray } from '../utils/array'

export enum FilterType {
  VESSEL_TYPE = 'VESSEL_TYPE',
  VESSEL_NAME = 'VESSEL_NAME',
  VESSEL_IMO = 'VESSEL_IMO',
  TABLE_FILTERS = 'TABLE_FILTERS',
  TERMINAL = 'TERMINAL',
  AGENT = 'AGENT',
  FLAG = 'FLAG',
}

const whenFilterDefined = (options?: string[]) => (callback: (options: string[]) => boolean) =>
  !options || options.length === 0 || callback(options)

export const filterFunctions: {
  [key in FilterType]: (movement: Movement, selectedFilterOptions?: string[]) => boolean
} = {
  VESSEL_TYPE: (movement, options) =>
    whenFilterDefined(options)(defined => defined.includes(movement.vessel.vesselType)),
  VESSEL_NAME: (movement, options) => whenFilterDefined(options)(defined => defined.includes(movement.vessel.name)),
  VESSEL_IMO: (movement, options) => whenFilterDefined(options)(defined => defined.includes(movement.vessel.imo ?? '')),
  TABLE_FILTERS: (movement, options) =>
    whenFilterDefined(options)(
      defined =>
        (!defined.includes('pob') || movement.status === StatusPill.PilotOnBoard) &&
        (!defined.includes('subscribed') || movement.subscribed)
    ),
  TERMINAL: (movement, options) =>
    whenFilterDefined(options)(
      defined =>
        (!!movement.from?.terminal && defined.includes(movement.from.terminal)) ||
        (!!movement.to?.terminal && defined.includes(movement.to.terminal))
    ),
  AGENT: (movement, options) =>
    whenFilterDefined(options)(defined =>
      movement.agent?.shortName ? defined.includes(movement.agent.shortName) : false
    ),
  FLAG: (movement, options) =>
    whenFilterDefined(options)(defined => (movement.vessel?.flag ? defined.includes(movement.vessel?.flag) : false)),
}

type ActiveFiltersAtom = { [key in FilterType]?: string[] }
const activeFiltersAtom = atomWithStorage<ActiveFiltersAtom>('active-filters', {})

export const useActiveFilters = () => useAtom(activeFiltersAtom)
const getListOfActiveFilters = (value: ActiveFiltersAtom) =>
  Object.entries(value) as [FilterType, string[] | undefined][]

export type FilterOption = { label: string; count: number; value: string }

export type FilterDefinition = {
  key: FilterType
  title: string
  hasChanged: boolean
  options: { [value: string]: FilterOption }
  selectedOptions: string[]
  getInitialSelected: () => string[]
  onChange: (newOptions: string[]) => void
  onSubmit: () => void
  onReset: () => void
  FilterComponent: React.FC<{ definition: FilterDefinition }>
}
type CreateFilterDefinition = (
  config: {
    key: FilterType
    title: string
    getOptions: (countByOption: (movements: Movement[], value: string) => number) => FilterOption[]
    getInitialSelected: () => string[]
    FilterComponent: React.FC<{ definition: FilterDefinition }>
  },
  dependencies: React.DependencyList
) => FilterDefinition

const countByOption = (key: FilterType) => (movements: Movement[], optionValue: string) =>
  movements.filter(movement => filterFunctions[key](movement, [optionValue])).length

export const useCreateFilterDefinition: CreateFilterDefinition = (
  { key, getOptions, getInitialSelected, FilterComponent, title },
  dependencies
) => {
  const [activeFilters, setActiveFilters] = useActiveFilters()
  const [hasChanged, setHasChanged] = useState(false)
  const [filter, setFilter] = useState<{
    options: { [value: string]: FilterOption }
    selected: string[]
  }>(() => {
    const initialOptions = getOptions(countByOption(key))
    return {
      options: recordFromArray(initialOptions, option => option.value),
      selected:
        activeFilters[key] ?? (getInitialSelected ? getInitialSelected!() : initialOptions.map(({ value }) => value)),
    }
  })

  const handleChange = useCallback((newOptions: string[]) => {
    setFilter(currentFilter => ({
      ...currentFilter,
      selected: newOptions,
    }))
    setHasChanged(true)
  }, [])

  const handleSubmit = useCallback(() => {
    setActiveFilters(filters => ({
      ...filters,
      [key]: filter.selected,
    }))
    setHasChanged(false)
  }, [filter.selected, key, setActiveFilters])

  const handleReset = useCallback(() => {
    handleChange(
      getInitialSelected
        ? getInitialSelected!()
        : Object.values(getOptions(countByOption(key))).map(({ value }) => value)
    )
  }, [getInitialSelected, getOptions, handleChange, key])

  useEffect(() => {
    setFilter(currentFilter => ({
      ...currentFilter,
      options: recordFromArray(getOptions(countByOption(key)), option => option.value),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, ...dependencies])

  return {
    key,
    hasChanged,
    options: filter.options,
    selectedOptions: filter.selected,
    getInitialSelected,
    onChange: handleChange,
    onSubmit: handleSubmit,
    onReset: handleReset,
    FilterComponent,
    title,
  }
}

export const useFilteredMovements = (movements: Movement[]) => {
  const [activeFilters] = useActiveFilters()
  const listOfActiveFilters = getListOfActiveFilters(activeFilters)

  return movements.filter(movement =>
    listOfActiveFilters.every(([filterType, filterOptions]) => filterFunctions[filterType](movement, filterOptions))
  )
}
