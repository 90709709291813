import React from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import { MixpanelProvider } from '@portxchange/mixpanel-utils'
import * as Sentry from '@sentry/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { render } from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'

import { AUTH0_AUDIENCE, AUTH0_REDIRECT_URI } from './constants/auth'
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  isTestEnv,
  MIXPANEL_API_HOST,
  MIXPANEL_TOKEN,
  SENTRY_DSN,
} from './constants/env-vars'
import { PortalProvider } from './hooks/usePortal'
import { ResponsivenessProvider } from './hooks/useResponsiveness'
import { Router } from './Router'
import { Page } from './ui/Page/Page'

import './styles/base.module.scss'
import './styles/theme.module.scss'

export const sentry = Sentry.init({
  dsn: SENTRY_DSN,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

render(
  <React.StrictMode>
    <Auth0Provider
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      redirectUri={AUTH0_REDIRECT_URI}
      audience={AUTH0_AUDIENCE}
      useRefreshTokens>
      <MixpanelProvider mixpanelId={MIXPANEL_TOKEN} config={{ api_host: MIXPANEL_API_HOST, debug: isTestEnv }}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <ResponsivenessProvider>
                <PortalProvider id="modals">
                  <Page>
                    <Router />
                  </Page>
                </PortalProvider>
              </ResponsivenessProvider>
            </DndProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </MixpanelProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
