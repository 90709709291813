import React from 'react'

import classNames from 'classnames'

import styles from './SettingsDropdown.module.scss'

type StaticSectionProps = { title: string; className?: string }
export const StaticSection: React.FC<StaticSectionProps> = ({ title, className, children }) => (
  <details className={classNames(styles.section, className)} open>
    <summary className={classNames(styles.header, styles.isStatic)}>
      <h4 className={styles.sectionTitle}>{title}</h4>
    </summary>
    {children}
  </details>
)
