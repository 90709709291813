export const chunkArray = <T>(array: T[], size: number) => {
  const chunked: T[][] = []
  let index = 0

  while (array.length > 0) {
    chunked.push(array.slice(index, size + index))
    index += size
  }

  return chunked
}

export const filterNonNullable = <T>(item?: T | null): item is T => item !== undefined && item !== null

export const recordFromArray = <T>(arr: T[], keyAccessor: (element: T) => string) =>
  arr.reduce((acc, element) => ({ ...acc, [keyAccessor(element)]: element }), {} as { [key: string]: T })

export const sortAlphabetically =
  <T>(accessor: (element: T) => string) =>
  (a: T, b: T) => {
    const stringA = accessor(a).toLowerCase()
    const stringB = accessor(b).toLowerCase()

    if (stringA < stringB) {
      return -1
    }
    if (stringA > stringB) {
      return 1
    }
    return 0
  }
