import React from 'react'

export const PilotOnBoardVesselIcon = () => (
  <svg width={18} height={27} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="97.701%" id="pilotonboard">
        <stop stopColor="#F16FB7" offset="0%" />
        <stop stopColor="#DF1885" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M37 10.947l8-10 8 10v16l-8-5-8 5z"
      transform="translate(-36 -.947)"
      fill="url(#pilotonboard)"
      stroke="#9F0158"
      fillRule="evenodd"
    />
  </svg>
)

export const InboundVesselIcon = () => (
  <svg width={18} height={27} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="97.701%" id="inbound">
        <stop stopColor="#6CCA66" offset="0%" />
        <stop stopColor="#3DA739" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M37 10.947l8-10 8 10v16l-8-5-8 5z"
      transform="translate(-36 -.947)"
      fill="url(#inbound)"
      stroke="#22881E"
      fillRule="evenodd"
    />
  </svg>
)

export const ShiftingVesselIcon = () => (
  <svg width={18} height={27} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="97.701%" id="shifting">
        <stop stopColor="#D9D9D9" offset="0%" />
        <stop stopColor="#B6B6B6" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M37 10.947l8-10 8 10v16l-8-5-8 5z"
      transform="translate(-36 -.947)"
      fill="url(#shifting)"
      stroke="#A6A6A6"
      fillRule="evenodd"
    />
  </svg>
)

export const OutboundVesselIcon = () => (
  <svg width={18} height={27} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="97.701%" id="outbound">
        <stop stopColor="#52a9ca" offset="0%" />
        <stop stopColor="#0686b8" offset="100%" />
      </linearGradient>
    </defs>
    <path
      d="M37 10.947l8-10 8 10v16l-8-5-8 5z"
      transform="translate(-36 -.947)"
      fill="url(#outbound)"
      stroke="#0a79a4"
      fillRule="evenodd"
    />
  </svg>
)
