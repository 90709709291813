import { Button } from '@portxchange/button/dist/Button'

import { Header } from '../../components/Header/Header'
import { Card, CardTitle } from '../../ui/Card/Card'
import { Centered } from '../../ui/Centered/Centered'

type LoginProps = {
  onLogin: () => void
}

export const Login: React.FC<LoginProps> = ({ onLogin, children }) => {
  return (
    <>
      <Header />
      <Centered>
        <Card>
          <CardTitle>Login</CardTitle>
          {children}
          <Button type="button" text="Log in" onClick={onLogin} />
        </Card>
      </Centered>
    </>
  )
}
