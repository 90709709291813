import { useState, lazy, Suspense, useRef } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useMixpanel } from '@portxchange/mixpanel-utils'
import classNames from 'classnames'
import { useAtom } from 'jotai'
import { useQuery } from 'react-query'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import { fetchAgents } from '../../Api/Agent/fetchAgents'
import { fetchMovements } from '../../Api/Movement/fetchMovements'
import { fetchTerminals } from '../../Api/Terminal/fetchTerminals'
import { ErrorScreen } from '../../components/ErrorScreen/ErrorScreen'
import { Header } from '../../components/Header/Header'
import { LegendBox } from '../../components/Map/LegendBox/LegendBox'
import { useActivePortContext } from '../../components/Port/ActivePort'
import { Table } from '../../components/Table/Table'
import { MovementFieldGetter, TinyTable } from '../../components/Table/TinyTable'
import { useUserContext } from '../../components/UserProvider/UserProvider'
import { AUTH0_REDIRECT_URI } from '../../constants/auth'
import { REACT_QUERY_KEYS } from '../../constants/react-query'
import { DEFAULT_SORTING } from '../../constants/table'
import { MINUTE } from '../../constants/time'
import { getRowLabel, Movement } from '../../Domain/Movement'
import { TableType } from '../../Domain/Tracking'
import { RenderQueryResult } from '../../hoc/RenderQueryResult'
import { useResponsiveness } from '../../hooks/useResponsiveness'
import { FilterType, useCreateFilterDefinition, useFilteredMovements } from '../../state/filters'
import { notificationsRefetchCounterAtom } from '../../state/notificationsRefetchCounter'
import { ChevronRight } from '../../ui/icons/ChevronRight/ChevronRight'
import { LoadingScreen } from '../../ui/LoadingScreen/LoadingScreen'
import { RowHeightSelector } from '../../ui/RowHeightSelector/RowHeightSelector'
import { CheckboxListSetting } from '../../ui/SettingsDropdown/CheckboxListSetting'
import { FilterSettingsDropdown } from '../../ui/SettingsDropdown/FilterSettingsDropdown'
import { SearchDropdownSetting } from '../../ui/SettingsDropdown/SearchDropdownSetting'
import { TableSettingsDropdown } from '../../ui/SettingsDropdown/TableSettingsDropdown'
import { findCountryByCode } from '../../utils/countries'

import { customSortTypes, customFilterTypes } from './column-filters'
import { mainTableColumns, mapTableColumns } from './columns'
import styles from './Dashboard.module.scss'

const MapContainer = lazy(() => import('./MapContainer'))
const MapContent = lazy(() => import('./MapContent'))

type DashboardProps = {
  movements: Movement[]
}

const isNotUndefined = <A extends {}>(a: A | undefined): a is A => a !== undefined

// Tiny Table filter logic
const vesselNameGetter: MovementFieldGetter = (m: Movement) => m.vessel.name
const vesselImoGetter: MovementFieldGetter = (m: Movement) => m.vessel?.imo
const movementFromShortNameGetter: MovementFieldGetter = (m: Movement) => m.from?.shortName
const movementFromLongNameGetter: MovementFieldGetter = (m: Movement) => m.from?.longName
const movementToShortNameGetter: MovementFieldGetter = (m: Movement) => m.to?.shortName
const movementToLongNameGetter: MovementFieldGetter = (m: Movement) => m.to?.longName

const fieldGetters: MovementFieldGetter[] = [
  vesselNameGetter,
  vesselImoGetter,
  movementFromShortNameGetter,
  movementFromLongNameGetter,
  movementToShortNameGetter,
  movementToLongNameGetter,
]

const DashboardMap = ({ movements }: DashboardProps) => {
  const { activePort } = useActivePortContext()
  const [isTableVisible, setIsTableVisible] = useState(true)
  const params = useParams<{ movement?: string }>()
  const activeMovement = params.movement ? movements.find(m => m.movementId === params.movement) : undefined

  const container = useRef<HTMLDivElement>(null)

  return (
    <div
      className={classNames(styles.mapTableContainer, {
        [styles.mapTableContainerFullMap]: !isTableVisible,
      })}>
      <MapContainer movements={movements} selectedMovement={activeMovement} center={activePort.center}>
        {vessels => (
          <MapContent vessels={vessels} selectedMovement={activeMovement}>
            {({ selectedItem, onSelectItem, movementIdsVisibleOnMap }) => (
              <div
                ref={container}
                className={classNames(styles.tableButtonContainer, {
                  [styles.tableButtonContainerHidden]: !isTableVisible,
                })}>
                <div
                  className={classNames(styles.tableContainer, {
                    [styles.tableContainerHidden]: !isTableVisible,
                  })}>
                  <Table
                    tableType={TableType.MAP}
                    columns={mapTableColumns}
                    data={movements}
                    defaultSorting={DEFAULT_SORTING}
                    sortTypes={customSortTypes}
                    filterTypes={customFilterTypes}
                    highlightedItem={selectedItem}
                    itemToString={item => item.movementId}
                    itemToDisabled={item => !movementIdsVisibleOnMap.has(item.movementId)}
                    itemToRowLabel={item => getRowLabel(item)}
                    onSelectRow={onSelectItem}
                  />
                </div>
                <button
                  aria-label="show/hide table"
                  className={styles.visibilityButton}
                  onClick={() => setIsTableVisible(!isTableVisible)}>
                  <span
                    className={classNames(styles.visibilityIcon, {
                      [styles.visibilityIconRotated]: !isTableVisible,
                    })}>
                    <ChevronRight />
                  </span>
                </button>
              </div>
            )}
          </MapContent>
        )}
      </MapContainer>
      <div className={classNames(styles.legendContainer)}>
        <LegendBox />
      </div>
    </div>
  )
}

const Dashboard = ({ movements }: DashboardProps) => (
  <Table
    tableType={TableType.DASHBOARD}
    columns={mainTableColumns}
    data={movements}
    defaultSorting={DEFAULT_SORTING}
    itemToString={item => item.movementId}
    itemToRowLabel={item => getRowLabel(item)}
    sortTypes={customSortTypes}
    filterTypes={customFilterTypes}
  />
)

export const DashboardContainer: React.FC = () => {
  const { user } = useUserContext()
  const { logout } = useAuth0()
  const { reset } = useMixpanel()

  const [notificationsRefetchCounter] = useAtom(notificationsRefetchCounterAtom)

  const movementsQuery = useQuery(
    [[REACT_QUERY_KEYS.movements, user.defaultPort], notificationsRefetchCounter],
    () => fetchMovements(user.measurementUnit, user.defaultPort),
    {
      refetchInterval: MINUTE,
      retry: 0,
      keepPreviousData: true,
      onError: error => {
        if (error instanceof Error && error.message.includes('401')) {
          reset()
          logout({ returnTo: AUTH0_REDIRECT_URI })
        }
      },
    }
  )

  return (
    <>
      <div className={styles.dashboardContainer}>
        <RenderQueryResult query={movementsQuery} ErrorComponent={<ErrorScreen />} LoadingComponent={<LoadingScreen />}>
          {movements => <FilteredDashboard movements={movements} />}
        </RenderQueryResult>
      </div>
    </>
  )
}

export const FilteredDashboard: React.FC<{ movements: Movement[] }> = ({ movements }) => {
  const { user } = useUserContext()

  const { path } = useRouteMatch()
  const { isMobile } = useResponsiveness()
  const { data: terminals } = useQuery([REACT_QUERY_KEYS.terminals, user.defaultPort], () =>
    fetchTerminals(user.defaultPort)
  )
  const { data: agents } = useQuery([REACT_QUERY_KEYS.agents, user.defaultPort], () => fetchAgents(user.defaultPort))

  const pobFilterDefinition = useCreateFilterDefinition(
    {
      key: FilterType.TABLE_FILTERS,
      title: 'Table filter',
      getOptions: countByOption => [
        {
          count: countByOption(movements, 'pob'),
          label: 'Pilot On Board only',
          value: 'pob',
        },
        {
          count: countByOption(movements, 'subscribed'),
          label: 'Subscribed notifications only',
          value: 'subscribed',
        },
      ],
      getInitialSelected: () => [],
      FilterComponent: CheckboxListSetting,
    },
    [movements]
  )

  const vesselTypeFilterDefinition = useCreateFilterDefinition(
    {
      key: FilterType.VESSEL_TYPE,
      title: 'Vessel type',
      getOptions: countByOption =>
        [...new Set(movements.map(item => item.vessel.vesselType))].map(type => ({
          count: countByOption(movements, type),
          label: type.toUpperCase(),
          value: type,
        })),
      getInitialSelected: () => [],
      FilterComponent: SearchDropdownSetting,
    },
    [movements]
  )

  const terminalFilterDefinition = useCreateFilterDefinition(
    {
      key: FilterType.TERMINAL,
      title: 'Terminal',
      getOptions: countByOption =>
        terminals
          ? terminals.map(({ uuid, name }) => ({
              count: countByOption(movements, uuid),
              label: name,
              value: uuid,
            }))
          : [],
      getInitialSelected: () => [],
      FilterComponent: SearchDropdownSetting,
    },
    [terminals, movements]
  )
  const agentFilterDefinition = useCreateFilterDefinition(
    {
      key: FilterType.AGENT,
      title: 'Agent',
      getOptions: countByOption =>
        agents
          ? agents.map(({ shortName, longName }) => ({
              count: countByOption(movements, shortName),
              label: longName,
              value: shortName,
            }))
          : [],
      getInitialSelected: () => [],
      FilterComponent: SearchDropdownSetting,
    },
    [agents]
  )
  const flagFilterDefinition = useCreateFilterDefinition(
    {
      key: FilterType.FLAG,
      title: 'Flag',
      getOptions: countByOption =>
        [...new Set(movements.map(({ vessel: { flag } }) => flag).filter(isNotUndefined))]
          .map(flag => {
            const country = findCountryByCode(flag)?.name ?? flag
            return {
              label: country.toUpperCase(),
              count: countByOption(movements, flag),
              value: flag,
            }
          })
          .concat({ label: 'OTHER', count: countByOption(movements, ''), value: '' }),
      getInitialSelected: () => [],
      FilterComponent: SearchDropdownSetting,
    },
    [movements]
  )

  const filteredMovements = useFilteredMovements(movements)

  // TODO: for Mobile search vessel name / IMO / optionaly: from/to
  return (
    <>
      <Header>
        {({ isToggled }) => (
          <>
            {!isMobile && <RowHeightSelector tableType={isToggled ? TableType.MAP : TableType.DASHBOARD} />}

            <FilterSettingsDropdown
              filterDefinitions={[
                pobFilterDefinition,
                vesselTypeFilterDefinition,
                terminalFilterDefinition,
                agentFilterDefinition,
                flagFilterDefinition,
              ]}
            />

            {!isMobile && <TableSettingsDropdown isDisabled={isToggled} />}
          </>
        )}
      </Header>
      <Suspense fallback={<LoadingScreen />}>
        {isMobile ? (
          <TinyTable movements={filteredMovements} fieldGetters={fieldGetters} />
        ) : (
          <Switch>
            <Route path={`${path}/map/:movement?`}>
              <DashboardMap movements={filteredMovements} />
            </Route>
            <Route path={path} exact>
              <Dashboard movements={filteredMovements} />
            </Route>
            <Redirect to={path} />
          </Switch>
        )}
      </Suspense>
    </>
  )
}
