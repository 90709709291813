import { Unlocode } from './Port'

export enum SystemOfMeasurement {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export type User = {
  company: string
  trackingOptOut?: boolean
  measurementUnit: SystemOfMeasurement
  defaultPort: Unlocode
}
