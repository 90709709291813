import { Dispatch, SetStateAction, useCallback, useRef } from 'react'

import classNames from 'classnames'
import update from 'immutability-helper'

import { ColumnSetting } from '../../components/TableSettingsProvider/TableSettingsProvider'
import { Checkbox } from '../Checkbox/Checkbox'
import { DragHandleIcon } from '../icons/DragHandleIcon/DragHandleIcon'

import { ALWAYS_VISIBLE, NON_DRAGGABLE } from './constants'
import styles from './SettingsDropdown.module.scss'
import { useColumnDragAndDrop, useParentDragAndDrop } from './useColumnDragAndDrop'

const ColumnItem = ({
  column,
  onToggle,
  moveColumn,
  findColumnWithIndex,
}: {
  column: ColumnSetting
  onToggle: (id: string) => void
  moveColumn: (id: string, index: number) => void
  findColumnWithIndex: (id: string) => { column: ColumnSetting; index: number }
}) => {
  const ref = useRef<HTMLLIElement>(null)
  const { dragHandlerRef, isDragging } = useColumnDragAndDrop(ref, column, moveColumn, findColumnWithIndex)
  const isAlwaysVisible = ALWAYS_VISIBLE.includes(column.id)

  return (
    <li ref={ref} className={classNames(styles.listItem, { [styles.isDragging]: isDragging })}>
      <Checkbox
        isChecked={isAlwaysVisible || column.isVisible}
        label={column.label}
        onChange={() => onToggle(column.id)}
        disabled={isAlwaysVisible}
      />
      <div
        className={classNames(styles.dragHandler, { [styles.isDisabled]: NON_DRAGGABLE.includes(column.id) })}
        ref={dragHandlerRef}>
        <DragHandleIcon />
      </div>
    </li>
  )
}

type ColumnOrderVisibilitySettingProps = {
  setNewColumns: Dispatch<SetStateAction<ColumnSetting[]>>
  onHasChanged: () => void
  columns: ColumnSetting[]
}
export const ColumnOrderVisibilitySetting = ({
  columns,
  setNewColumns,
  onHasChanged,
}: ColumnOrderVisibilitySettingProps) => {
  const handleVisibilityChange = (columnId: string) => {
    setNewColumns(prev => {
      const next = [...prev]
      const { index: columnIndex } = findColumnWithIndex(columnId)

      if (columnIndex > -1) {
        const foundColumn = next[columnIndex]
        next[columnIndex] = { ...foundColumn, isVisible: !foundColumn.isVisible }
      }

      return next
    })
    onHasChanged()
  }

  const findColumnWithIndex = useCallback(
    (id: string) => {
      const index = columns.findIndex(column => column.id === id)
      return { column: columns[index], index }
    },
    [columns]
  )

  const moveColumn = useCallback(
    (fromColumnId: string, toIndex: number) => {
      const { index: fromIndex, column } = findColumnWithIndex(fromColumnId)

      if (fromIndex > -1 && toIndex > -1) {
        setNewColumns(prev =>
          update(prev, {
            $splice: [
              [fromIndex, 1],
              [toIndex, 0, column],
            ],
          })
        )
        onHasChanged()
      }
    },
    [findColumnWithIndex, onHasChanged, setNewColumns]
  )

  const dndParentRef = useParentDragAndDrop()

  return (
    <ul ref={dndParentRef} className={styles.list}>
      {columns.map(column => (
        <ColumnItem
          key={column.id}
          column={column}
          onToggle={handleVisibilityChange}
          findColumnWithIndex={findColumnWithIndex}
          moveColumn={moveColumn}
        />
      ))}
    </ul>
  )
}
