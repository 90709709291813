import { MovementType } from '../../Domain/Movement'

type MovementOrPortStatusProps = {
  type: MovementType
  Movement: React.ReactNode
  PortStatus: React.ReactNode
}

export const MovementOrPortStatus = ({ type, Movement, PortStatus }: MovementOrPortStatusProps) =>
  type === MovementType.Movement ? <>{Movement}</> : <>{PortStatus}</>
