import * as Sentry from '@sentry/react'

import { DEPLOYMENT_ENVIRONMENT, isTestEnv } from '../constants/env-vars'

export function trackError(e: Error) {
  if (!isTestEnv) {
    Sentry.captureException(e, { extra: { environment: DEPLOYMENT_ENVIRONMENT } })
  } else {
    throw e
  }
}
