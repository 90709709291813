import React from 'react'

import classnames from 'classnames'

import styles from './DropoutMenuDropper.module.scss'
import { horizontalArrowOffset } from './variables'

export enum PositionX {
  Left,
  Center,
  Right,
}

export enum PositionY {
  Top,
  Bottom,
}

export type DropoutMenuDropperTheme = Readonly<{ triangleTop?: string; triangleBottom?: string }>

type DropoutMenuDropperProps = Readonly<{
  theme?: DropoutMenuDropperTheme
  className?: string
  positionX: PositionX
  positionY: PositionY
  triangleOffsetLeft?: number
  triangleOffsetRight?: number
  triangleOffsetTop?: number
  triangleOffsetBottom?: number
  hasTriangleSpace?: boolean
  hasTriangle?: boolean
  isHorizontal?: boolean
}>

const DropoutMenuDropper: React.FC<DropoutMenuDropperProps> = ({
  children,
  className,
  positionX,
  positionY,
  triangleOffsetLeft = 'auto',
  triangleOffsetRight = 'auto',
  triangleOffsetTop = 'auto',
  triangleOffsetBottom = 'auto',
  hasTriangleSpace = false,
  hasTriangle = true,
  isHorizontal = false,
  theme = {},
}) => {
  const triangleStyle = isHorizontal
    ? {
        marginTop: triangleOffsetTop,
        marginBottom: triangleOffsetBottom,
      }
    : {
        marginLeft: triangleOffsetLeft,
        marginRight: triangleOffsetRight,
      }

  return (
    <div
      className={classnames(styles.dropoutDropper, {
        [styles.left]: positionX === PositionX.Left,
        [styles.right]: positionX === PositionX.Right,
        [styles.center]: positionX === PositionX.Center,
        [styles.top]: positionY === PositionY.Top,
        [styles.bottom]: positionY === PositionY.Bottom,
        [styles.triangleSpace]: hasTriangleSpace,
        [styles.horizontal]: isHorizontal,
      })}
    >
      {hasTriangle && (
        <div
          className={classnames(styles.triangle, {
            [styles.offsetLeft]: triangleOffsetLeft !== 'auto' || triangleOffsetBottom !== 'auto',
            [styles.offsetRight]: triangleOffsetRight !== 'auto' || triangleOffsetTop !== 'auto',
            [theme.triangleTop || styles.top]: positionY === PositionY.Top,
            [theme.triangleBottom || styles.bottom]: positionY === PositionY.Bottom,
          })}
          style={triangleStyle}
        />
      )}
      <div className={classnames(styles.content, className)}>{children}</div>
    </div>
  )
}

type PositionedMenuDroppperProps = Pick<
  DropoutMenuDropperProps,
  | 'className'
  | 'hasTriangleSpace'
  | 'hasTriangle'
  | 'triangleOffsetLeft'
  | 'triangleOffsetRight'
  | 'triangleOffsetTop'
  | 'triangleOffsetBottom'
  | 'theme'
  | 'isHorizontal'
>

export const TopLeftDropoutMenuDropper: React.FC<PositionedMenuDroppperProps> = props => (
  <DropoutMenuDropper
    positionY={PositionY.Top}
    positionX={PositionX.Left}
    triangleOffsetLeft={horizontalArrowOffset}
    {...props}
  >
    {props.children}
  </DropoutMenuDropper>
)

export const TopCenterDropoutMenuDropper: React.FC<PositionedMenuDroppperProps> = props => (
  <DropoutMenuDropper positionY={PositionY.Top} positionX={PositionX.Center} {...props}>
    {props.children}
  </DropoutMenuDropper>
)

export const TopRightDropoutMenuDropper: React.FC<PositionedMenuDroppperProps> = props => (
  <DropoutMenuDropper
    positionY={PositionY.Top}
    positionX={PositionX.Right}
    triangleOffsetRight={horizontalArrowOffset}
    {...props}
  >
    {props.children}
  </DropoutMenuDropper>
)

export const BottomRightDropoutMenuDropper: React.FC<PositionedMenuDroppperProps> = props => (
  <DropoutMenuDropper
    positionY={PositionY.Bottom}
    positionX={PositionX.Right}
    triangleOffsetRight={horizontalArrowOffset}
    {...props}
  >
    {props.children}
  </DropoutMenuDropper>
)

export const BottomCenterDropoutMenuDropper: React.FC<PositionedMenuDroppperProps> = props => (
  <DropoutMenuDropper positionY={PositionY.Bottom} positionX={PositionX.Center} {...props}>
    {props.children}
  </DropoutMenuDropper>
)

export const BottomLeftDropoutMenuDropper: React.FC<PositionedMenuDroppperProps> = props => (
  <DropoutMenuDropper
    positionY={PositionY.Bottom}
    positionX={PositionX.Left}
    triangleOffsetLeft={horizontalArrowOffset}
    {...props}
  >
    {props.children}
  </DropoutMenuDropper>
)

export enum DateTimeRangeFilterPosition {
  TopLeft = 'topLeft',
  TopCenter = 'topCenter',
  TopRight = 'topRight',
  BottomLeft = 'bottomLeft',
  BottomCenter = 'bottomCenter',
  BottomRight = 'bottomRight',
}

export const positionedDropoutMenuOptions: Record<
  DateTimeRangeFilterPosition,
  React.FunctionComponent<PositionedMenuDroppperProps>
> = {
  topLeft: TopLeftDropoutMenuDropper,
  topCenter: TopCenterDropoutMenuDropper,
  topRight: TopRightDropoutMenuDropper,
  bottomLeft: BottomRightDropoutMenuDropper,
  bottomCenter: BottomCenterDropoutMenuDropper,
  bottomRight: BottomLeftDropoutMenuDropper,
}
