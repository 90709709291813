// AIS ShipTypes
// https://github.com/portxchange/ais-api/blob/master/modules/core/src/main/scala/ais/repo/ShipTypeMapping.scala~]

import { values } from '../utils/obj'

export enum ShipType {
  VESSEL_WITH_ANTI_POLLUTION_FACILITIES = 'VESSEL_WITH_ANTI_POLLUTION_FACILITIES',
  CARGO = 'CARGO',
  CARGO_HASCAT_A = 'CARGO_HASCAT_A',
  CARGO_HASCAT_B = 'CARGO_HASCAT_B',
  CARGO_HASCAT_C = 'CARGO_HASCAT_C',
  CARGO_HASCAT_D = 'CARGO_HASCAT_D',
  DIVING_OPS = 'DIVING_OPS',
  DREDGING_UNDERWATER_OPS = 'DREDGING_UNDERWATER_OPS',
  FISHING = 'FISHING',
  HIGH_SPEED = 'HIGH_SPEED',
  HIGHSPEED_HAZCAT_A = 'HIGHSPEED_HAZCAT_A',
  HIGHSPEED_HAZCAT_B = 'HIGHSPEED_HAZCAT_B',
  HIGHSPEED_HAZCAT_C = 'HIGHSPEED_HAZCAT_C',
  HIGHSPEED_HAZCAT_D = 'HIGHSPEED_HAZCAT_D',
  TOWING_BIG = 'TOWING_BIG',
  LAW_ENFORCEMENT_VESSEL = 'LAW_ENFORCEMENT_VESSEL',
  OTHER_TYPE = 'OTHER_TYPE',
  MILITARY_OPS = 'MILITARY_OPS',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  OTHER = 'OTHER',
  OTHER_TYPE_HAZCAT_A = 'OTHER_TYPE_HAZCAT_A',
  OTHER_TYPE_HAZCAT_B = 'OTHER_TYPE_HAZCAT_B',
  OTHER_TYPE_HAZCAT_C = 'OTHER_TYPE_HAZCAT_C',
  OTHER_TYPE_HAZCAT_D = 'OTHER_TYPE_HAZCAT_D',
  PASSENGER = 'PASSENGER',
  PASSENGER_HAZCAT_A = 'PASSENGER_HAZCAT_A',
  PASSENGER_HAZCAT_B = 'PASSENGER_HAZCAT_B',
  PASSENGER_HAZCAT_C = 'PASSENGER_HAZCAT_C',
  PASSENGER_HAZCAT_D = 'PASSENGER_HAZCAT_D',
  PILOT_VESSEL = 'PILOT_VESSEL',
  PLEASURE_CRAFT = 'PLEASURE_CRAFT',
  PORT_TENDER = 'PORT_TENDER',
  SAILING = 'SAILING',
  SEARCH_AND_RESCUE_VESSEL = 'SEARCH_AND_RESCUE_VESSEL',
  NOT_PARTIES_TO_AN_ARMED_CONFLICT = 'NOT_PARTIES_TO_AN_ARMED_CONFLICT',
  SPARE = 'SPARE',
  TANKER = 'TANKER',
  TANKER_HAZCAT_A = 'TANKER_HAZCAT_A',
  TANKER_HAZCAT_B = 'TANKER_HAZCAT_B',
  TANKER_HAZCAT_C = 'TANKER_HAZCAT_C',
  TANKER_HAZCAT_D = 'TANKER_HAZCAT_D',
  TOWING = 'TOWING',
  TUG = 'TUG',
  WING_IN_GROUND = 'WING_IN_GROUND',
  WING_IN_GROUND_HAZCAT_A = 'WING_IN_GROUND_HAZCAT_A',
  WING_IN_GROUND_HAZCAT_B = 'WING_IN_GROUND_HAZCAT_B',
  WING_IN_GROUND_HAZCAT_C = 'WING_IN_GROUND_HAZCAT_C',
  WING_IN_GROUND_HAZCAT_D = 'WING_IN_GROUND_HAZCAT_D',
  // Custom
  UNDEFINED = 'UNDEFINED',
}

export enum ShipTypeCategory {
  UNSPECIFIED_SHIPS,
  NAVIGATION_AIDS,
  FISHING,
  TUG_AND_SPECIAL_CRAFT,
  HIGH_SPEED_CRAFT,
  PASSENGER_VESSELS,
  CARGO_VESSELS,
  TANKERS,
  PLEASURE_CRAFT,
}

export const shipTypeCategories = values(ShipTypeCategory)

export const categorizedShipTypes: Record<ShipTypeCategory, ShipType[]> = {
  [ShipTypeCategory.UNSPECIFIED_SHIPS]: [
    ShipType.UNDEFINED,
    ShipType.OTHER,
    ShipType.NOT_AVAILABLE,
    ShipType.OTHER_TYPE_HAZCAT_A,
    ShipType.OTHER_TYPE_HAZCAT_B,
    ShipType.OTHER_TYPE_HAZCAT_C,
    ShipType.OTHER_TYPE_HAZCAT_D,
  ],
  [ShipTypeCategory.FISHING]: [ShipType.FISHING],
  [ShipTypeCategory.CARGO_VESSELS]: [
    ShipType.CARGO,
    ShipType.CARGO_HASCAT_A,
    ShipType.CARGO_HASCAT_B,
    ShipType.CARGO_HASCAT_C,
    ShipType.CARGO_HASCAT_D,
  ],
  [ShipTypeCategory.TANKERS]: [
    ShipType.TANKER,
    ShipType.TANKER_HAZCAT_A,
    ShipType.TANKER_HAZCAT_B,
    ShipType.TANKER_HAZCAT_C,
    ShipType.TANKER_HAZCAT_D,
  ],
  [ShipTypeCategory.PASSENGER_VESSELS]: [
    ShipType.PASSENGER,
    ShipType.PASSENGER_HAZCAT_A,
    ShipType.PASSENGER_HAZCAT_B,
    ShipType.PASSENGER_HAZCAT_C,
    ShipType.PASSENGER_HAZCAT_D,
  ],
  [ShipTypeCategory.TUG_AND_SPECIAL_CRAFT]: [
    ShipType.TOWING,
    ShipType.TOWING_BIG,
    ShipType.DREDGING_UNDERWATER_OPS,
    ShipType.DIVING_OPS,
    ShipType.MILITARY_OPS,
    ShipType.SAILING,
    ShipType.PLEASURE_CRAFT,
    ShipType.PILOT_VESSEL,
    ShipType.SEARCH_AND_RESCUE_VESSEL,
    ShipType.TUG,
    ShipType.PORT_TENDER,
    ShipType.VESSEL_WITH_ANTI_POLLUTION_FACILITIES,
    ShipType.LAW_ENFORCEMENT_VESSEL,
    ShipType.SPARE,
    ShipType.OTHER_TYPE,
    ShipType.NOT_PARTIES_TO_AN_ARMED_CONFLICT,
    ShipType.WING_IN_GROUND,
    ShipType.WING_IN_GROUND_HAZCAT_A,
    ShipType.WING_IN_GROUND_HAZCAT_B,
    ShipType.WING_IN_GROUND_HAZCAT_C,
    ShipType.WING_IN_GROUND_HAZCAT_D,
  ],
  [ShipTypeCategory.HIGH_SPEED_CRAFT]: [
    ShipType.HIGH_SPEED,
    ShipType.HIGHSPEED_HAZCAT_A,
    ShipType.HIGHSPEED_HAZCAT_B,
    ShipType.HIGHSPEED_HAZCAT_C,
    ShipType.HIGHSPEED_HAZCAT_D,
  ],

  [ShipTypeCategory.PLEASURE_CRAFT]: [],
  [ShipTypeCategory.NAVIGATION_AIDS]: [],
}

export const findShipTypeCategory = (type: ShipType): ShipTypeCategory =>
  shipTypeCategories.find(category => categorizedShipTypes[category]?.includes(type)) ??
  ShipTypeCategory.UNSPECIFIED_SHIPS
