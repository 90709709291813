import { Ref, RefObject, useLayoutEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { StatusPill } from '../../../../Domain/Movement'
import { Pill } from '../../../../ui/Pill/Pill'
import { Tooltip } from '../../../../ui/Tooltip/Tooltip'
import { TestId } from '../../../../utils/testid'

import styles from './VesselStatusCell.module.scss'

type TStatus = StatusPill | string

interface IProps {
  movementId: string
  status: TStatus
  vesselName: string
}

const useMeasureTooltip = (textRef: RefObject<HTMLSpanElement>): boolean => {
  const [needsTooltip, setNeedsTooltip] = useState(false)

  useLayoutEffect(() => {
    if (textRef.current) {
      setNeedsTooltip(textRef.current.clientWidth < textRef.current.scrollWidth)
    }
  }, [textRef])

  return needsTooltip
}

export const VesselStatusCell = ({ vesselName, status, movementId }: IProps) => {
  const isWithStatus = status === StatusPill.PilotOnBoard
  const textRef: Ref<HTMLSpanElement> = useRef(null)
  const measureTooltipNeeded = useMeasureTooltip(textRef)
  const tooltipKey = movementId

  const tooltipProps = {
    'data-tip': true,
    'data-for': tooltipKey,
  }

  return (
    <div className={styles.container}>
      {isWithStatus && <Pill data-testid={TestId.PilotOnBoard}>Pilot on board</Pill>}
      <span
        ref={textRef}
        className={classNames(styles.vesselName, { [styles.isOverflowing]: measureTooltipNeeded })}
        {...(measureTooltipNeeded && tooltipProps)}
      >
        {vesselName}
      </span>
      {measureTooltipNeeded && <Tooltip text={vesselName} tooltipKey={tooltipKey} />}
    </div>
  )
}
