import { Link, useLocation } from 'react-router-dom'

import { DASHBOARD_PAGES, InternalPages, PAGES } from '../../../constants/routing'
import { ExternalLinkIcon } from '../../../ui/icons/ExternalLinkIcon/ExternalLinkIcon'

import styling from './NavigationItem.module.scss'

type NavigationItemProps = Readonly<{
  text: string
  onClick?: () => void
}>

type InternalNavigationItemProps = NavigationItemProps &
  Readonly<{
    page: InternalPages
  }>

type ExternalNavigationItemProps = NavigationItemProps &
  Readonly<{
    url: string
  }>

const SideMenuItemText = (props: { children: React.ReactNode }) => (
  <span className={styling.sideMenuItemText}>{props.children}</span>
)

export const NavigationItem = (props: NavigationItemProps) => (
  <button className={styling.sideMenuItem} onClick={props.onClick}>
    <div className={styling.sideMenuItemWrapper}>
      <SideMenuItemText>{props.text}</SideMenuItemText>
    </div>
  </button>
)

export type CustomLocationState<T> = T | undefined

export const InternalNavigationItem = (props: InternalNavigationItemProps) => {
  const { pathname, state } = useLocation<CustomLocationState<{ from: string | undefined }>>()
  let { from } = state || { from: undefined }
  if (!from) {
    from = DASHBOARD_PAGES.includes(pathname) ? pathname : PAGES.DASHBOARD
  }

  return (
    <Link className={styling.sideMenuItem} to={{ pathname: props.page, state: { from } }} onClick={props.onClick}>
      <div className={styling.sideMenuItemWrapper}>
        <SideMenuItemText>{props.text}</SideMenuItemText>
      </div>
    </Link>
  )
}

export const ExternalNavigationItem = (props: ExternalNavigationItemProps) => (
  <a
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
    className={styling.sideMenuItem}
    onClick={props.onClick}
  >
    <div className={styling.sideMenuItemWrapper}>
      <SideMenuItemText>{props.text}</SideMenuItemText>
      <ExternalLinkIcon />
    </div>
  </a>
)
