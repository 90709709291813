import { useEffect, useState } from 'react'

import { useQuery } from 'react-query'

import { fetchSystemStatus } from '../../Api/SystemStatus/fetchStatus'
import { GOOGLE_SHEETS_ID } from '../../constants/env-vars'
import { MINUTE } from '../../constants/time'
import { InformationModal } from '../Modal/InformationModal'

export const SystemStatus = () => {
  const [isVisible, setIsVisible] = useState(false)

  const { data, isLoading } = useQuery(GOOGLE_SHEETS_ID, () => fetchSystemStatus(GOOGLE_SHEETS_ID), {
    refetchInterval: 5 * MINUTE,
  })

  useEffect(() => {
    if (!isLoading && data && data.length) {
      setIsVisible(true)
    }
  }, [isLoading, data])

  if (!isVisible || data === undefined || data.length === 0) {
    return null
  }

  const { title, message } = data[data.length - 1]

  return <InformationModal onClose={() => setIsVisible(false)} isVisible withCloseButton title={title} body={message} />
}
