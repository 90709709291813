import React from 'react'

import { format } from 'date-fns'
import { useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { fetchVesselDetails } from '../../Api/Vessel/fetchVesselDetails'
import { SystemOfMeasurement } from '../../Domain/Me'
import { VesselDetails } from '../../Domain/VesselDetails'
import { RenderQueryResult } from '../../hoc/RenderQueryResult'
import { ImagePlaceHolder } from '../../ui/ImagePlaceHolder/ImagePlaceHolder'
import { InlineLoader } from '../../ui/InlineLoader/InlineLoader'
import {
  addUnit,
  cbmToBbls,
  cbmToCuft,
  convertToUnit,
  createMeasurementSystemConverter,
  metersToFeet,
} from '../../utils/convert-units'
import { findCountryByCode } from '../../utils/countries'
import { longDateFormat } from '../../utils/datetime'
import { FullScreenOverlay } from '../FullScreenOverlay/FullScreenOverlay'
import { useUserContext } from '../UserProvider/UserProvider'

import styles from './VesselDetailsOverlay.module.scss'

type DataAccessor<Data, Value> = {
  label: string
  accessor: (data: Data) => Value
  formatter: (value: any, measurementSystem: SystemOfMeasurement) => React.ReactNode
}

const createAccessorFactory = <Data extends {}>() => ({
  createDataAccessor: <Value extends unknown>(
    label: string,
    accessor: (data: Data) => Value,
    formatter: (value: any, measurementSystem: SystemOfMeasurement) => React.ReactNode
  ): DataAccessor<Data, Value> => ({
    label,
    accessor,
    formatter,
  }),
})

const { createDataAccessor } = createAccessorFactory<VesselDetails>()

const identity = <A extends unknown>(input: A): A => input

const valueOrAlternative =
  <Value extends unknown>(
    alternative: React.ReactNode,
    formatter: (value: Value, measurementSystem: SystemOfMeasurement) => React.ReactNode
  ) =>
  (input: Value, measurementSystem: SystemOfMeasurement): React.ReactNode =>
    input !== undefined ? formatter(input, measurementSystem) : alternative

const generalInformationAccessors = [
  createDataAccessor('Vessel Type', ({ spireVesselType }) => spireVesselType, valueOrAlternative('N/A', identity)),
  createDataAccessor('Sub Type', ({ spireSubtype }) => spireSubtype, valueOrAlternative('N/A', identity)),
  createDataAccessor('IMO', ({ imo }) => imo, valueOrAlternative('N/A', identity)),
  createDataAccessor('MMSI', ({ mmsi }) => mmsi, valueOrAlternative('N/A', identity)),
  createDataAccessor('Call Sign', ({ callSign }) => callSign, valueOrAlternative('N/A', identity)),
  createDataAccessor('Flag', ({ flag }) => flag && findCountryByCode(flag)?.name, valueOrAlternative('N/A', identity)),
  createDataAccessor(
    'Vessel Name Date',
    ({ vesselNameDate }) => vesselNameDate && format(vesselNameDate, longDateFormat),
    valueOrAlternative('N/A', identity)
  ),
  createDataAccessor('Built Year', ({ builtYear }) => builtYear, valueOrAlternative('N/A', identity)),
  createDataAccessor('Builder', ({ builder }) => builder, valueOrAlternative('N/A', identity)),
  createDataAccessor('Class Society', ({ classSociety }) => classSociety, valueOrAlternative('N/A', identity)),
  createDataAccessor('Ice Class', ({ iceClass }) => iceClass, valueOrAlternative('N/A', identity)),
  createDataAccessor('Owner', ({ commercialOwner }) => commercialOwner, valueOrAlternative('N/A', identity)),
]
const vesselParticularsAccessors = [
  createDataAccessor('Summer DWT', ({ dwt }) => dwt, valueOrAlternative('N/A', addUnit('MT'))),
  createDataAccessor('Gross Tonnage', ({ grossTonnage }) => grossTonnage, valueOrAlternative('N/A', addUnit('MT'))),
  createDataAccessor('Net Tonnage', ({ netTonnage }) => netTonnage, valueOrAlternative('N/A', addUnit('MT'))),
  createDataAccessor(
    'Length Overall',
    ({ lengthOverall }) => lengthOverall,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    )
  ),
  createDataAccessor(
    'Extreme Breadth',
    ({ beam }) => beam,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    )
  ),
  createDataAccessor(
    'Depth',
    ({ depth }) => depth,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    )
  ),
  createDataAccessor(
    'Summer Draft',
    ({ maxDraughtDerived }) => maxDraughtDerived,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    )
  ),
  createDataAccessor(
    'Max Air Draft',
    ({ airDraught }) => airDraught,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit('m'),
        [SystemOfMeasurement.Imperial]: convertToUnit(metersToFeet, 'ft'),
      })
    )
  ),
  createDataAccessor('TPC', ({ tpc }) => tpc, valueOrAlternative('N/A', addUnit('MT'))),
  createDataAccessor('Displacement', ({ displacement }) => displacement, valueOrAlternative('N/A', addUnit('MT'))),
  createDataAccessor(
    'Liquid Capacity 98%',
    ({ liquidCapacity98Pcnt }) => liquidCapacity98Pcnt,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit(
          <>
            m<sup>3</sup>
          </>
        ),
        [SystemOfMeasurement.Imperial]: convertToUnit(cbmToBbls, 'BBLS'),
      })
    )
  ),
  createDataAccessor(
    'Grain Capacity',
    ({ grainCapacity }) => grainCapacity,
    valueOrAlternative(
      'N/A',
      createMeasurementSystemConverter({
        [SystemOfMeasurement.Metric]: addUnit(
          <>
            m<sup>3</sup>
          </>
        ),
        [SystemOfMeasurement.Imperial]: convertToUnit(cbmToCuft, 'cu ft'),
      })
    )
  ),
  createDataAccessor(
    'Container Intake',
    ({ capacityContainers }) => capacityContainers,
    valueOrAlternative('N/A', addUnit('TEU'))
  ),
  createDataAccessor(
    'Main Engine Designer',
    ({ mainEngineDesigner }) => mainEngineDesigner,
    valueOrAlternative('N/A', identity)
  ),
  createDataAccessor('Main Engine Model', ({ mainEngine }) => mainEngine, valueOrAlternative('N/A', identity)),
  createDataAccessor(
    'Main Engine Capacity',
    ({ engineCapacity }) => engineCapacity,
    valueOrAlternative('N/A', addUnit('kW'))
  ),
]

export const VesselDetailsOverlay: React.FC = () => {
  const { search } = useLocation()
  const routeParams = useParams<{ imo: string }>()
  const { user } = useUserContext()
  const history = useHistory()
  const vesselDetailsQuery = useQuery(`vessel-details-${routeParams.imo}`, () =>
    fetchVesselDetails(`imo${routeParams.imo}`)
  )

  const queryParams = new URLSearchParams(search)
  const prevPath = queryParams.get('returnTo') ?? '/'

  return (
    <FullScreenOverlay title="Vessel Details" onClose={() => history.push(prevPath)}>
      <div className={styles.fullVesselDetails}>
        <RenderQueryResult
          query={vesselDetailsQuery}
          LoadingComponent={<InlineLoader />}
          ErrorComponent={<div>Something went wrong, please try again later.</div>}>
          {vesselDetails => (
            <>
              <div className={styles.imageContainer}>
                {vesselDetails.photoUrl ? (
                  <img src={vesselDetails.photoUrl} alt={vesselDetails.name} className={styles.image} />
                ) : (
                  <ImagePlaceHolder />
                )}
              </div>
              <div className={styles.detailsListContainer}>
                <h3 className={styles.shipName}>{vesselDetails.name}</h3>
                <h4 className={styles.groupHeading}>General information</h4>
                <dl className={styles.detailsList}>
                  {generalInformationAccessors.map((row, arr, index) => (
                    <div className={styles.group} key={`${row.label}-${index}`}>
                      <dt className={styles.defLabel}>{row.label}</dt>
                      <dd className={styles.defValue}>
                        {row.formatter(row.accessor(vesselDetails), user.measurementUnit)}
                      </dd>
                    </div>
                  ))}
                </dl>
                <h4 className={styles.groupHeading}>Vessel Particulars</h4>
                <dl className={styles.detailsList}>
                  {vesselParticularsAccessors.map((row, arr, index) => (
                    <div className={styles.group} key={`${row.label}-${index}`}>
                      <dt className={styles.defLabel}>{row.label}</dt>
                      <dd className={styles.defValue}>
                        {row.formatter(row.accessor(vesselDetails), user.measurementUnit)}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </>
          )}
        </RenderQueryResult>
      </div>
    </FullScreenOverlay>
  )
}
