import { SortingRule } from 'react-table'

import { Movement } from '../Domain/Movement'
import { TableRowSize } from '../state/tableRowSize'

export const DEFAULT_SORTING: SortingRule<Movement>[] = [
  {
    id: 'scheduledTime',
    desc: true,
  },
]

export const HEADER_HEIGHT = 48

export const ROW_HEIGHT_LARGE = 64
export const ROW_HEIGHT_MEDIUM = 54
export const ROW_HEIGHT_SMALL = 44

export const rowSizes: Record<TableRowSize, number> = {
  [TableRowSize.Large]: ROW_HEIGHT_LARGE,
  [TableRowSize.Medium]: ROW_HEIGHT_MEDIUM,
  [TableRowSize.Small]: ROW_HEIGHT_SMALL,
}
