import React from 'react'

import {
  InboundVesselIcon,
  OutboundVesselIcon,
  PilotOnBoardVesselIcon,
  ShiftingVesselIcon,
} from '../../../ui/VesselIcon/VesselIcon'

import styles from './LegendBox.module.scss'

export const LegendBox = () => (
  <div className={styles.legend}>
    <div className={styles.item}>
      <span className={styles.icon}>
        <PilotOnBoardVesselIcon />
      </span>
      <span className={styles.text}>Pilot on board</span>
    </div>
    <div className={styles.item}>
      <span className={styles.icon}>
        <InboundVesselIcon />
      </span>
      <span className={styles.text}>Inbound</span>
    </div>
    <div className={styles.item}>
      <span className={styles.icon}>
        <ShiftingVesselIcon />
      </span>
      <span className={styles.text}>Shifting</span>
    </div>
    <div className={styles.item}>
      <span className={styles.icon}>
        <OutboundVesselIcon />
      </span>
      <span className={styles.text}>Outbound</span>
    </div>
  </div>
)
