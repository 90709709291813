import React from 'react'

import classnames from 'classnames'

import { InlineLoader } from '../../ui/InlineLoader/InlineLoader'
import { TestId } from '../../utils/testid'

import { Modal } from './Modal'
import styles from './Modal.module.scss'

type ConfirmationModalProps = {
  isVisible: boolean
  title: string
  onCancel: () => void
  onConfirm: () => void
  confirmText: string
  isProcessing: boolean
  isError: boolean
  cancelText?: string | boolean
  text?: string | boolean
  testId?: TestId
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onCancel,
  onConfirm,
  title,
  cancelText,
  confirmText,
  isVisible,
  text,
  isProcessing,
  isError,
  testId,
}) => {
  return (
    <Modal isVisible={isVisible} onClose={onCancel} testId={testId}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
        {isError && <p className={classnames(styles.text, styles.error)}>Oops, something went wrong!</p>}
      </div>
      <footer className={styles.footer}>
        {cancelText && (
          <button
            disabled={isProcessing}
            onClick={() => {
              onCancel()
            }}
            className={classnames(styles.button, styles.cancelButton)}
            data-testid={TestId.ConfirmationModalCancelButton}
          >
            {cancelText}
          </button>
        )}
        <button
          disabled={isProcessing}
          onClick={() => {
            onConfirm()
          }}
          className={classnames(styles.button, styles.confirmButton)}
          data-testid={TestId.ConfirmationModalConfirmButton}
        >
          {isProcessing ? (
            <span className={styles.loadingStatus}>
              <InlineLoader />
            </span>
          ) : (
            confirmText
          )}
        </button>
      </footer>
    </Modal>
  )
}
