const getEnvironmentVariable = (name: string): string => {
  const value = process.env[name]

  if (value === undefined) {
    throw new Error(`Key ${name} is not defined in environment`)
  } else if (value === '') {
    throw new Error(`Key ${name} is defined in environment as an empty string`)
  }
  return value
}

export const DEPLOYMENT_ENVIRONMENT = getEnvironmentVariable('REACT_APP_DEPLOYMENT_ENVIRONMENT')
export const HOUSTON_PILOTS_URL = getEnvironmentVariable('REACT_APP_HOUSTON_PILOTS_URL')
export const HOUSTON_PILOTS_BACKEND_URL = getEnvironmentVariable('REACT_APP_HOUSTON_PILOTS_BACKEND_URL')
export const PRONTO_MAPBOX_TOKEN = getEnvironmentVariable('REACT_APP_HOUSTON_PILOTS_MAPBOX_TOKEN')
export const HOUSTON_PILOTS_MAPBOX_DARK_STYLE_ID = getEnvironmentVariable(
  'REACT_APP_HOUSTON_PILOTS_MAPBOX_DARK_STYLE_ID'
)
export const HOUSTON_PILOTS_MAPBOX_LIGHT_STYLE_ID = getEnvironmentVariable(
  'REACT_APP_HOUSTON_PILOTS_MAPBOX_LIGHT_STYLE_ID'
)
export const PRONTO_SATELLITE_MAPBOX_STYLE_ID = getEnvironmentVariable(
  'REACT_APP_HOUSTON_PILOTS_SATELLITE_MAPBOX_STYLE_ID'
)
export const AIS_BACKEND_URL = getEnvironmentVariable('REACT_APP_AIS_BACKEND_URL')
export const LOG_FRONTEND_ERRORS = getEnvironmentVariable('REACT_APP_LOG_FRONTEND_ERRORS')
export const AUTH0_CLIENT_ID = getEnvironmentVariable('REACT_APP_AUTH0_CLIENT_ID')
export const AUTH0_DOMAIN = getEnvironmentVariable('REACT_APP_AUTH0_DOMAIN')
export const MIXPANEL_TOKEN = getEnvironmentVariable('REACT_APP_MIXPANEL_TOKEN')
export const MIXPANEL_API_HOST = getEnvironmentVariable('REACT_APP_MIXPANEL_API_HOST')
export const SENTRY_DSN = getEnvironmentVariable('REACT_APP_SENTRY_DSN')
export const GOOGLE_SHEETS_ID = getEnvironmentVariable('REACT_APP_GOOGLE_SHEETS_ID')
export const SYNCHRONIZER_URL = getEnvironmentVariable('REACT_APP_SYNCHRONIZER_URL')
export const PXP_ADMIN_URL = getEnvironmentVariable('REACT_APP_PXP_ADMIN_URL')
export const SHIP_TRACKER_URL = getEnvironmentVariable('REACT_APP_SHIP_TRACKER_URL')

export const isTestEnv = DEPLOYMENT_ENVIRONMENT === 'test'
export const isProductionEnv = DEPLOYMENT_ENVIRONMENT === 'production'
