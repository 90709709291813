export const ClearIcon = ({ className }: { className: string }) => {
  return (
    <svg viewBox="0 0 12 12" className={className}>
      <defs>
        <path
          id="clear-1"
          d="M6.26 2.504l.444.443-1.658 1.656 1.658 1.658-.443.443-1.658-1.658-1.656 1.658-.443-.443 1.657-1.658-1.657-1.656.443-.443 1.656 1.657 1.658-1.657z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-558 -67)">
          <g transform="translate(0 50)">
            <g transform="translate(558 17)">
              <circle cx="6" cy="6" r="6" fill="#0693CA" />
              <g transform="translate(1.565 1.565)">
                <mask id="clear-2" fill="#fff">
                  <use xlinkHref="#clear-1" />
                </mask>
                <g mask="url(#clear-2)">
                  <g transform="translate(-1.565 -1.565)">
                    <path fill="#FFF" d="M0 0H12.522V12.522H0z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
