import classnames from 'classnames'

import { TestId } from '../../utils/testid'
import { CheckMarkIcon } from '../icons/CheckMarkIcon/CheckMarkIcon'

import styles from './Checkbox.module.scss'

interface IProps {
  label: string
  isChecked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
  className?: string
}

export const Checkbox = ({ isChecked, onChange, label, disabled, className }: IProps) => (
  <label
    className={classnames(styles.checkboxLabel, className, { [styles.checkboxLabelDisabled]: disabled })}
    data-testid={TestId.CheckBoxLabel}
  >
    <input
      type="checkbox"
      checked={isChecked}
      onChange={() => !disabled && onChange?.(!isChecked)}
      data-testid={TestId.CheckBoxInput}
    />
    <span
      className={classnames(styles.checkboxButton, {
        [styles.checkboxChecked]: isChecked,
        [styles.checkboxButtonDisabled]: disabled,
        [styles.checkboxCheckedDisabled]: isChecked && disabled,
      })}
    >
      {isChecked && <CheckMarkIcon testId={TestId.CheckBoxCheckMark} />}
    </span>
    {label}
  </label>
)
