import { useCallback } from 'react'

import { useLoginState, extractRawIdToken } from '../../hooks/useLoginState'
import { getAuthenticatedApi } from '../../utils/api'
import { trackError } from '../../utils/trackError'

export const postStartSession = async (options: { idToken: string }) => {
  const api = await getAuthenticatedApi()
  const result = await api.post('track', { headers: { 'X-Id-Token': options.idToken } })

  return result.ok
}

export const usePostStartSession = () => {
  const { getIdTokenClaims } = useLoginState()

  return useCallback(async () => {
    try {
      const idToken = await getIdTokenClaims()
      return await postStartSession({ idToken: extractRawIdToken(idToken) })
    } catch (err) {
      if (err instanceof Error) {
        trackError(err)
      }
      return false
    }
  }, [getIdTokenClaims])
}
