import { PRODUCT_NAME } from '../../constants/constants'

export const faqData: Record<string, { question: string; answer: string }[]> = {
  'The System': [
    {
      question:
        'As a Pronto/PXP/Synchronizer user, how do I move from this system to my terminal/agent planning/dashboard view?',
      answer: 'There will be a link to Synchronizer on the Hamburger menu.',
    },
    {
      question: 'Do I need to do anything to access the system beyond the URL, my email address and a password?',
      answer:
        'If you already have an account with PXP Synchronizer and your company has been given access, the same credentials are used for PT.',
    },
    {
      question: 'Who do I contact when I lose my password?',
      answer: `Your password can be reset by using the "Don’t remember password" option in the ${PRODUCT_NAME} Login page.`,
    },
    {
      question: 'Can I have multiple logged in sessions with various devices using the same credentials?',
      answer: 'No, users cannot login concurrently with the same credentials.',
    },
  ],
  'The Interface': [
    {
      question: 'What are the various views/screens that I can look at?  How are they configured?',
      answer:
        'You have a Dashboard View and Map View that can be switched using the toggle at the top right corner of the page. The Dashboard view includes a detailed list of movements 5 days in the past and 5 days in the future, with information such as the movement type, scheduled and actual times, destinations, vessel particulars, pilot status, towage requirements, booking agent information and an email notification feature. The Map View includes a condensed list with movement type, scheduled times, vessel name, pilot status, destination and an email notification feature, as well as a live map with the vessel positions.',
    },
    {
      question: 'How is the table ordered? Is it ordered the same way when I switch views?',
      answer:
        'The default view of the tables in both the Dashboard and Map views are ordered chronologically by the scheduled time column.',
    },
  ],
  'The Features': [
    {
      question: "Which fields are orderable vs. filterable?  What's the difference?",
      answer:
        'The ET/AT fields, vessel particulars, and tug requirement column are orderable, allowing you to sort all available records to your preference.The Movement, Vessel, From, To, Type, and Agency fields are filterable. This allows you to type your desired result at the top of the column and only see those planned movements that apply to you.  It is possible to filter more than one field at a time, narrowing down your results even farther.',
    },
    {
      question: 'When I filter data, does that filter stay when I switch views?',
      answer:
        'When you switch views the data filters are refreshed, meaning that you will have to filter the data again.',
    },
    {
      question: 'Which do the arrows in the columns represent?',
      answer:
        'The arrows in the columns mean that those columns can be sorted. Two arrows in front of a column means all available data is displayed. If you click once it switches to an arrow pointing down, which means that the data is sorted from the most recent to the oldest data. While, if you click again it will show an arrow pointing up which sorts the data from the oldest to the most recent.',
    },
    {
      question: 'Can I adjust/move around/remove columns or rows?',
      answer:
        'You are unable to rename or move columns or rows. However, you are able to filter your selection of columns using the filter icon on the top right corner of the page in the Dashboard view only. All columns are filterable except for the ‘Vessel’ and ‘To’ columns which are automatically selected by default. Your selection of columns are saved in your cookies i.e. columns will appear as filtered per your last session when you re-login to the system.',
    },
    {
      question: 'Can I hide the table with movement information in the map view?',
      answer: 'Yes, you can show/hide the table using the arrow handle on the left of the table.',
    },
  ],
  'The Notifications': [
    {
      question: 'What events does the system send an email for? ',
      answer:
        'The system sends an email notification once the Actual Time Pilot on Board has been updated for the movement.',
    },
    {
      question:
        'What email address does the system send a message to? Can I change the recipient if I want it to go to someone else?',
      answer: 'The system sends messages to the email address linked to your account, ONLY.',
    },
    {
      question: 'How do I cancel an email-request?',
      answer: "The user currently can't unsubscribe to a specific movement selected.",
    },
  ],
  'The Support': [
    {
      question: "My ship isn't appearing on the dashboard/map, but I’ve submitted my movement order.",
      answer: `${PRODUCT_NAME} uses data provided by the Houston Pilots' Association via an API connection. If you believe there is an error in the data, please contact the agent for that vessel listed in ${PRODUCT_NAME}, or if you are the agent, please contact HPA Dispatch.`,
    },
    {
      question: 'The data in the table for my vessel is wrong. Who do I contact?',
      answer: `${PRODUCT_NAME} uses data provided by the Houston Pilots' Association via an API connection. If you believe there is an error in the data, please contact the agent for that vessel listed in ${PRODUCT_NAME}, or if you are the agent, please contact HPA Dispatch.`,
    },
    {
      question: 'How often do you refresh your connection with the Pilots?',
      answer: 'The connection is refreshed every minute.',
    },
    {
      question: 'Which browsers are supported?',
      answer: 'Edge, Firefox and Chrome',
    },
  ],
}
