import { useCallback } from 'react'

import { Button } from '@portxchange/button'
import { useHistory } from 'react-router-dom'

import { Page } from '../../ui/Page/Page'
import { Header } from '../Header/Header'
import { InformationModal } from '../Modal/InformationModal'

export const PortNotFound = () => {
  const history = useHistory()
  const onClose = useCallback(() => history.push('/'), [history])

  return (
    <Page>
      <Header />
      <InformationModal
        isVisible
        withCloseButton
        onClose={onClose}
        title="Port not found"
        body={
          <>
            <p>
              We're sorry, you don't seem to have access to the port in the URL. Click the button below to return to the
              dashboard of your default port.
            </p>
            <Button onClick={onClose} text="Return to dashboard" type="button" />
          </>
        }
      />
    </Page>
  )
}
