import React, { useContext, useState, useEffect, useRef } from 'react'

import { fromEvent } from 'rxjs'

import { breakpoints } from '../styles/breakpoints'

const isMobileMediaQuery = window.matchMedia(`(max-width: ${breakpoints.md})`)
const isTabletMediaQuery = window.matchMedia(`(min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg})`)

type ResponsivenessState = {
  isMobile: boolean
  isTablet: boolean
}

const initialState: ResponsivenessState = {
  isMobile: isMobileMediaQuery.matches,
  isTablet: isTabletMediaQuery.matches,
}

const ResponsivenessContext = React.createContext(initialState)

export const useResponsiveness = () => useContext(ResponsivenessContext)

export const ResponsivenessProvider: React.FC = ({ children }) => {
  const [currentBreakpoints, setCurrentBreakpoints] = useState(initialState)
  const { current: mobileMediaMatcher } = useRef(isMobileMediaQuery)
  const { current: tabletMediaMatcher } = useRef(isTabletMediaQuery)

  useEffect(() => {
    const subscriptions = [
      fromEvent<MediaQueryListEvent>(mobileMediaMatcher, 'change').subscribe(({ matches: isMobile }) =>
        setCurrentBreakpoints(state => ({ ...state, isMobile }))
      ),
      fromEvent<MediaQueryListEvent>(tabletMediaMatcher, 'change').subscribe(({ matches: isTablet }) =>
        setCurrentBreakpoints(state => ({ ...state, isTablet }))
      ),
    ]

    return () => subscriptions.forEach(sub => sub.unsubscribe())
  }, [mobileMediaMatcher, tabletMediaMatcher, setCurrentBreakpoints])

  return <ResponsivenessContext.Provider value={currentBreakpoints}>{children}</ResponsivenessContext.Provider>
}
