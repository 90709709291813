import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'

import { mainTableColumns } from '../../pages/Dashboard/columns'
import { useUserContext } from '../UserProvider/UserProvider'

const EXCLUDED_COLUMNS = ['subscribed'] // Excluded columns will be pushed to the right with the order taken from mainTableColumns
const DEFAULT_HIDDEN = ['orderedTime']

export type ColumnSetting = { id: string; label: string; isVisible: boolean }

type State = {
  settings: ColumnSetting[]
  defaultSettings: ColumnSetting[]
  setSettings: (settings: ColumnSetting[]) => void
}

const Context = createContext<State>({} as State)

export const useColumnSettingsContext = () => useContext(Context)

export function ColumnSettingsProvider({ children }: PropsWithChildren<{}>) {
  const { user } = useUserContext()

  const defaultSettings = useMemo(() => {
    const hidden =
      user.defaultPort === 'USCRP'
        ? [...DEFAULT_HIDDEN, 'offTime', 'movementToDockHeading', 'tugMat']
        : [...DEFAULT_HIDDEN, 'pilotNo', 'lineHandler', 'tugCompany']

    return mainTableColumns
      .filter(({ id }) => !EXCLUDED_COLUMNS.includes(id!))
      .map(({ id, Header }) => ({
        id: id!,
        label: Header as string,
        isVisible: !hidden.includes(id!),
      }))
  }, [user])

  const initialSettings = useMemo(() => {
    const stored = localStorage.getItem('columnSettings')
    const persistedSettings: ColumnSetting[] | null = stored ? JSON.parse(stored) : null

    return persistedSettings
      ? persistedSettings.concat(defaultSettings.filter(({ id }) => !persistedSettings.some(c => c.id === id)))
      : defaultSettings
  }, [defaultSettings])

  const [settings, setSettings] = useState(initialSettings)

  const defaultSettingsJson = useMemo(() => JSON.stringify(defaultSettings), [defaultSettings])

  useEffect(() => {
    const settingsJson = JSON.stringify(settings)

    if (settingsJson === defaultSettingsJson) {
      localStorage.removeItem('columnSettings')
    } else {
      localStorage.setItem('columnSettings', settingsJson)
    }
  }, [settings, defaultSettingsJson])

  return <Context.Provider value={{ settings, defaultSettings, setSettings }}>{children}</Context.Provider>
}
