import classNames from 'classnames'

import { PILOT_TRACKER_THEME_CSS, PILOT_TRACKER_THEME_DARK_CSS } from '../../constants/constants'
import { useDarkMode } from '../../hooks/useDarkMode'

import styles from './Page.module.scss'

export const Page: React.FC = ({ children }) => {
  const { isDarkMode } = useDarkMode()

  return (
    <main className={classNames(styles.page, PILOT_TRACKER_THEME_CSS, { [PILOT_TRACKER_THEME_DARK_CSS]: isDarkMode })}>
      {children}
    </main>
  )
}
