import classnames from 'classnames'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { PAGES } from '../../../constants/routing'
import { useDarkMode } from '../../../hooks/useDarkMode'
import { useResponsiveness } from '../../../hooks/useResponsiveness'
import { FilterDefinition } from '../../../state/filters'
import { HamburgerIcon } from '../../../ui/icons/HamburgerIcon/HamburgerIcon'
import { Logo } from '../../../ui/Logo/Logo'
import { ToggleWithOuterLabel, TOGGLE_THEME_WITH_OUTER_LABEL } from '../../../ui/Toggle/Toggle'
import { TestId } from '../../../utils/testid'

import styles from './PrimaryMenu.module.scss'

const useMapListToggler = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  const isToggled = pathname.includes(PAGES.DASHBOARD_MAP)
  const handleToggle = () => history.push(`/${isToggled ? PAGES.DASHBOARD : PAGES.DASHBOARD_MAP}`)

  return { isToggled, handleToggle }
}

interface PrimaryMenuProps {
  isDrawerOpen: boolean
  setDrawerOpen: (isOpen: boolean) => void
  filterDefinitions?: FilterDefinition[]
  hideControls?: boolean
  children?: ({ isToggled }: { isToggled: boolean }) => JSX.Element
}

const LogoLightMode = () => <Logo textFill="#002255" />
const LogoDarkMode = () => <Logo textFill="#FFF" />

export const PrimaryMenu = ({ isDrawerOpen, setDrawerOpen, hideControls, children }: PrimaryMenuProps) => {
  const { isToggled, handleToggle } = useMapListToggler()
  const { isMobile } = useResponsiveness()
  const { isDarkMode } = useDarkMode()

  return (
    <>
      <header className={styles.primaryMenu}>
        <div className={styles.left}>
          <button
            aria-label="open/close menu"
            type="button"
            className={styles.menuItem}
            onClick={() => setDrawerOpen(!isDrawerOpen)}>
            <HamburgerIcon isOpen={isDrawerOpen} />
          </button>
          <Link className={styles.logoItem} to={PAGES.ROOT}>
            {isDarkMode ? <LogoDarkMode /> : <LogoLightMode />}
          </Link>
        </div>
        <div className={styles.right}>
          {!hideControls && (
            <>
              {!isMobile && (
                <ToggleWithOuterLabel
                  theme={{
                    ...TOGGLE_THEME_WITH_OUTER_LABEL,
                    toggleContainer: classnames(styles.menuItem, styles.toggleItem),
                  }}
                  isChecked={isToggled}
                  outerLabel="MAP / LIST VIEW"
                  handleToggle={handleToggle}
                  testId={TestId.DashboardViewToggle}
                />
              )}
              {children?.({ isToggled })}
            </>
          )}
        </div>
      </header>
    </>
  )
}
