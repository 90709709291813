import { useState } from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import classNames from 'classnames'

import { useColumnSettingsContext } from '../../components/TableSettingsProvider/TableSettingsProvider'
import { useDarkMode } from '../../hooks/useDarkMode'
import { white, darkblue } from '../../styles/variables'
import { DropoutMenu } from '../DropoutMenu/DropoutMenu'
import { SettingsIcon } from '../icons/SettingsIcon/SettingsIcon'

import { ColumnOrderVisibilitySetting } from './ColumnSetting'
import { StaticSection } from './Section'
import styles from './SettingsDropdown.module.scss'

const TableSettingsContent = ({ onClose }: { onClose: () => void }) => {
  const { track, setUserProperties, register } = useMixpanel()

  const { settings: columns, defaultSettings, setSettings } = useColumnSettingsContext()

  const [newColumns, setNewColumns] = useState(columns)
  const [hasChanged, setHasChanged] = useState(false)

  const handleReset = () => {
    setNewColumns(defaultSettings)
    setHasChanged(true)
  }

  const handleApply = () => {
    setSettings(newColumns)
    const customColumns = Object.values(newColumns)
      .filter(c => c.isVisible)
      .map(c => c.label)

    setUserProperties('customColumns', customColumns)
    register({ customColumns })
    track('Customize column settings', {
      customColumns,
    })

    onClose()
    setHasChanged(false)
  }

  return (
    <div className={styles.dropdownContainer}>
      <StaticSection title="Select columns / order" className={styles.columnSection}>
        <ColumnOrderVisibilitySetting
          columns={newColumns}
          setNewColumns={setNewColumns}
          onHasChanged={() => setHasChanged(true)}
        />
      </StaticSection>
      <button className={styles.resetButton} onClick={handleReset}>
        Reset filters
      </button>
      <div className={styles.applyButtonContainer}>
        <button
          onClick={handleApply}
          className={classNames(styles.applyButton, {
            [styles.disabled]: !hasChanged,
          })}
          disabled={!hasChanged}>
          Apply
        </button>
      </div>
    </div>
  )
}

const SettingsIconDark = () => <SettingsIcon fill={darkblue} />
const SettingsIconLight = () => <SettingsIcon fill={white} />

export const TableSettingsDropdown = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { isDarkMode } = useDarkMode()

  return (
    <div className={styles.buttonContainer}>
      <DropoutMenu>
        {({ closeMenu, isOpen, openMenu }) => (
          <>
            <button
              className={classNames(styles.dropdownButton, { [styles.clicked]: isOpen, [styles.disabled]: isDisabled })}
              onClick={() => (isOpen ? closeMenu() : openMenu())}>
              {isDarkMode ? <SettingsIconLight /> : <SettingsIconDark />}
            </button>
            {isOpen && <TableSettingsContent onClose={closeMenu} />}
          </>
        )}
      </DropoutMenu>
    </div>
  )
}
