import React from 'react'

import { FilterDefinition, FilterOption } from '../../state/filters'
import { Checkbox } from '../Checkbox/Checkbox'

import styles from './SettingsDropdown.module.scss'

type ItemProps = {
  item: FilterOption
  isChecked: boolean
  onToggle: (isChecked: boolean) => void
}
const Item = ({ item: { label, count }, isChecked, onToggle }: ItemProps) => (
  <li className={styles.listItem}>
    <Checkbox isChecked={isChecked} label={`${label} (${count})`} onChange={() => onToggle(!isChecked)} />
  </li>
)

type CheckboxListSettingProps = {
  definition: FilterDefinition
}
export const CheckboxListSetting = ({
  definition: { onChange, selectedOptions, options },
}: CheckboxListSettingProps) => {
  const handleToggle = (type: string, isChecked: boolean) => {
    onChange(isChecked ? [...selectedOptions, type] : selectedOptions.filter(value => value !== type))
  }
  return (
    <ul className={styles.list}>
      {Object.values(options).map(item => (
        <Item
          key={item.value}
          item={item}
          isChecked={selectedOptions.includes(item.value)}
          onToggle={isChecked => handleToggle(item.value, isChecked)}
        />
      ))}
    </ul>
  )
}
