import { usePortal } from '../../hooks/usePortal'
import { TestId } from '../../utils/testid'

import styles from './Modal.module.scss'

export type ModalProps = {
  isVisible: boolean
  onClose: () => void
  testId?: TestId
}

export const Modal: React.FC<ModalProps> = ({ children, isVisible, onClose, testId }) => {
  const { Portal } = usePortal()

  return isVisible ? (
    <Portal>
      <div className={styles.modalParent} data-testid={testId}>
        <div className={styles.backdrop} onClick={onClose} />
        <div className={styles.modal} role="dialog" aria-hidden={isVisible ? 'false' : 'true'}>
          {children}
        </div>
      </div>
    </Portal>
  ) : null
}
