import { PropsWithChildren } from 'react'

import classNames from 'classnames'
import { UseTableColumnProps, UseFiltersColumnProps, Row } from 'react-table'

import { DropoutMenu } from '../../../ui/DropoutMenu/DropoutMenu'
import { ArrowDownIcon } from '../../../ui/icons/ArrowIcons/ArrowDownIcon'
import styles from '../Table.module.scss'

type DropdownColumnFilterProps<Data extends {}> = Readonly<{
  column: UseTableColumnProps<Data> & UseFiltersColumnProps<Data>
  getOptions: (rows: Row<Data>[]) => string[]
  label: string
}>
export const DropdownColumnFilter = <Data extends {}>({
  column: { filterValue, setFilter, preFilteredRows },
  getOptions,
  label,
  children,
}: PropsWithChildren<DropdownColumnFilterProps<Data>>) => {
  const options = getOptions(preFilteredRows)

  return (
    <div className={styles.filterDropoutContainer}>
      {children}
      <DropoutMenu>
        {({ isOpen, openMenu, closeMenu }) => (
          <>
            <button
              type="button"
              onClick={() => (isOpen ? closeMenu() : openMenu())}
              className={styles.filterDropoutButton}
            >
              {filterValue === undefined ? label : filterValue} <ArrowDownIcon />
            </button>
            {isOpen && (
              <ul className={styles.filterDropoutMenu}>
                <li>
                  <button
                    className={classNames(styles.filterDropoutMenuButton, {
                      [styles.active]: filterValue === undefined,
                    })}
                    onClick={() => {
                      setFilter(undefined)
                      closeMenu()
                    }}
                  >
                    All
                  </button>
                </li>
                {options.map(option => (
                  <li key={option}>
                    <button
                      className={classNames(styles.filterDropoutMenuButton, {
                        [styles.active]: filterValue === option,
                      })}
                      onClick={() => {
                        setFilter(option)
                        closeMenu()
                      }}
                    >
                      {option}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </DropoutMenu>
    </div>
  )
}
