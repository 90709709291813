import countries from 'world_countries_lists/data/countries/en/world.json'

type Country = typeof countries[number]

export const findCountryByCode = (code: Country['alpha2']): Country | undefined => {
  const foundCountry = countries.find(country => country.alpha2 === code.toLocaleLowerCase())

  // Map country name to the shorter version
  if (foundCountry && foundCountry.name === 'United Kingdom of Great Britain and Northern Ireland') {
    return { ...foundCountry, name: 'United Kingdom' }
  }

  return foundCountry
}
