import { Direction, Tooltip } from '../../../../ui/Tooltip/Tooltip'

import styles from './TooltipCell.module.scss'

interface IProps {
  cellText: string
  tooltipValues: (string | undefined)[]
  tooltipKey: string
  tooltipDirection?: Direction
  onShow?: () => void
}

export const TooltipCell = ({ cellText, tooltipValues, tooltipKey, onShow, tooltipDirection }: IProps) => {
  return (
    <>
      <div className={styles.cellText} data-iscapture="true" data-tip data-for={tooltipKey}>
        {cellText}
      </div>
      <Tooltip
        place={tooltipDirection}
        tooltipKey={tooltipKey}
        text={tooltipValues.filter(v => !!v).join(' | ')}
        onShow={onShow}
      />
    </>
  )
}
