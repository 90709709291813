import { useRef, useEffect, useState, useMemo } from 'react'

import classNames from 'classnames'
import { useAtom } from 'jotai'
import {
  Column,
  useTable,
  useFlexLayout,
  useSortBy,
  useFilters,
  SortingRule,
  SortByFn,
  FilterTypes,
  HeaderGroup,
  Cell,
  useColumnOrder,
} from 'react-table'
import useMeasure from 'react-use-measure'

import { NO_DATA_AVAILABLE } from '../../constants/constants'
import { HEADER_HEIGHT, rowSizes } from '../../constants/table'
import { OrderType } from '../../Domain/Movement'
import { TableType } from '../../Domain/Tracking'
import { FIRST_COLUMN_WIDTH } from '../../pages/Dashboard/columns'
import { tableRowSizeAtom } from '../../state/tableRowSize'
import { tableScrollIntoViewport } from '../../utils/table'
import { TestId } from '../../utils/testid'
import { InformationModal } from '../Modal/InformationModal'
import { useColumnSettingsContext } from '../TableSettingsProvider/TableSettingsProvider'

import { BackToTopButton } from './BackToTopButton/BackToTopButton'
import { DefaultColumnFilter } from './Filters/DefaultColumnFilter'
import styles from './Table.module.scss'
import { useTrackFilterTable, useTrackSortTable } from './trackTableHooks'
import { VirtualListWithHeaders } from './VirtualListWithHeaders'

type TableProps<Data extends {}> = Readonly<{
  columns: Column<Data>[]
  data: Data[]
  defaultSorting?: SortingRule<Data>[]
  itemToString: (item: Data) => string
  itemToDisabled?: (item: Data) => boolean
  itemToRowLabel: (item: Data) => OrderType | undefined
  highlightedItem?: Data
  onSelectRow?: (item: Data) => void
  sortTypes?: Record<string, SortByFn<Data>>
  filterTypes?: Record<string, FilterTypes<Data>>
  tableType: TableType
}>

const useRecalculateTableHeight = () => {
  const [bodyHeight, setBodyHeight] = useState(0)
  const [ref, bounds] = useMeasure()

  useEffect(() => {
    if (bounds.height) {
      setBodyHeight(bounds.height)
    }
  }, [bodyHeight, bounds.height])

  return { ref, bodyHeight }
}

// The typing for this table has been customized in ./@types/react-table/index.d.ts
// to let TS know about added plugin/hook features.
export const Table = <Data extends {}>({
  columns,
  data,
  defaultSorting,
  sortTypes,
  filterTypes,
  itemToString,
  highlightedItem,
  itemToDisabled,
  itemToRowLabel,
  onSelectRow,
  tableType,
}: TableProps<Data>) => {
  const { settings: columnSettings } = useColumnSettingsContext()
  const [isDisabledModalVisible, setIsDisabledModalVisible] = useState(false)
  const listRef = useRef<HTMLDivElement>(null)
  const { ref, bodyHeight } = useRecalculateTableHeight()
  const defaultColumn: Partial<Column<Data>> = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      sortDescFirst: true,
      Cell: (cell: Cell<Data>) => (cell.value !== undefined && cell.value !== '' ? cell.value : NO_DATA_AVAILABLE),
      minWidth: 0,
    }),
    []
  )
  const isDashboardView = tableType === TableType.DASHBOARD
  const isSecondColumn = (i: number) => i === 1
  const isSticky = (i: number) => i < 2

  const getClassName = (i: number) => {
    if (!isDashboardView) {
      return styles.cell
    }

    return `${styles.cell} ${isSticky(i) ? styles.sticky : ''} ${isSecondColumn(i) ? styles.divider : ''}`
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, filters },
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      defaultColumn,
      columns,
      data,
      sortTypes,
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState: {
        hiddenColumns: columnSettings.filter(({ isVisible }) => !isVisible).map(({ id }) => id),
        sortBy: defaultSorting,
      },
    },
    useFlexLayout,
    useFilters,
    useSortBy,
    useColumnOrder
  )

  const [tableRowSize] = useAtom(tableRowSizeAtom)

  const [highlightedIndex, setHighlightedIndex] = useState<number>()

  useEffect(() => {
    if (listRef.current) {
      const index = highlightedItem
        ? rows.findIndex(row => itemToString(row.original) === itemToString(highlightedItem))
        : undefined

      setHighlightedIndex(index)

      if (index !== undefined && highlightedIndex !== index) {
        tableScrollIntoViewport(listRef.current, {
          top: index * rowSizes[tableRowSize],
          headerHeight: HEADER_HEIGHT,
          height: rowSizes[tableRowSize],
        })
      }
    }
  }, [ref, highlightedItem, setHighlightedIndex, itemToString, rows, highlightedIndex, tableRowSize])

  useEffect(() => {
    if (isDashboardView) {
      const { hidden, order } = columnSettings.reduce<{ hidden: string[]; order: string[] }>(
        (acc, column) => ({
          hidden: !column.isVisible ? acc.hidden.concat(column.id) : acc.hidden,
          order: acc.order.concat(column.id),
        }),
        {
          hidden: [],
          order: [],
        }
      )

      setColumnOrder(order)
      setHiddenColumns(hidden)
    }
  }, [columnSettings, setColumnOrder, setHiddenColumns, isDashboardView])

  useTrackSortTable({ initialSorting: defaultSorting?.[0], sortBy, tableType })
  useTrackFilterTable({ filters, tableType })

  return (
    <div ref={ref} role="table" {...getTableProps({ className: styles.table })}>
      <VirtualListWithHeaders
        outerRef={listRef}
        itemCount={rows.length}
        height={bodyHeight}
        headerGroups={headerGroups as unknown as HeaderGroup[]}
        tableBodyProps={getTableBodyProps({ className: styles.body })}>
        {({ index, style }) => {
          const row = rows[index]
          prepareRow(row)
          const isDisabled = itemToDisabled?.(row.original) ?? false
          return (
            <div
              role="row"
              tabIndex={index}
              onClick={
                !isDisabled ? onSelectRow?.bind(onSelectRow, row.original) : () => setIsDisabledModalVisible(true)
              }
              {...row.getRowProps({
                style,
                className: classNames(styles.row, {
                  [styles.highlighted]: index === highlightedIndex,
                  [styles.clickable]: onSelectRow,
                  [styles.labelAutomatic]: itemToRowLabel(row.original) === OrderType.Automatic,
                  [styles.labelCancelled]: itemToRowLabel(row.original) === OrderType.Cancelled,
                }),
              })}
              data-testid={TestId.TableBodyRow}>
              {row.cells.map((cell, i) => (
                <div
                  role="cell"
                  {...cell.getCellProps({
                    style: {
                      maxWidth: cell.column.maxWidth,
                      left: isSecondColumn(i) && isDashboardView ? FIRST_COLUMN_WIDTH.width : undefined,
                    },
                    className: getClassName(i),
                  })}>
                  {cell.render('Cell')}
                </div>
              ))}
            </div>
          )
        }}
      </VirtualListWithHeaders>
      <BackToTopButton listRef={listRef} rowSize={rowSizes[tableRowSize]} />
      <InformationModal
        isVisible={isDisabledModalVisible}
        onClose={() => setIsDisabledModalVisible(false)}
        title="Vessel not on the map"
        body="Vessel Tracking and Vessel Information is not available for this movement."
        withCloseButton
      />
    </div>
  )
}
