import React, { useMemo, useState } from 'react'

import { FilterDefinition, FilterOption } from '../../state/filters'
import { sortAlphabetically } from '../../utils/array'
import { Checkbox } from '../Checkbox/Checkbox'
import { DropoutMenu } from '../DropoutMenu/DropoutMenu'
import { UnfoldIcon } from '../icons/UnfoldIcon/UnfoldIcon'

import styles from './SettingsDropdown.module.scss'

type SearchDropdownSettingProps = { definition: FilterDefinition }
export const SearchDropdownSetting: React.FC<SearchDropdownSettingProps> = ({
  definition: { onChange, selectedOptions, options, key },
}) => {
  const [localSelected, setLocalSelected] = useState(selectedOptions)
  const [searchFilter, setSearchFilter] = useState('')

  const handleAddSelected = (value: string) => {
    const newLocalSelected = localSelected.concat(value)
    setLocalSelected(newLocalSelected)
    onChange(newLocalSelected)
  }

  const handleRemoveSelected = (value: string) => {
    const newLocalSelected = localSelected.filter(val => val !== value)
    setLocalSelected(newLocalSelected)
    onChange(newLocalSelected)
  }

  const { selectedItems, notSelectedItems } = useMemo(
    () =>
      Object.values(options)
        .sort(sortAlphabetically(({ label }) => label))
        .reduce(
          (acc, item) => {
            return selectedOptions.includes(item.value)
              ? { ...acc, selectedItems: acc.selectedItems.concat(item) }
              : { ...acc, notSelectedItems: acc.notSelectedItems.concat(item) }
          },
          { selectedItems: [], notSelectedItems: [] } as {
            selectedItems: FilterOption[]
            notSelectedItems: FilterOption[]
          }
        ),
    [options, selectedOptions]
  )

  const closedDropdownLabel = selectedOptions.length > 0 ? `${selectedOptions.length} selected` : 'All selected'

  return (
    <DropoutMenu>
      {({ isOpen, openMenu }) => (
        <div className={styles.searchDropdownSetting}>
          <input
            data-testid={`search-dropdown-handle-${key}`}
            type="text"
            className={styles.searchInput}
            value={isOpen ? searchFilter : closedDropdownLabel}
            placeholder="Type to search"
            onFocus={() => openMenu()}
            onChange={e => setSearchFilter(e.target.value)}
          />
          {!isOpen && (
            <span className={styles.searchIcon}>
              <UnfoldIcon />
            </span>
          )}
          {isOpen && (
            <div className={styles.searchDropdown}>
              <div className={styles.searchSection}>
                {selectedItems.map(({ label, value, count }) => (
                  <div key={value} className={styles.searchItem}>
                    <Checkbox
                      className={styles.itemCheckbox}
                      isChecked
                      label={`${label} (${count})`}
                      onChange={() => handleRemoveSelected(value)}
                    />
                  </div>
                ))}
              </div>
              {selectedItems.length > 0 && notSelectedItems.length > 0 && <div className={styles.searchDivider} />}
              <div className={styles.searchSection}>
                {notSelectedItems
                  .filter(item => item.label.toLowerCase().includes(searchFilter.toLowerCase()))
                  .map(({ label, value, count }) => (
                    <div key={value} className={styles.searchItem}>
                      <Checkbox
                        className={styles.itemCheckbox}
                        isChecked={false}
                        label={`${label} (${count})`}
                        onChange={() => handleAddSelected(value)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </DropoutMenu>
  )
}
