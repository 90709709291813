import { useDrag, useDrop } from 'react-dnd'

import { ColumnSetting } from '../../components/TableSettingsProvider/TableSettingsProvider'

import { NON_DRAGGABLE } from './constants'

const DND_ITEM_TYPE = 'DND_COLUMN'

interface DndItem {
  id: string
  originalIndex: number
}

export const useColumnDragAndDrop = (
  ref: React.RefObject<HTMLLIElement>,
  column: ColumnSetting,
  moveColumn: (id: string, index: number) => void,
  findColumnWithIndex: (id: string) => { column: ColumnSetting; index: number }
) => {
  const [{ isDragging }, dragHandlerRef, dragPreviewRef] = useDrag(
    () => ({
      type: DND_ITEM_TYPE,
      item: {
        id: column.id,
        originalIndex: findColumnWithIndex(column.id).index,
      } as DndItem,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          moveColumn(item.id, item.originalIndex)
        }
      },
      canDrag: () => !NON_DRAGGABLE.includes(column.id),
    }),
    [column, moveColumn, findColumnWithIndex]
  )

  const [, dropRef] = useDrop(
    () => ({
      accept: DND_ITEM_TYPE,
      hover: ({ id: draggedId }: DndItem, monitor) => {
        if (draggedId !== column.id && monitor.canDrop()) {
          moveColumn(draggedId, findColumnWithIndex(column.id).index)
        }
      },
      canDrop: (_, monitor) => !NON_DRAGGABLE.includes(column.id) && !monitor.isOver(),
    }),
    [column, moveColumn, findColumnWithIndex]
  )

  dragPreviewRef(dropRef(ref))

  return { isDragging, dragHandlerRef }
}

export const useParentDragAndDrop = () => {
  const [, dropRef] = useDrop(() => ({ accept: DND_ITEM_TYPE }))
  return dropRef
}
