import * as React from 'react'

import classnames from 'classnames'

import { ClassValue } from '../../../utils/classnames'

import styles from './DrillDownLevel.module.scss'

export type DrillDownLevelTheme = Partial<{
  drillDownLevel: string
  left: string
  right: string
  active: string
}>

export const DRILL_DOWN_LEFT = 'SIDE_MENU_LEFT'
export const DRILL_DOWN_RIGHT = 'SIDE_MENU_RIGHT'
type DrillDownLevelPosition = typeof DRILL_DOWN_LEFT | typeof DRILL_DOWN_RIGHT

function getStyleForOrientation(position: DrillDownLevelPosition): ClassValue {
  const orientationStyles: Record<DrillDownLevelPosition, ClassValue> = {
    [DRILL_DOWN_LEFT]: [styles.drillDownLevelLeft],
    [DRILL_DOWN_RIGHT]: [styles.drillDownLevelRight],
  }

  return orientationStyles[position]
}

function getStyleForActive(active: boolean): ClassValue {
  return active ? [styles.active] : undefined
}

interface IDrillDownLevelProps {
  position: DrillDownLevelPosition
  active: boolean
  children: React.ReactNode
}

export const DrillDownLevel = (props: IDrillDownLevelProps) => {
  const className = classnames(
    styles.drillDownLevel,
    getStyleForOrientation(props.position),
    getStyleForActive(props.active)
  )

  return <div className={className}>{props.children}</div>
}
