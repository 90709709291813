import { Header } from '../../components/Header/Header'
import { Collapsable } from '../../ui/Collapsable/Collapsable'
import { ContentPage, ContentPageCentered } from '../../ui/ContentPage/ContentPage'
import { PageCloseLink } from '../../ui/PageCloseLink/PageCloseLink'

import styles from './Faq.module.scss'
import { faqData } from './faqData'

const subTitles = Object.keys(faqData)
export const Faq = () => {
  return (
    <>
      <Header hideControls />
      <ContentPage>
        <PageCloseLink />
        <ContentPageCentered>
          <h1 className={styles.title}>Frequently Asked Questions</h1>
          {subTitles.map(subtitle => (
            <div key={subtitle}>
              <h3 className={styles.subtitle}>{subtitle}</h3>
              {faqData[subtitle].map(({ question, answer }) => (
                <Collapsable key={question} titleText={question}>
                  {answer}
                </Collapsable>
              ))}
            </div>
          ))}
        </ContentPageCentered>
      </ContentPage>
    </>
  )
}
