import React from 'react'

import { Button, ButtonTheme } from '@portxchange/button'

import { CloseIcon } from '../../ui/icons/CloseIcon/CloseIcon'

import styles from './FullScreenOverlay.module.scss'

type FullScreenOverlayProps = Readonly<{
  title: React.ReactNode
  onClose: () => void
}>

const buttonTheme: ButtonTheme = {
  button: styles.fullScreenOverlayCloseButton,
  label: styles.fullScreenOverlayCloseButtonText,
}

export const FullScreenOverlay: React.FC<FullScreenOverlayProps> = ({ children, title, onClose }) => (
  <>
    <div className={styles.fullScreenOverlay} role="dialog">
      <div className={styles.fullScreenOverlayTopBar}>
        <h2 className={styles.fullScreenOverlayTitle}>{title}</h2>
        <Button
          type="button"
          text={
            <>
              close <CloseIcon size={16} className={styles.fullScreenOverlayCloseButtonIcon} />
            </>
          }
          onClick={onClose}
          theme={buttonTheme}
        />
      </div>
      <div className={styles.fullScreenOverlayContent}>{children}</div>
    </div>
  </>
)
