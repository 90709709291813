import { parseISO } from 'date-fns'

import { alto, cerulean, green } from '../styles/variables'
import { filterNonNullable } from '../utils/array'

import { IMO, MMSI, toVesselTypeCategory } from './Vessel'

export enum StatusPill {
  PilotOnBoard = 'pilotOnBoard',
}

export enum OrderType {
  Automatic = 'automatic',
  Cancelled = 'cancelled',
}

export type Agent = Readonly<{
  shortName: string
  longName?: string
  contactPerson?: string
  phone?: string
}>

export type Dock = Readonly<{
  abbreviation?: string
  shortName: string
  longName?: string
  numberCode?: string
  terminal?: string
}>

type RecentlyUpdated = Readonly<{
  scheduledTime?: string
  draft?: string
}>

type JobType = 'INBOUND' | 'OUTBOUND' | 'SHIFTING'

type Heading = 'S' | 'P' | 'M'

export enum MovementType {
  Movement = 'Movement',
  PortStatus = 'PortStatus',
}

export type HoustonPortState = 'BothWaysOpen' | 'InboundClosed' | 'OutboundClosed' | 'BothWaysClosed'

export type VesselJson = {
  name: string
  imo?: IMO
  mmsi?: MMSI
  flag?: string
  vesselType?: string
}

export type ServiceDetails = {
  pilotNo?: string
  lineHandler?: string
  tugCompany?: string
}

export type MovementJson = Readonly<{
  movementId: string
  scheduledTime: string
  underWayTime?: string
  from?: Dock
  to?: Dock
  draft?: string
  vessel: VesselJson
  offTime?: string
  status: string
  tugMat?: string
  agent?: Agent
  jobType: string
  isCancelled?: boolean
  isAutomaticOrder?: boolean
  movementToDockHeading?: Heading
  movementCalledDateTime?: string
  serviceDetails?: ServiceDetails
  recordTime: string
  recentlyUpdated?: RecentlyUpdated
  subscribed: boolean
  houstonPortState: HoustonPortState
  type: MovementType
}>

// Converted properties from base MovementJsonFull
type MovementBase = {
  draft?: string
  scheduledTime: Date
  underWayTime: Date | undefined
  movementCalledDateTime: Date | undefined
  offTime: Date | undefined
  recentlyUpdated: {
    scheduledTime?: Date
    draft?: string
  }
  jobType: JobType
  vessel: Omit<VesselJson, 'vesselType'> & { vesselType: string }
}

export type Movement = Omit<MovementJson, keyof MovementBase> & MovementBase

export const jobTypeToColor: Record<JobType, string> = {
  INBOUND: green,
  OUTBOUND: cerulean,
  SHIFTING: alto,
}

export const jsonToMovement = (data: MovementJson): Movement => ({
  ...data,
  scheduledTime: parseISO(data.scheduledTime),
  underWayTime: data.underWayTime ? parseISO(data.underWayTime) : undefined,
  movementCalledDateTime: data.movementCalledDateTime ? parseISO(data.movementCalledDateTime) : undefined,
  offTime: data.offTime ? parseISO(data.offTime) : undefined,
  recentlyUpdated: {
    ...(data.recentlyUpdated || {}),
    scheduledTime: data.recentlyUpdated?.scheduledTime ? parseISO(data.recentlyUpdated.scheduledTime) : undefined,
  },
  jobType: data.jobType as JobType,
  vessel: {
    ...data.vessel,
    imo: data.vessel.imo && (data.vessel.imo.trim() as IMO),
    vesselType: toVesselTypeCategory(data.vessel.vesselType || 'NULL'),
  },
})

export const getVesselIdentifierFromMovement = ({ vessel }: Movement): string | null =>
  !!vessel?.mmsi && vessel?.mmsi !== '0' ? vessel?.mmsi : null

export const getImoFromMovement = ({ vessel }: Movement): string | null =>
  vessel?.imo !== undefined && vessel?.imo !== '0' ? vessel.imo : null

export const getValidVesselIdentifiersFromMovements = (movements: Movement[]): string[] =>
  movements.map(getVesselIdentifierFromMovement).filter(filterNonNullable)

export const sortByScheduledTime = (a: Movement, b: Movement): number =>
  b.scheduledTime.getTime() - a.scheduledTime.getTime()

export const getRowLabel = ({ isAutomaticOrder, isCancelled }: Movement): OrderType | undefined => {
  if (isAutomaticOrder) {
    return OrderType.Automatic
  }
  if (isCancelled) {
    return OrderType.Cancelled
  }

  return undefined
}
