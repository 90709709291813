import { BackArrowIcon } from '../../../../ui/icons/BackArrowIcon/BackArrowIcon'

import styles from './SubMenuHeader.module.scss'

type SubMenuHeaderProps = Readonly<{
  onBack: () => void
}>

export const SubMenuHeader = (props: SubMenuHeaderProps) => (
  <div className={styles.header}>
    <button aria-label="go back" onClick={props.onBack} className={styles.backButton}>
      <BackArrowIcon />
    </button>
  </div>
)
