import { parseISO } from 'date-fns'

import { UUID } from './UUID'
import { ENI, IMO, MMSI, USCG } from './Vessel'

// These properties come from https://github.com/portxchange/pronto-domain/blob/master/domain/src/main/scala/pronto/domain/masterdata/VesselMasterData.scala#L104-L150
export type VesselDetailsJson = Readonly<{
  id: UUID
  imo?: IMO
  mmsi?: MMSI
  eni?: ENI
  uscg?: USCG
  name?: string
  callSign?: string
  flag?: string
  shipType?: string
  statCodeDerived?: string
  maxDraughtDerived?: number
  lengthOverall?: number
  beam?: number
  airDraught?: number
  aisPositionAllowed: boolean
  aisPositionAllowedReason?: string
  aisPositionSensitive?: boolean
  outOfService: boolean
  invalid: boolean
  isDeepSeaVessel?: boolean
  commercialOwner?: string
  spireVesselType?: string
  spireSubtype?: string
  iceClass?: string
  photoUrl?: string
  vesselNameDate?: string
  builtYear?: number
  builder?: string
  classSociety?: string
  dwt?: number
  grossTonnage?: number
  netTonnage?: number
  tpc?: number
  depth?: number
  displacement?: number
  liquidCapacity98Pcnt?: number
  grainCapacity?: number
  mainEngineDesigner?: string
  mainEngine?: string
  engineCapacity?: number
  capacityContainers?: number
}>

export type VesselDetails = Omit<VesselDetailsJson, 'vesselNameDate'> & {
  vesselNameDate?: Date
}

export const parseVesselDetails = ({ vesselNameDate, ...details }: VesselDetailsJson): VesselDetails => ({
  ...details,
  vesselNameDate: vesselNameDate ? parseISO(vesselNameDate) : undefined,
})
