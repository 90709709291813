export const tableScrollIntoViewport = (
  viewport: HTMLElement,
  element: { top: number; headerHeight: number; height: number }
) => {
  const shouldScrollUp = element.top <= viewport.scrollTop + element.height
  const shouldScrollDown = element.top >= viewport.scrollTop + viewport.offsetHeight - element.headerHeight

  if (shouldScrollUp) {
    viewport.scrollTo({ top: element.top, behavior: 'smooth' })
  } else if (shouldScrollDown) {
    viewport.scrollTo({
      top: element.top - viewport.offsetHeight + element.height + element.headerHeight,
      behavior: 'smooth',
    })
  }
}
