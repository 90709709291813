import styles from './InlineLoader.module.scss'

export const InlineLoader = () => {
  return (
    <div className={styles.loaderInline}>
      <div className={styles.spinnerWrapper}>
        <div className={styles.lightBackgroundSpinner} />
      </div>
    </div>
  )
}
