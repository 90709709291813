import { Brand } from '../utils/brand'

export type TimeZone = Brand<string, 'timeZone'>

export const createTimeZone = (tz: string): TimeZone => tz as TimeZone

export const HOUSTON_TIME_ZONE: TimeZone = createTimeZone('America/Chicago')

export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
