import React from 'react'

import classnames from 'classnames'
import Downshift, { ControllerStateAndHelpers, DownshiftState, StateChangeOptions, DownshiftProps } from 'downshift'

import styles from './DropoutMenu.module.scss'

type DropoutMenuProps<T> = DownshiftProps<T> &
  Readonly<{
    children: (
      props: Pick<
        ControllerStateAndHelpers<T>,
        'isOpen' | 'closeMenu' | 'openMenu' | 'getToggleButtonProps' | 'getItemProps'
      >
    ) => React.ReactNode
  }>

function downshiftStateReducer<Item>(state: DownshiftState<Item>, changes: StateChangeOptions<Item>) {
  switch (changes.type) {
    // to make sure any click outside the menu doesn't close menu + rendered children
    case Downshift.stateChangeTypes.mouseUp:
      return {
        ...changes,
        isOpen: true,
      }
    default:
      return changes
  }
}

export function DropoutMenu<Item>(props: DropoutMenuProps<Item>) {
  return (
    <Downshift {...props} stateReducer={downshiftStateReducer}>
      {({ isOpen, closeMenu, openMenu, getMenuProps, getRootProps, getToggleButtonProps, getItemProps }) => (
        <>
          {isOpen && <div className={styles.dropoutMenuOverlay} onClick={() => closeMenu()} />}
          <div {...getRootProps(undefined, { suppressRefError: true })}>
            <div className={classnames(styles.dropoutMenu, { [styles.active]: isOpen })} {...getMenuProps()}>
              {props.children({ isOpen, closeMenu, openMenu, getToggleButtonProps, getItemProps })}
            </div>
          </div>
        </>
      )}
    </Downshift>
  )
}
