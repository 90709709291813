import { PropsWithChildren } from 'react'

import classNames from 'classnames'
import { UseTableColumnProps, UseFiltersColumnProps } from 'react-table'

import { ClearIcon } from '../../../ui/icons/ClearIcon/ClearIcon'
import { SearchIcon } from '../../../ui/SearchIcon/SearchIcon'
import styles from '../Table.module.scss'

type DefaultColumnFilterProps<Data extends {}> = Readonly<{
  column: UseTableColumnProps<Data> & UseFiltersColumnProps<Data>
}>
export const DefaultColumnFilter = <Data extends {}>({
  column: { filterValue, setFilter, render },
  children,
}: PropsWithChildren<DefaultColumnFilterProps<Data>>) => (
  <>
    {children}
    <div className={styles.filterContainer}>
      <input
        className={styles.headCellInnerFilterable}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`${render('Header')}`}
      />
      {filterValue ? (
        <button aria-label="clear" onClick={() => setFilter(undefined)} className={styles.icon}>
          <ClearIcon className={styles.clear} />
        </button>
      ) : (
        <SearchIcon className={classNames(styles.icon, styles.search)} />
      )}
    </div>
  </>
)
