import { useMemo } from 'react'

import { useMixpanel } from '@portxchange/mixpanel-utils'
import { CellProps, Column } from 'react-table'

import { useActivePortContext } from '../../components/Port/ActivePort'
import { PortStateIndicator } from '../../components/PortStateIndicator/PortStateIndicator'
import { ColorLabelCell } from '../../components/Table/cells/ColorLabelCell/ColorLabelCell'
import { OverflowDropdown } from '../../components/Table/cells/OverflowCell/OverflowCell'
import { TimeCell } from '../../components/Table/cells/TimeCell/TimeCell'
import { TooltipCell } from '../../components/Table/cells/TooltipCell/TooltipCell'
import { VesselStatusCell } from '../../components/Table/cells/VesselStatusCell/VesselStatusCell'
import { DateTimeRangeFilterComponent } from '../../components/Table/Filters/DateTimeRangeFilter'
import { DropdownColumnFilter } from '../../components/Table/Filters/DropDownColumnFilter'
import { HelpDropper } from '../../components/Table/HelpDropper/HelpDropper'
import { useUserContext } from '../../components/UserProvider/UserProvider'
import { NO_DATA_AVAILABLE } from '../../constants/constants'
import { jobTypeToColor, Movement, MovementType } from '../../Domain/Movement'
import { TableType } from '../../Domain/Tracking'
import { transparent } from '../../styles/variables'
import { DropoutMenu } from '../../ui/DropoutMenu/DropoutMenu'
import { DateTimeRangeFilterPosition } from '../../ui/DropoutMenu/DropoutMenuDropper'
import { HelpIcon } from '../../ui/icons/HelpIcon/HelpIcon'
import { UpdatePill } from '../../ui/UpdatePill/UpdatePill'
import { draftToMeasurements } from '../../utils/convert-units'
import { renderTimeInfo } from '../../utils/datetime'

import { CustomFilterTypes, CustomSortTypes } from './column-filters'
import styles from './Dashboard.module.scss'
import { MovementOrPortStatus } from './MovementOrPortStatus'

const getStandardSizing = (width: number, multiplier: number = 2) => ({ width, maxWidth: width * multiplier })

const columnWidth = {
  small: getStandardSizing(80),
  regular: getStandardSizing(130),
  wide: getStandardSizing(145),
}

export const FIRST_COLUMN_WIDTH = columnWidth.regular

const HelpDropdown = () => {
  const { track } = useMixpanel()

  return (
    <DropoutMenu>
      {({ isOpen, openMenu, closeMenu }) => (
        <>
          <HelpIcon
            onClick={() => {
              if (isOpen) {
                closeMenu()
              } else {
                track('View legend')
                openMenu()
              }
            }}
            className={isOpen ? styles.helpIconSelected : undefined}
          />
          {isOpen && <HelpDropper handleClickClose={closeMenu} />}
        </>
      )}
    </DropoutMenu>
  )
}

function JobType({ type, jobType, houstonPortState }: Movement) {
  const {
    activePort: { unlocode },
  } = useActivePortContext()

  const hasIndicator = useMemo(() => !['USCRP'].includes(unlocode), [unlocode])

  return (
    <ColorLabelCell color={type === MovementType.Movement ? jobTypeToColor[jobType] : transparent}>
      <div className={styles.portStatusCell}>
        {type === MovementType.Movement && hasIndicator && <PortStateIndicator portState={houstonPortState} />}
        {jobType}
      </div>
    </ColorLabelCell>
  )
}

const getDropdownValues = (): Movement['jobType'][] => ['INBOUND', 'SHIFTING', 'OUTBOUND']

export const mainTableColumns: Column<Movement>[] = [
  {
    Header: 'Movement',
    Filter: props => (
      <DropdownColumnFilter {...props} getOptions={getDropdownValues} label="Movement">
        <HelpDropdown />
      </DropdownColumnFilter>
    ),
    id: 'jobType',
    accessor: 'jobType',
    ...FIRST_COLUMN_WIDTH,
    disableSortBy: true,
    Cell: ({ row }) => <JobType {...row.original} />,
  },
  {
    Header: 'Vessel',
    id: 'name',
    accessor: row => row.vessel.name,
    disableSortBy: true,
    Cell: ({ value, row }: CellProps<Movement, string>) => (
      <MovementOrPortStatus
        type={row.original.type}
        Movement={
          <VesselStatusCell movementId={row.original.movementId} vesselName={value} status={row.original.status} />
        }
        PortStatus={<VesselStatusCell movementId={row.original.movementId} vesselName={value} status="" />}
      />
    ),
    ...columnWidth.wide,
  },
  {
    Header: 'From',
    id: 'from',
    accessor: 'from',
    disableSortBy: true,
    filter: CustomFilterTypes.DockFilter,
    Cell: ({ value, row }) => {
      const { track } = useMixpanel()
      if (!value) {
        return NO_DATA_AVAILABLE
      }

      return value.shortName === value.longName ? (
        value.shortName
      ) : (
        <TooltipCell
          cellText={value.shortName}
          tooltipValues={[value.longName]}
          tooltipKey={`${row.id}${value.abbreviation}`}
          onShow={() => track('Hover on From')}
        />
      )
    },
    ...columnWidth.small,
  },
  {
    Header: 'To',
    id: 'to',
    accessor: 'to',
    disableSortBy: true,
    filter: CustomFilterTypes.DockFilter,
    Cell: ({ value, row }) => {
      const { track } = useMixpanel()
      if (!value) {
        return NO_DATA_AVAILABLE
      }

      return value.shortName === value.longName ? (
        value.shortName
      ) : (
        <TooltipCell
          cellText={value.shortName}
          tooltipValues={[value.longName]}
          tooltipKey={`${row.id}${value.abbreviation}`}
          onShow={() => track('Hover on To')}
        />
      )
    },
    ...columnWidth.small,
  },
  {
    Header: 'Ordered time',
    id: 'orderedTime',
    accessor: 'movementCalledDateTime',
    Cell: ({ value, row }) => (
      <MovementOrPortStatus
        type={row.original.type}
        Movement={<TimeCell text="AT Booking" time={value} />}
        PortStatus={renderTimeInfo(value)}
      />
    ),

    sortType: CustomSortTypes.OptionalDateTime,
    Filter: props => (
      <DateTimeRangeFilterComponent {...props} dropoutPosition={DateTimeRangeFilterPosition.TopCenter} />
    ),
    filter: CustomFilterTypes.DateTimeRangeFilter,
    ...columnWidth.wide,
  },
  {
    Header: 'Scheduled time',
    id: 'scheduledTime',
    accessor: 'scheduledTime',
    Cell: ({ value, row, column }) => {
      const { track } = useMixpanel()

      return (
        <MovementOrPortStatus
          type={row.original.type}
          Movement={
            <TimeCell
              text="ET Pilot On"
              time={value}
              previousTime={
                row.original.recentlyUpdated.scheduledTime
                  ? {
                      value: row.original.recentlyUpdated.scheduledTime,
                      tooltipKey: row.id + column.id,
                    }
                  : undefined
              }
              onShow={() => track('Hover on update', { tableType: TableType.DASHBOARD, columnName: 'scheduledTime' })}
            />
          }
          PortStatus={renderTimeInfo(value)}
        />
      )
    },
    sortType: 'datetime',
    Filter: props => (
      <DateTimeRangeFilterComponent {...props} dropoutPosition={DateTimeRangeFilterPosition.TopCenter} />
    ),
    filter: CustomFilterTypes.DateTimeRangeFilter,
    ...columnWidth.wide,
  },
  {
    Header: 'Underway',
    id: 'underWayTime',
    accessor: 'underWayTime',
    Cell: ({ value, row }) => (
      <MovementOrPortStatus
        type={row.original.type}
        Movement={<TimeCell text="AT Pilot On" time={value} />}
        PortStatus={renderTimeInfo(value)}
      />
    ),
    sortType: CustomSortTypes.OptionalDateTime,
    Filter: props => (
      <DateTimeRangeFilterComponent {...props} dropoutPosition={DateTimeRangeFilterPosition.TopCenter} />
    ),
    filter: CustomFilterTypes.DateTimeRangeFilter,
    ...columnWidth.wide,
  },
  {
    Header: 'Off time',
    id: 'offTime',
    accessor: 'offTime',
    Cell: ({ value, row }) => (
      <MovementOrPortStatus
        type={row.original.type}
        Movement={<TimeCell text="AT Pilot Off" time={value} />}
        PortStatus={renderTimeInfo(value)}
      />
    ),
    sortType: CustomSortTypes.OptionalDateTime,
    Filter: props => (
      <DateTimeRangeFilterComponent {...props} dropoutPosition={DateTimeRangeFilterPosition.TopCenter} />
    ),
    filter: CustomFilterTypes.DateTimeRangeFilter,
    ...columnWidth.wide,
  },
  {
    Header: 'Draft',
    id: 'draft',
    accessor: 'draft',
    Cell: ({ value, row, column }) => {
      const { track } = useMixpanel()
      const {
        user: { measurementUnit },
      } = useUserContext()

      const prevValue = row.original.recentlyUpdated.draft

      const formattedValue = useMemo(
        () => (value === undefined ? undefined : draftToMeasurements(value, measurementUnit)),
        [value, measurementUnit]
      )

      const prevFormatteValue = useMemo(
        () => (prevValue === undefined ? undefined : draftToMeasurements(prevValue, measurementUnit)),
        [prevValue, measurementUnit]
      )

      return value ? (
        <UpdatePill
          value={formattedValue}
          previous={
            prevFormatteValue
              ? {
                  value: prevFormatteValue,
                  tooltipKey: `${row.id + column.id}`,
                }
              : undefined
          }
          onShow={() => track('Hover on update', { tableType: TableType.DASHBOARD, columnName: 'draft' })}
        />
      ) : (
        NO_DATA_AVAILABLE
      )
    },
    disableFilters: true,
    ...columnWidth.small,
  },
  {
    Header: 'Type',
    id: 'vesselType',
    accessor: ({ vessel: { vesselType } }) => vesselType,
    Cell: ({ value, row }: CellProps<Movement, Movement['vessel']['vesselType']>) => (
      <MovementOrPortStatus type={row.original.type} Movement={value} PortStatus={NO_DATA_AVAILABLE} />
    ),
    disableSortBy: true,
    ...columnWidth.regular,
  },
  {
    Header: 'IMO',
    id: 'imo',
    accessor: ({ vessel: { imo } }) => imo,
    disableSortBy: true,
    ...columnWidth.small,
  },
  {
    Header: 'Heading',
    id: 'movementToDockHeading',
    accessor: 'movementToDockHeading',
    disableFilters: true,
    ...columnWidth.small,
  },
  {
    Header: 'Tugmat',
    id: 'tugMat',
    accessor: 'tugMat',
    disableFilters: true,
    ...columnWidth.small,
  },
  {
    Header: 'Agent',
    id: 'agent',
    accessor: 'agent',
    Cell: ({ value, row, column }) => {
      const { track } = useMixpanel()
      return value ? (
        <TooltipCell
          tooltipKey={`${row.id + column.id}`}
          cellText={value.shortName}
          tooltipValues={[value.longName, value.contactPerson, value.phone]}
          onShow={() => track('Hover on agent')}
        />
      ) : (
        NO_DATA_AVAILABLE
      )
    },
    disableSortBy: true,
    filter: CustomFilterTypes.AgentFilter,
    ...columnWidth.small,
  },
  {
    Header: 'Pilot No.',
    id: 'pilotNo',
    accessor: ({ serviceDetails }) => serviceDetails?.pilotNo,
    disableSortBy: true,
    disableFilters: true,
    ...columnWidth.small,
  },
  {
    Header: 'Line Handler',
    id: 'lineHandler',
    accessor: ({ serviceDetails }) => serviceDetails?.lineHandler,
    disableSortBy: true,
    disableFilters: true,
    ...columnWidth.regular,
  },
  {
    Header: 'Tug Company',
    id: 'tugCompany',
    accessor: ({ serviceDetails }) => serviceDetails?.tugCompany,
    disableSortBy: true,
    disableFilters: true,
    ...columnWidth.small,
  },
  {
    id: 'subscribed',
    accessor: 'subscribed',
    Cell: ({ value, row, column }) => (
      <MovementOrPortStatus
        type={row.original.type}
        Movement={<OverflowDropdown subscribed={value} row={row} column={column} tableType={TableType.DASHBOARD} />}
        PortStatus={null}
      />
    ),
    width: 30,
    disableSortBy: true,
    disableFilters: true,
  },
]

export const mapTableColumns: Column<Movement>[] = [
  {
    Header: 'Movement',
    Filter: props => (
      <DropdownColumnFilter {...props} getOptions={getDropdownValues} label="Movement">
        <HelpDropdown />
      </DropdownColumnFilter>
    ),
    id: 'jobType',
    accessor: 'jobType',
    ...columnWidth.regular,
    disableSortBy: true,
    Cell: ({ row }) => <JobType {...row.original} />,
  },
  {
    Header: 'Vessel',
    id: 'name',
    accessor: ({ vessel }): string => vessel.name,
    Cell: ({ value, row }: CellProps<Movement, string>) => (
      <MovementOrPortStatus
        type={row.original.type}
        Movement={
          <VesselStatusCell movementId={row.original.movementId} vesselName={value} status={row.original.status} />
        }
        PortStatus={<VesselStatusCell movementId={row.original.movementId} vesselName={value} status="" />}
      />
    ),
    ...columnWidth.wide,
    disableSortBy: true,
  },
  {
    Header: 'To',
    id: 'to',
    accessor: (row): string | undefined => row.to?.shortName,
    ...columnWidth.small,
    disableSortBy: true,
  },
  {
    Header: 'Scheduled time',
    id: 'scheduledTime',
    accessor: 'scheduledTime',
    Cell: ({ value, row, column }) => {
      const { track } = useMixpanel()
      return (
        <MovementOrPortStatus
          type={row.original.type}
          Movement={
            <TimeCell
              text="ET Pilot On"
              time={value}
              previousTime={
                row.original.recentlyUpdated.scheduledTime
                  ? {
                      value: row.original.recentlyUpdated.scheduledTime,
                      tooltipKey: row.id + column.id,
                    }
                  : undefined
              }
              onShow={() =>
                track('Hover on update', {
                  tableType: TableType.MAP,
                  columnName: 'scheduledTime',
                })
              }
            />
          }
          PortStatus={renderTimeInfo(value)}
        />
      )
    },
    ...columnWidth.wide,
    sortType: 'datetime',
    Filter: props => (
      <DateTimeRangeFilterComponent {...props} dropoutPosition={DateTimeRangeFilterPosition.TopRight} isVertical />
    ),
    filter: CustomFilterTypes.DateTimeRangeFilter,
  },
  {
    id: 'subscribed',
    accessor: 'subscribed',
    Cell: ({ value, row, column }) => (
      <MovementOrPortStatus
        type={row.original.type}
        Movement={<OverflowDropdown subscribed={value} row={row} column={column} tableType={TableType.MAP} />}
        PortStatus={null}
      />
    ),
    ...getStandardSizing(30, 1),
    disableSortBy: true,
    disableFilters: true,
  },
]
