import styles from './SubMenuForm.module.scss'

export enum SideSubMenuFormState {
  FORM_STATE_INITIAL = 'FORM_CREATING',
  FORM_STATE_SUBMITTING = 'FORM_SUBMITTING',
  FORM_STATE_SUBMITTED = 'FORM_SUBMITTED',
  FORM_STATE_SUBMIT_ERROR = 'FORM_STATE_SUBMIT_ERROR',
}

export const SubMenuForm: React.FC = ({ children }) => <div className={styles.form}>{children}</div>
