import { Point } from 'geojson'

import { Brand } from '../utils/brand'

import { findShipTypeCategory, ShipType, ShipTypeCategory } from './ShipType'

export type MMSI = Brand<string, 'MMSI'>
export type IMO = Brand<string, 'IMO'>
export type ENI = Brand<string, 'ENI'>
export type USCG = Brand<string, 'USCG'>
export const createMMSI = (val: string | number): MMSI => String(val) as MMSI
export const createIMO = (val: string | number): IMO => String(val) as IMO
export const createENI = (val: string | number): ENI => String(val) as ENI
export const createUSCG = (val: string | number): USCG => String(val) as USCG

export const BARGES_AND_OTHERS_CATEGORY = 'Barges & Others' as const
export const BARGES_AND_OTHERS_VESSEL_TYPES: string[] = ['NULL', 'Other', 'Fishing', 'Offshore', 'Tug']
export const toVesselTypeCategory = (vesselType: string): string =>
  BARGES_AND_OTHERS_VESSEL_TYPES.includes(vesselType) ? BARGES_AND_OTHERS_CATEGORY : vesselType

export type VesselCommonProperties = Readonly<{
  mmsi: number
  shipName?: string
  shipTypeCategory: ShipTypeCategory
  destination?: string
  destinationPort?: string
  speedOverGround?: number
  draught?: number
  eta?: string
}>

export type VesselJson = Omit<VesselCommonProperties, 'shipTypeCategory'> & {
  shipType?: ShipType
  location?: Point
  courseOverGround?: number
  heading: number
  shipDimensions: {
    distanceToBow: number
    distanceToStern: number
    distanceToPort: number
    distanceToStarboard: number
  }
}

export type VesselWithValidLocation = Omit<VesselJson, 'location'> & { location: Point }

export const vesselPropertiesFromJson = ({ shipType, ...vesselProperties }: VesselJson): VesselCommonProperties => ({
  ...vesselProperties,
  shipTypeCategory: findShipTypeCategory(shipType || ShipType.UNDEFINED),
})

export const hasValidLocation = (json: VesselJson): json is VesselWithValidLocation =>
  'location' in json && json.location !== undefined
