import { useState } from 'react'

import { NavigationDrawer } from './NavigationDrawer/NavigationDrawer'
import { PrimaryMenu } from './PrimaryMenu/PrimaryMenu'

interface HeaderProps {
  hideControls?: boolean
  children?: ({ isToggled }: { isToggled: boolean }) => JSX.Element
}

export const Header = ({ hideControls, children }: HeaderProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  return (
    <>
      <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} hideControls={hideControls}>
        {children}
      </PrimaryMenu>
      <NavigationDrawer isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)} />
    </>
  )
}
