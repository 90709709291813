import { Auth0ContextInterface } from '@auth0/auth0-react'
import ky from 'ky'
import { BehaviorSubject, lastValueFrom, noop, of } from 'rxjs'
import { filter, mergeMap, take } from 'rxjs/operators'

import { AIS_BACKEND_URL, HOUSTON_PILOTS_BACKEND_URL } from '../constants/env-vars'

const ApiType = {
  BACKEND: `${HOUSTON_PILOTS_BACKEND_URL}/`,
  AIS_BACKEND: `${AIS_BACKEND_URL}/`,
}

type State = { isAuthenticated: boolean; getTokenSilently: Auth0ContextInterface['getAccessTokenSilently'] }

// Create uninitialised Auth0 client observable
export const client = new BehaviorSubject<State>({ isAuthenticated: false, getTokenSilently: () => new Promise(noop) })

// Set Auth0 client upon app load
export const initializeClient = client.next.bind(client)

// Extract auth token from Auth0 client
export const getToken = client.pipe(
  filter(({ isAuthenticated }) => isAuthenticated),
  mergeMap(({ getTokenSilently }) => getTokenSilently())
)

export const getApi = (prefixUrl: string): Promise<typeof ky> =>
  lastValueFrom(
    getToken
      .pipe(
        mergeMap(token =>
          // Create extended Ky instance that intercepts requests and injects the Authorization header
          of(
            ky.extend({
              prefixUrl,
              hooks: {
                beforeRequest: [request => request.headers.set('Authorization', `Bearer ${token}`)],
              },
            })
          )
        )
      )
      .pipe(take(1))
  )

// Gets the token from the Auth0 client and then create an extended Ky instance with authentication
export const getAuthenticatedApi = () => getApi(ApiType.BACKEND)
export const getAisApi = () => getApi(ApiType.AIS_BACKEND)
