import { useState } from 'react'

import classNames from 'classnames'
import { format, subDays } from 'date-fns'
import ReactDatePicker from 'react-datepicker'
import { UseTableColumnProps, UseFiltersColumnProps, UseSortByColumnProps } from 'react-table'

import { DropoutMenu } from '../../../ui/DropoutMenu/DropoutMenu'
import { positionedDropoutMenuOptions } from '../../../ui/DropoutMenu/DropoutMenuDropper'
import { getSortDirection, SortingIcon } from '../../../ui/icons/SortingIcon/SortingIcon'
import { longDateTimeFormat, shortDateTimeFormat, timeFormat } from '../../../utils/datetime'
import tableStyles from '../Table.module.scss'

import styles from './DateTimeRangeFilter.module.scss'

export type DateTimeRange = {
  start: Date
  end: Date
}

type DateTimeRangeFilterFilterProps<Data extends {}> = Readonly<{
  column: UseTableColumnProps<Data> & UseFiltersColumnProps<Data> & UseSortByColumnProps<Data>
  dropoutPosition: keyof typeof positionedDropoutMenuOptions
  isVertical?: boolean
}>
export const DateTimeRangeFilterComponent = <Data extends {}>({
  column: { id, setFilter, toggleSortBy, render, filterValue, isSorted, isSortedDesc },
  dropoutPosition,
  isVertical,
}: DateTimeRangeFilterFilterProps<Data>) => {
  const [startDate, setStartDate] = useState<Date | null>(filterValue?.start || null)
  const [endDate, setEndDate] = useState<Date | null>(filterValue?.end || null)
  const DropoutComponent = positionedDropoutMenuOptions[dropoutPosition]

  const sortDirection = getSortDirection(isSorted, isSortedDesc)

  return (
    <div onClick={evt => evt.stopPropagation()} className={classNames({ [styles.vertical]: isVertical })}>
      <DropoutMenu>
        {({ isOpen, openMenu, closeMenu }) => (
          <>
            <button
              aria-label="open/close menu"
              className={classNames(tableStyles.headCellInner, styles.button)}
              onClick={() => (isOpen ? closeMenu() : openMenu())}>
              {filterValue
                ? `${format(filterValue.start, shortDateTimeFormat)} - ${format(filterValue.end, shortDateTimeFormat)}`
                : render('Header')}
            </button>{' '}
            <button aria-label="sort asc/desc" onClick={() => toggleSortBy()}>
              <SortingIcon sortDirection={sortDirection} testId={id} />
            </button>
            {isOpen && (
              <DropoutComponent
                className={styles.dateRangeFilterContainer}
                theme={{ triangleTop: styles.dropoutTriangle }}>
                <div className={styles.datePickerContainerGroup}>
                  <div className={styles.datePickerContainer}>
                    <label className={styles.datePickerLabel} htmlFor={`from-date-${id}`}>
                      From:
                    </label>
                    <ReactDatePicker
                      id={`from-date-${id}`}
                      className={styles.datePicker}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={subDays(new Date(), 7)}
                      showTimeSelect
                      selectsStart
                      dateFormat={longDateTimeFormat}
                      placeholderText={longDateTimeFormat.toLowerCase()}
                      timeFormat={timeFormat}
                      onChange={newStartDate => {
                        setStartDate(newStartDate)
                        if (newStartDate && endDate && endDate < newStartDate) {
                          setEndDate(newStartDate)
                        }
                      }}
                      popperPlacement="bottom"
                    />
                  </div>
                  <div className={styles.datePickerContainer}>
                    <label className={styles.datePickerLabel} htmlFor={`to-date-${id}`}>
                      Until:
                    </label>
                    <ReactDatePicker
                      id={`to-date-${id}`}
                      className={styles.datePicker}
                      selected={endDate}
                      startDate={startDate}
                      endDate={endDate}
                      minDate={new Date(Math.max(subDays(new Date(), 7).getTime(), startDate?.getTime() || 0))}
                      showTimeSelect
                      dateFormat={longDateTimeFormat}
                      placeholderText={longDateTimeFormat.toLowerCase()}
                      timeFormat={timeFormat}
                      selectsEnd
                      onChange={setEndDate}
                    />
                  </div>
                </div>
                <div className={styles.dropoutControlsContainer}>
                  <div className={styles.dropoutButtonContainer}>
                    <button
                      className={styles.resetButton}
                      onClick={() => {
                        setFilter(undefined)
                        setStartDate(null)
                        setEndDate(null)
                      }}>
                      Reset
                    </button>
                    <button
                      className={classNames(styles.button, styles.dropoutButton)}
                      disabled={!startDate || !endDate}
                      onClick={() => {
                        setFilter({
                          start: startDate,
                          end: endDate,
                        })
                        closeMenu()
                      }}>
                      Apply date range
                    </button>
                  </div>
                </div>
              </DropoutComponent>
            )}
          </>
        )}
      </DropoutMenu>
    </div>
  )
}
