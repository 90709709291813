export const SettingsIcon = ({ fill }: { fill: string }) => {
  return (
    <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M14.149 9.503V.57a.571.571 0 1 0-1.143 0v8.932a2.566 2.566 0 0 0-2 2.497c0 .344.07.684.205 1a2.56 2.56 0 0 0 1.794 1.503v.926a.571.571 0 0 0 1.144 0v-.92a2.56 2.56 0 0 0 2-2.509 2.56 2.56 0 0 0-2-2.497Zm-.572 3.931a1.429 1.429 0 1 1 0-2.857 1.429 1.429 0 0 1 0 2.857ZM8.646 2.36V.571a.571.571 0 0 0-1.143 0V2.36c-1.17.264-2 1.304-2 2.503 0 .344.07.684.206 1a2.56 2.56 0 0 0 1.794 1.503v8.063a.571.571 0 1 0 1.143 0V7.366a2.56 2.56 0 0 0 1.8-1.515 2.56 2.56 0 0 0-1.8-3.491Zm-.572 3.926a1.429 1.429 0 1 1 0-2.857 1.429 1.429 0 0 1 0 2.857Zm-4.931.074V.571A.571.571 0 1 0 2 .571V6.36c-1.17.264-2 1.304-2 2.503 0 .344.07.684.206 1A2.56 2.56 0 0 0 2 11.366v4.063a.571.571 0 1 0 1.143 0v-4.063a2.56 2.56 0 0 0 1.8-1.515 2.56 2.56 0 0 0-1.8-3.491Zm-.572 3.926a1.429 1.429 0 1 1 0-2.857 1.429 1.429 0 0 1 0 2.857Z"
          id="settings-a"
        />
      </defs>
      <g>
        <mask id="settings-b" fill="#FFF">
          <use xlinkHref="#settings-a" />
        </mask>
        <g mask="url(#settings-b)">
          <path fill={fill} d="M-1.211-3.286h20v20h-20z" />
        </g>
      </g>
    </svg>
  )
}
